import request from '@/utils/api.utils';

/**
 * GET: fetch the list of expert notifications
 */
export function fetchExpertNotifications(expertId) {
  return request.get(`/experts/${expertId}/notifications`);
}

/**
 * PUT: Mark Expert Notification as Read
 */
export function updateExpertNotification({ expertId, notificationId }) {
  return request.put(`/experts/${expertId}/notifications/${notificationId}`);
}
