<template>
  <div class="p-5">
    <ListFiltersComponent
      v-if="showListFilters"
      :filtersToDisplay="filtersToDisplay"
      @changeFilterEvent="handleChangeFilterEvent"
    />
    <SitesList :filterData="filterSitesData" @goToDetail="goToDetail" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SitesList from '@/components/Sites/List/SitesList.vue';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import isClientUserMixin from '@/mixins/isClientUserMixin';

export default {
  name: 'SiteListContainerView',
  components: {
    ListFiltersComponent,
    SitesList,
  },
  mixins: [isClientUserMixin],
  data() {
    return {
      filterSitesData: {},
      filtersToDisplay: {
        client: false,
        study: true,
        protocol: true,
        site: false,
      },
      showListFilters: false,
    };
  },
  created() {
    this.setIsProtocolView(this.isProtocolView());
    this.setBreadCrumbData({
      sites: [
        {
          text: this.$t('sites'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      userType: ({ auth }) => auth.userType,
    }),
  },
  methods: {
    ...mapActions({
      setIsProtocolView: 'sites/setIsProtocolView',
    }),
    goToDetail() {
      this.$router.push({
        name: 'view-site',
      });
    },
    handleChangeFilterEvent(newFilter) {
      this.filterSitesData = newFilter;
    },
    isProtocolView() {
      return this.$route.name.includes('protocol-sites');
    },
  },
};
</script>
