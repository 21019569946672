<template>
  <div>
    <h2 class="">{{ $t('protocol_information') }}</h2>
    <b-row>
      <b-col cols="6">
        <label>{{ $t('client') }}</label>
        <b-form-input
          v-if="client.legalName"
          :value="client.legalName"
          :placeholder="$t('client_name_legal')"
          disabled
          data-testid="client-name-input"
          id="client-name"
        ></b-form-input>
        <label>{{ $t('protocol_name') }}</label>
        <b-form-input
          v-model="basic.name"
          placeholder="Protocol Name (Unique)"
          maxLength="100"
          data-testid="protocol-name-input"
          id="protocol-name"
        ></b-form-input>
        <label>{{ $t('description') }}</label>
        <textarea
          rows="4"
          v-model="basic.description"
          class="form-control form-control"
          :placeholder="$t('description_tip')"
          data-testid="description-input"
          id="description"
        />
      </b-col>
      <b-col cols="6">
        <label>{{ $t('study_name') }}</label>
        <b-form-input
          v-model="study.name"
          placeholder="Study Name (Unique)"
          disabled
          data-testid="study-name-input"
          id="study-name"
        ></b-form-input>
        <b-row>
          <b-col>
            <label>{{ $t('protocol_study_duration') }}</label>
            <ValidationProvider
              rules="between:1,9999"
              v-slot="{ errors }"
              name="weeks"
            >
              <div>
                <b-form-input
                  class="d-inline w-50 mr-4"
                  type="number"
                  v-model.number="basic.studyDurationPerParticipant"
                  name="weeks"
                  min="1"
                  max="9999"
                  data-testid="weeks-input"
                  id="weeks-duration"
                ></b-form-input>
                {{ $t('weeks') }}
              </div>
              <span class="text-danger text-xs">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
          <b-col>
            <label>{{ $t('protocol_planned_num_participants') }}</label>
            <b-form-input
              type="number"
              v-model.number="basic.plannedNumberOfParticipants"
              placeholder=""
              min="1"
              data-testid="participants-input"
              id="participants-numbers"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="primary"
          :disabled="!validForm || validating"
          @click="shouldValidate ? handleNextClick() : $emit('nextStep')"
          data-testid="next-button"
        >
          {{ $t('next') }}
          <b-spinner small v-if="validating"></b-spinner>
          <arrow-right v-else />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fetchProtocols } from '@/api/protocol';
import { mapState } from 'vuex';
import { fetchClientBasicInfo } from '@/api/client';

export default {
  components: {},
  data() {
    return {
      validating: false,
      client: {},
      isEdit: this.$route.name.includes('edit'),
    };
  },
  props: {
    basic: Object,
  },
  computed: {
    ...mapState({
      study: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.protocolForEdit,
      selectedProtocol: (state) => state.protocols.selectedProtocol,
    }),
    validForm() {
      const { name, studyDurationPerParticipant, plannedNumberOfParticipants } =
        this.basic;
      return (
        !!name &&
        studyDurationPerParticipant > 0 &&
        plannedNumberOfParticipants > 0 &&
        studyDurationPerParticipant <= 9999
      );
    },
    shouldValidate() {
      return !(
        this.$route.name.includes('edit-protocol') &&
        this.protocol.basic.name === this.basic.name
      );
    },
  },
  created() {
    this.getClientBasicInfo();
  },

  methods: {
    async handleNextClick() {
      this.validating = true;
      try {
        const { data } = await fetchProtocols(
          // {
          //   clientId: ,
          //   studyId: this.study.id,
          // },
          {
            'filter[name]': this.basic.name,
            'filter[client_id]': this.study.clientId,
          }
        );
        data.length === 0
          ? this.$emit('nextStep')
          : this.$toast.error(this.$t('error_protocol_name_in_use'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.message) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.validating = false;
      }
    },

    async getClientBasicInfo() {
      try {
        let clientId = this.isEdit
          ? this.selectedProtocol.study.clientId
          : this.study.clientId;
        const { data } = await fetchClientBasicInfo(clientId);
        this.client = data;
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style></style>
