<template>
  <b-badge
    pill
    :class="statusClass"
    class="d-inline-flex justify-content-center font-weight-normal"
  >
    <slot>{{ $t(text) }}</slot>
  </b-badge>
</template>

<script>
export default {
  name: 'WebBadgeComponent',
  props: {
    pill: {
      type: Boolean,
      default: true,
    },
    status: {
      type: Object,
    },
  },
  computed: {
    text() {
      if (this.status?.name === 'N/A') return '';
      return this.status?.name ? this.status?.name.replace(' ', '_') : '';
    },
    statusClass() {
      const name = this.status?.name
        ? this.status?.name?.toLowerCase().replace(' ', '-')
        : '';
      return `badge-${name}`;
    },
  },
};
</script>

<style scoped lang="scss">
$white: #fff;

$badge_colors: (
  'active': #4dc185,
  'inactive': #a1a8c0,
  'complete': #529a0a,
  'onTrack': #4dc185,
  'relapse': #f35656,
  'discontinued ': #565f77,
  'attention': #f58b44,
  'critical': #f35656,
  'screenedOut': #dadada,
  'draft': #41479b,
  'droppedOut': #565f77,
  'consentWithdrawn': #778ca2,
  'lost': #000000,
  'other': #badefc,
);

$badge-variants: (
  'active': (
    color: $white,
    background-color: map-get($badge_colors, 'active'),
  ),
  'inactive': (
    color: $white,
    background-color: map-get($badge_colors, 'inactive'),
  ),
  'complete': (
    color: $white,
    background-color: map-get($badge_colors, 'complete'),
  ),
  'on-track': (
    color: $white,
    background-color: map-get($badge_colors, 'onTrack'),
  ),
  'relapse': (
    color: $white,
    background-color: map-get($badge_colors, 'relapse'),
  ),
  'critical': (
    color: $white,
    background-color: map-get($badge_colors, 'critical'),
  ),
  'discontinued': (
    color: $white,
    background-color: map-get($badge_colors, 'discontinued'),
  ),
  'attention': (
    color: $white,
    background-color: map-get($badge_colors, 'attention'),
  ),
  'screened-out': (
    color: #565f77,
    background-color: map-get($badge_colors, 'screenedOut'),
  ),
  'draft': (
    color: $white,
    background-color: map-get($badge_colors, 'draft'),
  ),
  'dropped-out': (
    color: $white,
    background-color: map-get($badge_colors, 'droppedOut'),
  ),
  'consent-withdrawn': (
    color: $white,
    background-color: map-get($badge_colors, 'consentWithdrawn'),
  ),
  'lost': (
    color: $white,
    background-color: map-get($badge_colors, 'lost'),
  ),
  'other': (
    color: #565f77,
    background-color: map-get($badge_colors, 'other'),
  ),
);

@each $name, $variant in $badge-variants {
  .badge-#{$name} {
    @each $key, $value in $variant {
      #{$key}: #{$value};
    }
  }
}
</style>
