import {
  fetchProtocols,
  fetchAllProtocols,
  fetchProtocolsServerSide,
  getProtocolApprover,
  fetchProtocolsV2,
  getProtocolDetail,
} from '@/api/protocol';
import { fetchClients } from '@/api/client';
import { fetchAllStudies } from '@/api/study';

const initialState = {
  all: [],
  list: [],
  protocols: [],
  pagination: {},
  clients: [],
  studies: [],
  selectedProtocol: {},
  protocolForEdit: {},
  protocolParams: {},
  selectedApprover: {},
};
const state = { ...initialState };

const getters = {
  protocolOptions:
    (state) =>
    (isTopLevelProtocol = false) => {
      return isTopLevelProtocol
        ? state.all.map((item) => ({
            text: item.name,
            value: item.id,
            status: item.status.name,
          }))
        : state.protocols.map((item) => ({
            text: item.name,
            value: item.id,
            status: item.status.name,
          }));
    },
  isAppropriateProtocol: (state) => (view) => {
    if (view.includes('protocol-sites')) {
      if (state.selectedProtocol.status) {
        const { name } = state.selectedProtocol.status;
        return name === 'ACTIVE' || name === 'WAITING FOR APPROVAL';
      }
    }
    if (!view.includes('protocol-sites')) {
      if (state.protocols.length === 0) return false;
      return state.protocols.some((protocol) =>
        ['ACTIVE', 'WAITING FOR APPROVAL'].includes(protocol.status.name)
      );
    }
  },
  extractClients({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.clientId)?.length;
      !exists && acc.push({ title: curr.clientName, value: curr.clientId });
      return acc;
    }, []);
  },

  extractStudies({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.studyId)?.length;
      !exists &&
        acc.push({
          title: curr.studyName,
          value: curr.studyId,
          clientId: curr.clientId,
        });
      return acc;
    }, []);
  },
};

const mutations = {
  SET_PROTOCOL_APPROVER: (state, approver) => {
    // Workaround for empty response (returns empty array)
    state.selectedProtocol.approver =
      approver?.length !== 0 ? approver?.user : {};
    state.selectedApprover = approver?.length !== 0 ? approver : {};
  },
  SET_LIST_STUDY_PROTOCOLS: (state, protocols) => {
    state.protocols = protocols;
  },
  SET_PROTOCOLS: (state, protocols) => {
    state.list = protocols;
  },
  SET_ALL_PROTOCOLS: (state, protocols) => {
    state.all = protocols;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  SELECTED_PROTOCOL: (state, protocol) => {
    state.selectedProtocol = protocol;
  },
  SET_EDIT_PROTOCOL: (state, protocol) => {
    state.protocolForEdit = JSON.parse(JSON.stringify(protocol));
  },
  SET_PROTOCOL_PARAMS: (state, params) => {
    state.protocolParams = params;
  },
  SET_ALL_CLIENTS: (state, data) => {
    state.clients = data;
  },
  SET_ALL_STUDIES: (state, data) => {
    state.studies = data;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async fetchProtocols({ commit }, payload) {
    try {
      const { data, pagination } = await fetchProtocolsV2(payload);
      commit('SET_PROTOCOLS', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_PROTOCOLS', []);
      commit('SET_PAGINATION', {});
      return error;
    }
  },
  async fetchAllProtocols({ commit }, payload) {
    try {
      const { data, pagination } = await fetchProtocolsServerSide(payload);
      commit('SET_ALL_PROTOCOLS', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_ALL_PROTOCOLS', []);
      commit('SET_PAGINATION', {});
      return error;
    }
  },
  async fetchProtocolDetails({ commit }, payload) {
    try {
      const { data } = await getProtocolDetail(payload);
      commit('SELECTED_PROTOCOL', data);
    } catch (error) {
      commit('SELECTED_PROTOCOL', {});
      console.error(error);
      return error;
    }
  },
  async fetchProtocolFiltersData({ commit }) {
    try {
      const [clients, studies] = await Promise.all([
        fetchClients(),
        fetchAllStudies(),
      ]);
      commit('SET_ALL_CLIENTS', clients.data);
      commit('SET_ALL_STUDIES', studies.data);
    } catch (error) {
      commit('SET_ALL_CLIENTS', []);
      commit('SET_ALL_STUDIES', []);
      this.$toast.error(this.$t('error_something_went_wrong'));
    }
  },
  async fetchProtocolFiltersDataForClientUser({ commit }) {
    try {
      const studies = await fetchAllStudies();
      commit('SET_ALL_STUDIES', studies.data);
    } catch (error) {
      commit('SET_ALL_STUDIES', []);
      this.$toast.error(this.$t('error_something_went_wrong'));
    }
  },
  async dispatchFetchProtocolApprover({ commit }, payload) {
    try {
      const { data } = await getProtocolApprover(payload);
      commit('SET_PROTOCOL_APPROVER', data);
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async dispatchFetchAllProtocols({ commit }, payload) {
    try {
      const { data } = await fetchAllProtocols(payload);
      commit('SET_ALL_PROTOCOLS', data);
    } catch (error) {
      commit('SET_ALL_PROTOCOLS', []);
      return error;
    }
  },
  async dispatchFetchProtocols({ commit }, urlParams) {
    try {
      const { data } = await fetchProtocols(urlParams);
      commit('SET_LIST_STUDY_PROTOCOLS', data);
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  setProtocolParams({ commit }, params) {
    commit('SET_PROTOCOL_PARAMS', params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
