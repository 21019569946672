<template>
  <v-client-table
    :data="studiesListTableData"
    :columns="columns"
    :options="options"
    :class="[tableClass, { 'dashboard-table': isDashboardView }]"
    ref="studiesTable"
    @sorted="handleSorted"
  >
    <template slot="name" slot-scope="props">
      <span>{{ props.row.name }}</span>
      <span
        v-if="showHighlightTag && props.row.id === $route.params.studyId"
        class="highlight-tag"
        >{{ highlightTagText }}</span
      >
    </template>
    <template slot="createdAt" slot-scope="props">
      <span v-if="props.row.createdAt">
        {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
      </span>
    </template>
    <template slot="status" slot-scope="props">
      <span class="text-lowercase">{{ props.row.statusName }}</span>
    </template>
    <template slot="actions" slot-scope="props">
      <div class="cursor-pointer text-right" @click="goToDetail(props.row)">
        <span class="view-details">{{ $t('view') }}</span>
        <i class="fas fa-chevron-right ml-2"></i>
      </div>
    </template>
  </v-client-table>
</template>

<script>
import listHighLightMixin from '@/mixins/listHighLightMixin';

export default {
  props: {
    studiesListTableData: Array,
    orderBy: {
      type: Object,
      default: () => ({
        column: 'createdAt',
        ascending: false,
      }),
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    displayClientNameColumn: Boolean,
  },
  mixins: [listHighLightMixin],
  data() {
    return {
      isDashboardView: this.$route.name === 'dashboard',
      columns: ['name', 'createdAt', 'statusName', 'actions'],
      options: {
        headings: {
          createdAt: this.$t('date_creation'),
          clientName: this.$t('client_name_legal'),
          statusName: this.$t('status'),
          actions: '',
        },
        sortable: ['createdAt', 'name', 'statusName'],
        orderBy: this.orderBy,
        perPage: 9999,
      },
    };
  },
  created() {
    if (this.displayClientNameColumn) {
      this.columns.unshift('clientName');
    }
  },
  methods: {
    goToDetail(row) {
      if (!this.isLoading) this.$emit('goToDetail', row);
    },
    handleSorted({ column, ascending }) {
      this.$refs.studiesTable.setOrder(column, ascending);
      this.$emit('sorting', column, ascending);
    },
  },
};
</script>
