const initialState = {
  visible: true,
};
const state = { ...initialState };

const getters = {
  visible: (state) => state.visible,
};

const mutations = {
  SET_VISIBLE: (state, visible) => {
    state.visible = visible;
  },
  TOGGLE_VISIBLE: (state) => {
    state.visible = !state.visible;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  hide({ commit }) {
    commit('SET_VISIBLE', false);
  },
  show({ commit }) {
    commit('SET_VISIBLE', true);
  },
  toggle({ commit }) {
    commit('TOGGLE_VISIBLE');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
