<template>
  <div :class="{ 'p-5': $route.name === 'change-consent' }">
    <wizard-header
      :title="this.$t('change_consent')"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard
            :currentStep="step"
            wizardType="changeConsent"
          ></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <cardStudyInfo
        class="mt-2"
        :cardTitle="titleStudyInfo"
        :selectedObject="selectedParticipant"
        :keysList="keyListStudyInfo"
        :keyNames="tagNames"
        :keyOrder="tagsOrder"
      ></cardStudyInfo>
    </div>

    <div class="bg-white steps-padding">
      <step-one v-show="step === 0" @nextStep="handleNext" />
      <step-two
        v-show="step === 1"
        v-if="step === 1"
        @back="step--"
        :partnersChanged="step1Partners"
        :changeReason="changeReason"
      />
    </div>

    <b-modal
      v-model="showConfirmationModal"
      title="Add new Participant"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_add_participant') }}
      </p>
      <template #modal-footer="{ hide }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="$router.push({ name: 'view-participant' })"
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="hide()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WizardHeader from '@/components/WizardHeader';
import tailorScheduleMixin from '@/mixins/tailorScheduleMixin';
import { mapActions, mapGetters, mapState } from 'vuex';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import cardStudyInfo from '@/components/InfoCards/cardStudyInfo';
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';

export default {
  components: {
    WizardHeader,
    StepOne,
    StepTwo,
    cardStudyInfo,
  },
  mixins: [tailorScheduleMixin, cardStudyInfoMixin],
  data() {
    return {
      originalConsent: false,
      secondStepConsent: false,
      showConfirmationModal: false,
      step: 0,
      participant: {
        subjectCode: '',
        gender: null,
        height: null,
        weight: null,
        partners: [],
        tags: [],
        reason: null,
      },
      titleStudyInfo: 'study_information',
      titleParticipantInfo: 'participant_information',
      keyListStudyInfo: [
        'studyName',
        'protocolName',
        'siteName',
        'dateStart',
        'dateEnd',
      ],
      tagNames: {
        studyName: 'study',
        protocolName: 'protocol',
        siteName: 'site',
        dateStart: 'study_start_date',
        dateEnd: 'study_end_date_planned',
      },
      tagsOrder: {
        studyName: 1,
        protocolName: 2,
        siteName: 3,
        dateStart: 4,
        dateEnd: 5,
      },
      keyListParticipant: [
        'phone',
        'subjectId',
        'tags',
        'gender',
        'height',
        'weight',
      ],
      tagNamesParticipant: {
        phone: 'phone_number_mobile',
        subjectId: 'participant_id_subject',
        tags: 'type',
        gender: 'gender',
        height: 'height',
        weight: 'weight',
      },
      tagsOrderParticipant: {
        phone: 1,
        subjectId: 2,
        tags: 3,
        gender: 4,
        height: 5,
        weight: 6,
      },
      partnersArray: [],
      participantInfoCardData: {},
      step1Partners: [],
      changeReason: '',
    };
  },
  computed: {
    ...mapState({
      site: (state) => state.sites.selectedSite,
      study: (state) => state.studies.selectedStudy,
      selectedClient: (state) => state.clients.selectedClient,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      participantParams: (state) => state.participants.participantParams,
      partnerTestData: (state) => state.resources.tests,
      excludedTask: (state) => state.participants.excluded_games,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
  },

  created() {
    this.fetchPartners().finally(() => {
      let existedPartners = [];
      if (this.partners) {
        this.partners.map((partner) =>
          existedPartners.push({
            partnerId: partner.id,
            isEnabled: null,
            name: partner.name,
          })
        );
        this.participant.partners = existedPartners;
      }
    });
    this.getExcludedGames();
    this.fetchMovementTests();
    this.setBreadCrumbData({
      'client-create-participant': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'client-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'study-create-participant': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'study-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'protocol-create-participant': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },

        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'protocol-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'site-create-participant': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.site?.basic?.name,
          to: { name: 'view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'add-participant': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },

        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
    });
  },

  methods: {
    ...mapActions({
      fetchPartners: 'resources/dispatchFetchPartners',
    }),
    handleNext(partners, changeReason) {
      this.step++;
      this.step1Partners = partners;
      this.changeReason = changeReason;
    },
    handleTailorScheduleNextClick(games) {
      this.participant.games = games;
      this.step++;
    },
    // We store excluded games in state for Step 2
    async getExcludedGames() {
      const payload = this.participantParams;
      this.$store.dispatch('participants/tasksEnabled', payload);
    },
    // We transform API call and store it in state for Step 2
    async fetchMovementTests() {
      try {
        this.$store
          .dispatch('resources/dispatchTestGames', {
            'filter[protocol]': this.participantParams.protocolId,
            'filter[game_mode]': true,
          })
          .then(() => {
            const excluded = this.excludedTask;
            const participantTasks = this.refactor_partner_category_test_list(
              this.partnerTestData,
              excluded
            );
            this.$store.commit(
              'participants/SET_PARTNER_TASKS_LIST',
              participantTasks
            );
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style scoped>
.steps-padding {
  padding: 2rem 3rem 3rem 3rem;
}
</style>
