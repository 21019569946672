<template>
  <div>
    <h2 class="mb-4" v-if="!isView">{{ $t('overview') }}</h2>
    <study-information />

    <div class="overview-card">
      <p class="text-lg">{{ $t('protocol_information') }}</p>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6"> {{ $t('protocol_name') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ protocol.basic.name }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">{{ $t('protocol_approver') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              class="avenir-bold"
              v-if="protocol.approver.firstName && protocol.approver.lastName"
              >{{
                protocol.approver.firstName + ' ' + protocol.approver.lastName
              }}</b-col
            >
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">
              {{ $t('protocol_study_duration') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              protocol.basic.studyDurationPerParticipant
            }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">
              {{ $t('protocol_planned_num_participants') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              protocol.basic.plannedNumberOfParticipants
            }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">
              {{ $t('description') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ protocol.basic.description }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('protocol_schedule') }}</p>
      <p>
        <i class="ni ni-check-bold text-primary"></i>
        {{ $t('protocol_schedule_confirmation') }}
      </p>
    </div>
    <div
      class="overview-card"
      v-if="protocol.appleHealth || protocol.googleFit || protocol.fitbit"
    >
      <p class="text-lg">{{ $t('protocol_integrations') }}</p>
      <p class="avenir-bold" v-if="protocol.googleFit">
        <i class="ni ni-check-bold text-primary"></i>
        {{ $t('data_source_google_fit') }}
      </p>
      <p class="avenir-bold" v-if="protocol.appleHealth">
        <i class="ni ni-check-bold text-primary"></i>
        {{ $t('data_source_apple_health') }}
      </p>
      <p class="avenir-bold" v-if="protocol.fitbit">
        <i class="ni ni-check-bold text-primary"></i>
        {{ $t('data_source_fitbit') }}
      </p>
    </div>

    <b-row class="my-5" v-if="!isView">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="submitting"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="$emit(isEdit ? 'updateProtocol' : 'createProtocol')"
          :disabled="submitting"
          data-testid="submit-button"
        >
          {{ $route.name.includes('create') ? 'Create' : 'Update' }}
          {{ $t('protocol') }}
          <b-spinner small v-if="submitting"></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StudyInformation from '../Overviews/StudyInformation';

export default {
  components: { StudyInformation },
  data() {
    return {
      numberOfWeeks: 4,
      isView: this.$route.name.includes('view-protocol'),
      isEdit: this.$route.name.includes('edit-protocol'),
    };
  },
  props: {
    protocol: Object,
    submitting: Boolean,
  },
};
</script>

<style></style>
