<template>
  <b-modal
    v-model="isIdle"
    content-class="text-center"
    hide-footer
    no-close-on-backdrop
    title-tag="h2"
    centered
  >
    <template slot="modal-title">
      {{ $t('inactivity_detected') }}
    </template>
    <div class="main-text">
      <p>
        {{ transformParams($t('inactivity_popup_detail'), 20, 5) }}
      </p>
      <span
        >{{ time / 1000 }} {{ $t('inactivity_popup_detail_second_left') }}</span
      >
    </div>
  </b-modal>
</template>

<script>
import { transformTimeParams } from '@/utils/stringParams';

export default {
  data() {
    return {
      time: 5000,
    };
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

      if (this.time === 0) {
        clearInterval(timerId);
        this.$store.dispatch('auth/logout').then(() => {
          this.$toast.error(this.$t('toast_session_expired'));
        });
      }
    }, 1000);
  },
  methods: {
    transformParams(string, min, sec) {
      return transformTimeParams(string, min, sec);
    },
  },
};
</script>
