<template>
  <div>
    <b-row>
      <b-col>
        <h2>{{ $t('schedule_tailor') }}</h2>
        <p class="text-grey">
          {{ $t('schedule_tailor_detail') }}
        </p>
      </b-col>
    </b-row>
    <HorizontalDivider colorLine="e8e9ec"></HorizontalDivider>
    <div><b-spinner v-if="showSpinner"></b-spinner></div>
    <div v-if="!showSpinner">
      <div
        v-for="(partner, pi) in participantTasks"
        :key="partner.id"
        class="accordion partners-title"
        role="tablist"
      >
        <b-row>
          <b-col cols="4">
            <h3 class="pt-3 pb-2" v-b-toggle="`collapse-${partner.id}`">
              <img
                src="@/assets/icons/plus-icon.svg"
                alt="expand-accordion"
                class="when-closed mr-2"
              />
              <img
                src="@/assets/icons/close-icon.svg"
                alt="expand-accordion"
                class="when-opened mr-2"
              />
              <span v-if="selected[pi].highlighted === true" class="editing">
                {{ partner.name }}
                <edit-icon class="align-self-stretch ml-2"></edit-icon
              ></span>
              <span v-else>{{ partner.name }}</span>
            </h3>
          </b-col>
        </b-row>

        <b-collapse :id="`collapse-${partner.id}`" class="ml-4" visible>
          <b-row
            v-for="(category, i) in partner.categories"
            :key="i"
            class="mt-3 pb-4"
          >
            <b-col>
              <b-row>
                <b-col cols="4" class="d-flex align-items-center">
                  <h4
                    v-b-toggle="`collapse-${category.id}${i}-inner`"
                    size="sm"
                    class="child-toggle"
                    visible
                  >
                    <img
                      src="@/assets/icons/plus-icon.svg"
                      alt="expand-accordion"
                      class="when-closed mr-2"
                    />
                    <img
                      src="@/assets/icons/close-icon.svg"
                      alt="expand-accordion"
                      class="when-opened mr-2"
                    />
                    <span
                      v-if="selected[pi].categories[i].marked === true"
                      class="editing"
                    >
                      {{ $t(category.name) }}
                      <edit-icon class="align-self-stretch ml-2"></edit-icon
                    ></span>
                    <span v-if="selected[pi].categories[i].marked === false">
                      {{ $t(category.name) }}</span
                    >
                  </h4>
                </b-col>
                <!-- number of enabled tests -->
                <b-col cols="4" class="d-flex align-items-center"
                  ><p
                    :id="`testNumber${selected[pi].categories[i].id}`"
                    :class="
                      selected[pi].categories[i].marked === true
                        ? 'text-purple'
                        : 'text-blue'
                    "
                  >
                    <small
                      >{{ enabledNumberOfTests(selected[pi].categories[i]) }}
                      {{ $t('/') }}
                      {{ totalNumberOfTests(category) }}
                      {{ $t('enabled') }}</small
                    >
                  </p></b-col
                >
                <!-- enabled all row -->
                <b-col
                  cols="4"
                  class="text-blue clickable d-flex align-items-center"
                >
                  <div
                    :ref="`${partner.id}${category.name}`"
                    :id="`${partner.id}${category.name}`"
                  >
                    <small
                      v-if="
                        enabledNumberOfTests(selected[pi].categories[i]) >= 1
                      "
                      @click="
                        disable_enable_AllCategoryTests(
                          parseInt(pi),
                          parseInt(i),
                          (child = 0),
                          partner.id,
                          category.name
                        )
                      "
                      ><u>{{ $t('disable_all_tasks_from_this_category') }}</u>
                    </small>
                    <small
                      v-if="
                        enabledNumberOfTests(selected[pi].categories[i]) === 0
                      "
                      @click="
                        disable_enable_AllCategoryTests(
                          parseInt(pi),
                          parseInt(i),
                          (child = 1),
                          partner.id,
                          category.name
                        )
                      "
                      ><u>{{
                        $t('enable_all_tasks_from_this_category')
                      }}</u></small
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="displayTests">
                <b-collapse :id="`collapse-${category.id}${i}-inner`" visible>
                  <b-col v-for="(test, a) in category.tests" :key="test.id">
                    <b-form-checkbox
                      class="ml-3"
                      name="check-button-step2"
                      switch
                      v-model="
                        selected[pi].categories[i].tests[a].versions.enabled
                      "
                      @change="markCategoryPartner(pi, i, a)"
                    >
                      <div
                        v-if="
                          selected[pi].categories[i].tests[a].versions
                            .disabled !==
                          selected[pi].categories[i].tests[a].versions.enabled
                        "
                        class="d-block"
                      >
                        <span
                          v-if="
                            selected[pi].categories[i].tests[a].versions.enabled
                          "
                        >
                          {{ $t(test.name) }}
                        </span>
                        <span v-else class="text-grey">
                          {{ $t(test.name) }}
                        </span>
                      </div>
                      <div v-else class="editing d-block">
                        <span>{{ $t(test.name) }}</span>
                        <edit-icon class="align-self-stretch ml-2"></edit-icon>
                      </div>
                    </b-form-checkbox>
                  </b-col>
                </b-collapse>
              </b-row>
            </b-col>
          </b-row>
        </b-collapse>
        <HorizontalDivider
          colorLine="e8e9ec"
          colsWidth="12"
        ></HorizontalDivider>
      </div>
    </div>
    <div>
      <!-- change reason -->
      <b-row v-if="showChangesReason" class="mt-4 mb-4">
        <b-col>
          <h3>{{ $t('change_reason') }}</h3>
          <span>{{ $t('change_reason_info') }}</span>
          <b-form-radio-group v-model="changeReason" class="custom-radio">
            <b-form-radio value="change" class="d-block">
              <span class="opacity-6 avenir ml-0">
                {{ $t('change_reason_change') }}
              </span>
            </b-form-radio>
            <b-form-radio value="correction" class="d-block">
              <span class="opacity-6 avenir">
                {{ $t('change_reason_correction') }}
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <!-- info panel -->
      <b-row>
        <b-col>
          <info-panel
            v-if="changeReason && showChangesReason"
            class="mt-2"
            :title="alertTitle"
            :message="alertMessage"
            :inputNum="2"
            :radioText="inputText"
            @check-confirm2="checkConfirm"
          ></info-panel>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <base-button
            type="secondary"
            @click="$emit('back')"
            data-testid="back-button"
            >{{ $t('back') }}
          </base-button>
          <base-button
            type="primary"
            :disabled="disabledButton"
            @click="handleNextClick"
            rounded
            data-testid="next-button"
          >
            {{ $t('next') }}
            <arrow-right />
          </base-button>
        </b-col>
        <b-col></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tailorScheduleMixin from '@/mixins/tailorScheduleMixin';
import InfoPanel from '@/components/InfoPanel';
import EditIcon from '@/components/Icons/EditIcon';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import { isEqual, cloneDeep } from 'lodash';

export default {
  components: {
    EditIcon,
    InfoPanel,
    HorizontalDivider,
  },
  mixins: [tailorScheduleMixin],
  props: {
    rochePartner: Object,
    fromStepThree: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      alertTitle: '',
      inputText: this.$t('i_understand_continue'),
      alertMessage: '',
      changeReason: null,
      compareTasks: {},
      disabledButton: false,
      displayTests: false,
      excludedGamesList: [],
      excludedGames: [],
      loading: true,
      totalNum: 0,
      selected: {},
      selectedOriginalValues: {},
      showChangesReason: false,
      differentTasks: [],
    };
  },
  computed: {
    ...mapState({
      participant: (state) => state.participants.selectedParticipant,
      participantTasks: (state) => state.participants.participantTests,
      showSpinner: (state) => state.participants.tailorScheduleStep2Spinner,
      selectedTaskFromStepThree: ({ participants }) =>
        participants.selected_tasks,
    }),
  },

  created() {
    if (this.fromStepThree) {
      this.compareTasks = cloneDeep(this.participantTasks);
      this.cloneObject(this.selectedTaskFromStepThree);
      this.displayTests = true;
    } else {
      this.compareTasks = cloneDeep(this.participantTasks);
      this.cloneObject(this.participantTasks);
      this.displayTests = true;
    }
  },

  watch: {
    changeReason(value) {
      this.setInfoPanelTexts(value);
      this.$store.state.participants.changeReason;
    },
    rochePartner: {
      handler() {},
      deep: true,
    },
  },

  methods: {
    markCategoryPartner(part, cat, test) {
      let category = this.selected[part].categories[cat].tests;
      let original = this.compareTasks[part].categories[cat].tests;
      let testSelected = this.selected[part].categories[cat].tests[test];
      let originalTest = this.compareTasks[part].categories[cat].tests[test];

      if (isEqual(category, original)) {
        this.selected[part].categories[cat].marked = false;
        this.selected[part].highlighted = false;
        this.showChangesReason = false;
        this.disabledButton = false;
      } else {
        this.selected[part].categories[cat].marked = true;
        this.selected[part].highlighted = true;
        this.showChangesReason = true;
        this.disabledButton = true;
      }
      if (isEqual(testSelected, originalTest)) {
        const index = this.differentTasks.indexOf(testSelected.versions.id);
        this.differentTasks.splice(index, 1);
      } else {
        const reps = [];
        reps.push(testSelected.versions.id, ...this.differentTasks);
        this.differentTasks = reps;
      }
    },

    checkConfirm(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },

    setInfoPanelTexts(value) {
      value === 'change'
        ? ((this.alertTitle = this.$t('change_box_schedule_header')),
          (this.alertMessage = this.$t('change_box_schedule_change')))
        : ((this.alertTitle = this.$t('change_box_info_correction_header')),
          (this.alertMessage = this.$t('change_box_info_correction_detail')));
    },
    cloneObject(object) {
      this.selected = structuredClone(object);
      this.selectedOriginalValues = structuredClone(object);
    },

    disable_enable_AllCategoryTests(pi, i, child) {
      let categoryTests = this.selected[pi].categories[i].tests;

      let elements = Object.keys(categoryTests).length;
      for (let index = 0; index < elements; index++) {
        categoryTests[index].versions.enabled = child === 0 ? false : true;
      }

      this.selected[pi].categories[i].marked = true;
      this.selected[pi].highlighted = true;

      if (child === 0) {
        this.selected[pi].categories[i].marked = true;
        this.selected[pi].highlighted = true;
      }
      if (child === 1) {
        this.selected[pi].categories[i].marked = true;
        this.selected[pi].highlighted = true;
      }
    },

    totalNumberOfTests(category) {
      return Object.keys(category.tests).length;
    },

    enabledNumberOfTests(category) {
      let numbers = 0;
      for (let index = 0; index < Object.keys(category.tests).length; index++) {
        if (category.tests[index].versions.enabled) {
          numbers += 1;
        }
      }
      return numbers;
    },

    async handleNextClick() {
      this.loading = true;
      this.componentKey += 1;
      this.$emit('nextStep');
      this.loading = false;
      this.$store.commit('participants/SET_CHANGE_REASON', this.changeReason);
      this.$store.commit('participants/SET_SELECTED_TASKS', this.selected);
      this.$store.commit(
        'participants/SET_SELECTED_ORIGINAL_TASKS',
        this.selectedOriginalValues
      );
      this.$store.commit('participants/SET_CHANGED_TESTS', this.differentTasks);
    },
  },
};
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}

.editing {
  color: #3331e0;
}

.text-purple {
  color: #3331e0;
}

.text-blue {
  color: #046db3;
}

.text-grey {
  color: #a1a8c0;
}

.tasks-list {
  display: grid;
  grid-template-rows: repeat(5, min-content);
  grid-auto-flow: column;
  column-gap: 35px;
}

.child-toggle {
  img {
    width: 11px;
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
