<template>
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.992814 0.667714C0.666147 0.994381 0.666147 1.52105 0.992814 1.84771L6.53281 7.38772C6.79281 7.64771 7.21281 7.64771 7.47281 7.38772L13.0128 1.84771C13.3395 1.52105 13.3395 0.994381 13.0128 0.667715C12.6861 0.341048 12.1595 0.341048 11.8328 0.667715L6.99948 5.49438L2.16615 0.661048C1.84615 0.341048 1.31281 0.341048 0.992814 0.667714Z"
      fill="#046DB3"
    />
  </svg>
</template>
  
  <script>
export default {};
</script>
  
  <style></style>