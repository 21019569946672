<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.875 3.95833C4.875 3.42906 5.25043 3 5.71354 3H17.4531C17.9162 3 18.2917 3.42906 18.2917 3.95833C18.2917 4.48761 17.9162 4.91667 17.4531 4.91667H5.71354C5.25043 4.91667 4.875 4.48761 4.875 3.95833ZM4.875 7.79167C4.875 7.26239 5.25043 6.83333 5.71354 6.83333H17.4531C17.9162 6.83333 18.2917 7.26239 18.2917 7.79167C18.2917 8.32094 17.9162 8.75 17.4531 8.75H5.71354C5.25043 8.75 4.875 8.32094 4.875 7.79167ZM4.875 11.625C4.875 11.0957 5.25043 10.6667 5.71354 10.6667H17.4531C17.9162 10.6667 18.2917 11.0957 18.2917 11.625C18.2917 12.1543 17.9162 12.5833 17.4531 12.5833H5.71354C5.25043 12.5833 4.875 12.1543 4.875 11.625ZM4.875 15.4583C4.875 14.9291 5.25043 14.5 5.71354 14.5H17.4531C17.9162 14.5 18.2917 14.9291 18.2917 15.4583C18.2917 15.9876 17.9162 16.4167 17.4531 16.4167H5.71354C5.25043 16.4167 4.875 15.9876 4.875 15.4583Z"
      fill="#C5D8E4"
    />
    <circle cx="2.95833" cy="3.95833" r="0.958333" fill="#C5D8E4" />
    <ellipse
      cx="2.95833"
      cy="7.79036"
      rx="0.958333"
      ry="0.958333"
      fill="#C5D8E4"
    />
    <ellipse
      cx="2.95833"
      cy="11.6263"
      rx="0.958333"
      ry="0.958333"
      fill="#C5D8E4"
    />
    <ellipse
      cx="2.95833"
      cy="15.4583"
      rx="0.958333"
      ry="0.958334"
      fill="#C5D8E4"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
