<template>
  <b-modal
    v-if="value"
    @ok="updateLanguage"
    @hide="close"
    :visible="$store.state.modals.isModalChooseLanguageVisible"
    :ok-title="$t('change_language')"
    :ok-disabled="isLoading || this.$store.state.auth.lang === this.value.code"
    :title="$t('language_preference')"
    :cancel-title="$t('cancel')"
    :cancel-disabled="isLoading"
    id="modal-change-language"
    hide-header-close
    no-close-on-backdrop
  >
    <v-select
      class="dropdown-languages"
      :options="languages"
      :value="value"
      :clearable="false"
      @input="chooseLanguage"
    />
  </b-modal>
</template>

<script>
import { fetchLanguages } from '@/api/resources';
import { updateLanguage } from '@/api/experts';

export default {
  data() {
    return {
      value: null,
      languages: null,
      isLoading: false,
    };
  },
  created() {
    fetchLanguages().then(({ data }) => {
      this.updateLabels(data);
      this.languages = data;
      this.updateInitValue();
    });
  },
  methods: {
    updateLabels(source = this) {
      source.forEach((e) => {
        e.label =
          e.code === 'en_GB' ? this.$t('lang_english') : this.$t('lang_german');
      });
    },
    updateInitValue() {
      this.value = this.languages.find(
        (el) => el.code === this.$store.state.auth.lang
      );
    },
    chooseLanguage(val) {
      this.value = val;
    },
    updateLanguage() {
      this.isLoading = true;
      updateLanguage(this.value.code)
        .then(() => {
          this.$store.commit('auth/SET_USER_LANGUAGE', this.value.code);
          this.updateLabels(this.languages);
          this.isLoading = false;
          this.$store.commit('modals/SET_VISIBLE', false);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast.error(this.$t(err.data.msg));
        });
    },
    close(bvModalEvent) {
      if (!this.isLoading) {
        this.updateInitValue();
        this.$store.commit('modals/SET_VISIBLE', false);
      } else {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
#modal-change-language {
  .vs__dropdown-option--selected {
    opacity: 0.5;
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  .modal-content {
    max-width: 480px;
  }
  .modal-title {
    font-size: 24px;
  }
  .modal-body {
    padding: 40px 40px 0;
  }
  .modal-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 56px 40px 40px;
    justify-content: center;
    button {
      flex-grow: 1;
      font-size: 1rem;
      margin: 0;
      border-radius: 100px;
    }
    .btn-secondary {
      color: #046db3;
    }
  }
}
</style>
