export default {
  methods: {
    builtObject(selectedObject, keysList, keyNames, keyOrder) {
      // Extract all keys
      const obj = selectedObject;
      const extract = (obj, ...keys) => {
        const newObject = Object.assign({});
        Object.keys(obj).forEach((key) => {
          if (keys.includes(key)) {
            newObject[key] = obj[key];
            delete obj[key];
          }
        });
        return newObject;
      };

      // Extract the selected keys from the view
      const keyList = keysList;
      const extractData = extract(obj, ...keyList);

      // convert the object into array
      const propertyNames = Object.entries(extractData);

      // create array of objects with properties needed for the component
      let participantDataObject = propertyNames.map((prop) => {
        let properties = {
          label: prop[0],
          value: prop[1],
          order: prop[0],
        };
        // Get the corresponding text key for translations
        const tagNames = keyNames;
        // Set the name for the label and the order prop to display keys in the component
        const tagOrder = keyOrder;

        for (const key in tagNames) {
          if (Object.hasOwnProperty.call(tagNames, key)) {
            properties['label'] = tagNames[prop[0]];
            properties['order'] = tagOrder[prop[0]];
          }
        }
        return properties;
      });

      // Order the array of objects according with the chosen order from the view
      participantDataObject.sort((a, b) => {
        return a.order - b.order;
      });
      //this.participantInfoCardData = participantDataObject;
      this.items = participantDataObject;
    },
  },
};
