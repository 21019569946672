export default {
  methods: {
    handleFiltersData(target) {
      const { clientId, siteId, studyId, protocolId } = this.filter;

      switch (target) {
        case 'client':
          if (clientId) this.filtersByClient(clientId);
          if (!clientId) this.noFilters();
          break;

        case 'study':
          if (studyId) this.filtersByStudy(studyId);
          if (!studyId) this.filtersByClient(clientId);
          break;

        case 'protocol':
          if (protocolId) this.filtersByProtocol(protocolId);
          if (!protocolId) this.filtersByStudy(studyId);
          break;

        case 'site':
          if (siteId) this.filtersBySite(siteId);
          if (!siteId) this.filtersByProtocol(protocolId);
      }
    },
    noFilters() {
      this.studiesData = [...this.studies];
      this.protocolsData = [...this.protocols];
      this.sitesData = [...this.sites];
      this.filter = {
        clientId: '',
        studyId: '',
        protocolId: '',
        siteId: '',
      };
    },
    filtersByClient(clientId) {
      this.studiesData = this.studies.filter(
        (study) => study.clientId === clientId
      );
      this.protocolsData = this.protocols.filter(
        (protocol) => protocol.clientId === clientId
      );
      this.sitesData = this.sites.filter((site) => site.clientId === clientId);
      this.filter = {
        clientId: clientId,
        studyId: '',
        protocolId: '',
        siteId: '',
      };
    },
    filtersByStudy(studyId) {
      this.protocolsData = this.protocols.filter(
        (protocol) => protocol.studyId === studyId
      );
      this.sitesData = this.sites.filter((site) => site.studyId === studyId);

      const { clientId: studyClientId } = this.studies.find(
        (study) => study.value === studyId
      );
      this.studiesData = this.studies.filter(
        (study) => study.clientId === studyClientId
      );

      this.filter = {
        clientId: studyClientId,
        studyId: studyId,
        protocolId: '',
        siteId: '',
      };
    },
    filtersByProtocol(protocolId) {
      const { clientId: protocolClientId, studyId: protocolStudyId } =
        this.protocols.find((protocol) => protocol.value === protocolId);

      this.studiesData = this.studies.filter(
        (study) => study.clientId === protocolClientId
      );
      this.protocolsData = this.protocols.filter(
        (protocol) => protocol.studyId === protocolStudyId
      );
      this.sitesData = this.sites.filter(
        (site) => site.protocolId === protocolId
      );

      this.filter = {
        clientId: protocolClientId,
        studyId: protocolStudyId,
        protocolId: protocolId,
        siteId: '',
      };
    },
    filtersBySite(siteId) {
      const {
        clientId: siteClientId,
        protocolId: siteProtocolId,
        studyId: siteStudyId,
      } = this.sites.find((site) => site.value === siteId);

      this.studiesData = this.studies.filter(
        (study) => study.clientId === siteClientId
      );
      this.protocolsData = this.protocols.filter(
        (protocol) => protocol.studyId === siteStudyId
      );
      this.sitesData = this.sites.filter(
        (site) => site.protocolId === siteProtocolId
      );

      this.filter = {
        clientId: siteClientId,
        studyId: siteStudyId,
        protocolId: siteProtocolId,
        siteId: siteId,
      };
    },
  },
};
