<template>
  <v-client-table
    :data="protocolsListTableData"
    :columns="columns"
    :options="options"
    class="tableClass"
    ref="protocolsTable"
    @sorted="handleSorted"
  >
    <template slot="name" slot-scope="props">
      <span>{{ props.row.name }}</span
      ><span
        v-if="showHighlightTag && props.row.id === $route.params.protocolId"
        class="highlight-tag"
        >{{ highlightTagText }}</span
      >
    </template>
    <template slot="createdAt" slot-scope="props">
      <span v-if="props.row.createdAt">
        {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
      </span>
    </template>
    <template slot="status" slot-scope="props">
      <span class="text-lowercase">
        {{ props.row.status.name.split('_').join(' ') }}
      </span>
    </template>
    <template slot="actions" slot-scope="props">
      <div class="cursor-pointer text-right" @click="goToDetail(props.row)">
        <span class="view-details">{{ $t('view') }}</span>
        <i class="fas fa-chevron-right ml-2"></i>
      </div>
    </template>
  </v-client-table>
</template>
<script>
import listHighLightMixin from '@/mixins/listHighLightMixin';

export default {
  props: {
    protocolsListTableData: Array,
    orderBy: {
      type: Object,
      default: () => ({
        column: 'createdAt',
        ascending: false,
      }),
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [listHighLightMixin],
  data() {
    return {
      columns: ['name', 'createdAt', 'status', 'actions'],
      options: {
        headings: {
          name: this.$t('name'),
          createdAt: this.$t('date_creation'),
          status: this.$t('status'),
          actions: '',
        },
        sortable: ['createdAt', 'name'],
        orderBy: this.orderBy,
        perPage: 9999,
      },
    };
  },
  methods: {
    goToDetail(row) {
      if (!this.isLoading) this.$emit('goToDetail', row);
    },
    handleSorted({ column, ascending }) {
      this.$refs.protocolsTable.setOrder(column, ascending);
      this.$emit('sorting', column, ascending);
    },
  },
};
</script>
