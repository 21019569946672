<template>
  <b-pagination
    v-model="page"
    :total-rows="totalRows"
    :perPage="pageSize"
    :hide-ellipsis="true"
    class="mt-4"
    aria-controls="my-table"
    align="center"
    :first-text="$t('first')"
    :last-text="$t('last')"
    :prev-text="$t('prev')"
    :next-text="$t('next')"
    @input="handleInput"
  ></b-pagination>
</template>

<script>
import { isNil } from 'lodash/lang';
import { paginationDefaults } from '@/constants/pagination-constants';

export default {
  name: 'PaginatorTableComponent',
  data() {
    return {
      page: !isNil(this.currentPage)
        ? this.currentPage
        : paginationDefaults.firstPageIndex,
    };
  },
  props: ['currentPage', 'totalRows', 'pageSize'],
  methods: {
    handleInput() {
      this.$emit('changePageEvent', this.page);
    },
  },
};
</script>

<style scoped></style>
