export const periodSelectorOptions = {
  OVERALL: 'Overall',
  THIS_WEEK: 'This week',
  FIRST_TO_LAST_WEEK: 'First-to-last-week',
  LAST_WEEK: 'Last-week',
};

export const typeSelectorOptions = {
  SUMMARY: 'Summary',
  DETAILED: 'Detailed',
};

export const messageKeys = {
  MESSAGE_1: 'period_info_1',
  MESSAGE_2: 'period_info_2',
};
