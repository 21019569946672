const initialState = {
  isModalChooseLanguageVisible: false,
};
const state = { ...initialState };

const mutations = {
  SET_VISIBLE: (state, visible) => {
    state.isModalChooseLanguageVisible = visible;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
