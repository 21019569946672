<template>
  <!-- TODO: make this dynamic for all select -->
  <v-select
    :options="data"
    class="filter-dropdown"
    :placeholder="`Select ${title}`"
    @input="changeFilter"
    label="title"
  />
</template>

<script>
export default {
  name: 'filter-select',
  props: {
    data: Array,
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    changeFilter(data) {
      this.$emit('changeFilter', data);
    },
  },
};
</script>

<style lang="scss">
.filter-dropdown {
  margin-left: auto;
  border-radius: 50px !important;
  .vs__dropdown-toggle {
    border-radius: 50px !important;
    border: 1px solid #e8e9ec;
    display: flex;
    min-width: 140px;
  }
  &:not(.full) {
    .vs__dropdown-menu {
      max-width: 300px;
      right: 0;
      left: unset;
    }
  }
  .vs__search::placeholder {
    color: #565f77;
    padding-left: 5px;
  }
  .vs__selected {
    color: #046db3;
    // margin-top: 0.7rem;

    &:before {
      content: '';
      color: #565f77;
      margin-right: 5px;
    }
  }
}

.client-dropdown {
  .vs__selected {
    &:before {
      content: 'Client: ';
    }
  }
}
.study-dropdown {
  .vs__selected {
    &:before {
      content: 'Study: ';
    }
  }
}
.protocol-dropdown {
  .vs__selected {
    &:before {
      content: 'Protocol: ';
    }
  }
}
.site-dropdown {
  .vs__selected {
    &:before {
      content: 'Site: ';
    }
  }
}
.period-dropdown {
  .vs__selected {
    &:before {
      content: 'Period: ';
    }
  }
}
.report-dropdown {
  .vs__selected {
    &:before {
      content: 'Report: ';
    }
  }
}
</style>
