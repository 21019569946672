import {
  fetchParticipants,
  fetchParticipant,
  participantsTasksEnabled,
  createParticipantNote,
  getAllParticipantNotes,
  updateVisitSchedule,
  fetchAllParticipants,
} from '@/api/participant';
import { fetchClients } from '@/api/client';
import { fetchAllStudies } from '@/api/study';
import { fetchAllProtocols } from '@/api/protocol';
import { fetchSites } from '@/api/site';
import { participantStatusId } from '@/constants/participantStatuses';

const initialState = {
  all: [],
  list: [],
  pagination: {},
  selectedParticipant: {},
  selected_tasks: {},
  selected_original_tasks: {},
  participantParams: {},
  participantTests: [],
  excluded_games: [],
  association: {},
  showParticipantModal: false,
  changeReason: null,
  changedTestsPayload: [],
  selectedParticipantOrigin: {},
  tailorScheduleStep2Spinner: true,
  participantNotes: [],
  clients: [],
  studies: [],
  protocols: [],
  sites: [],
};
const state = { ...initialState };

const getters = {
  extractClients({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.clientId)?.length;
      !exists && acc.push({ title: curr.clientName, value: curr.clientId });
      return acc;
    }, []);
  },
  extractSites({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.siteId)?.length;
      !exists &&
        acc.push({
          title: curr.siteName,
          value: curr.siteId,
          clientId: curr.clientId,
          studyId: curr.studyId,
          protocolId: curr.protocolId,
        });
      return acc;
    }, []);
  },
  extractStudies({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.studyId)?.length;
      !exists &&
        acc.push({
          title: curr.studyName,
          value: curr.studyId,
          clientId: curr.clientId,
        });
      return acc;
    }, []);
  },
  extractProtocols({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter(
        (item) => item.value === curr.protocolId
      )?.length;
      !exists &&
        acc.push({
          title: curr.protocolName,
          value: curr.protocolId,
          clientId: curr.clientId,
          studyId: curr.studyId,
        });
      return acc;
    }, []);
  },
  isParticipantDroppedOut({ selectedParticipant }) {
    return (
      selectedParticipant.status.id === participantStatusId.discontinuedReason
    );
  },
};

const mutations = {
  SET_ALL_PARTICIPANTS: (state, participants) => {
    state.all = participants;
  },
  SET_LIST_PARTICIPANTS: (state, participant) => {
    state.list = participant;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  SET_SELECTED_PARTICIPANT: (state, participant) => {
    state.selectedParticipant = participant;
  },
  SET_SELECTED_PARTICIPANT_COPY: (state, participant) => {
    state.selectedParticipantOrigin = participant;
  },
  SET_PARTICIPANT_PARAMS: (state, params) => {
    state.participantParams = params;
  },
  SET_PARTNER_TASKS_LIST: (state, tasksList) => {
    state.participantTests = tasksList;
  },
  SET_ASSOCIATION: (state, site) => {
    state.association = site;
  },
  SET_TOGGLE_PARTICIPANT_MODAL: (state) => {
    state.showParticipantModal = !state.showParticipantModal;
  },
  TAILOR_SCHEDULE_SPINNER: (state, value) => {
    state.tailorScheduleStep2Spinner = value;
  },

  SET_EXCLUDED_GAMES: (state, data) => {
    state.excluded_games = data;
  },
  SET_SELECTED_TASKS: (state, data) => {
    state.selected_tasks = data;
  },
  SET_SELECTED_ORIGINAL_TASKS: (state, data) => {
    state.selected_original_tasks = data;
  },
  SET_CHANGED_TESTS: (state, data) => {
    state.changedTestsPayload = data;
  },
  SET_CHANGE_REASON: (state, data) => {
    state.changeReason = data;
  },
  SET_ALL_CLIENTS: (state, data) => {
    state.clients = data;
  },
  SET_ALL_STUDIES: (state, data) => {
    state.studies = data;
  },
  SET_ALL_PROTOCOLS: (state, data) => {
    state.protocols = data;
  },
  SET_ALL_SITES: (state, data) => {
    state.sites = data;
  },

  SET_PARTICIPANT_NOTES: (state, data) => {
    state.participantNotes = data;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  setParticipantParams({ commit }, params) {
    commit('SET_PARTICIPANT_PARAMS', params);
  },
  async fetchParticipantParams({ commit }, payload) {
    try {
      const { data } = await fetchParticipants(payload);
      await fetchParticipants(payload);
      const { clientId, studyId, protocolId, siteId, id } = data[0];
      const params = {
        clientId,
        studyId,
        protocolId,
        siteId,
        participantId: id,
      };
      commit('SET_PARTICIPANT_PARAMS', params);
    } catch (error) {
      console.error(error);
    }
  },
  tasksEnabled({ commit }, payload) {
    return new Promise((resolve, reject) => {
      participantsTasksEnabled(payload)
        .then((response) => {
          let arrayTasks = [];
          response.data.excludedGames.forEach((element) => {
            arrayTasks.push(element.testVersion.id);
          });
          commit('SET_EXCLUDED_GAMES', arrayTasks);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async saveTaskList({ commit }, data) {
    try {
      commit('SET_PARTNER_TASKS_LIST', data);
      return 'ok';
    } catch (error) {
      commit('SET_PARTNER_TASKS_LIST', []);
      return error;
    }
  },

  async dispatchFetchParticipants({ commit }, payload) {
    try {
      const { data } = await fetchParticipants(payload);
      commit('SET_LIST_PARTICIPANTS', data);
    } catch (error) {
      commit('SET_LIST_PARTICIPANTS', []);
      return error;
    }
  },
  async dispatchFetchParticipant({ commit }, payload) {
    try {
      const { data } = await fetchParticipant(payload);
      commit('SET_SELECTED_PARTICIPANT', data);
      commit('SET_SELECTED_PARTICIPANT_COPY', { ...data });
    } catch (error) {
      commit('SET_SELECTED_PARTICIPANT', {});
      return error;
    }
  },
  async fetchAllParticipants({ commit }, payload) {
    try {
      const { data, pagination } = await fetchAllParticipants(payload);
      commit('SET_ALL_PARTICIPANTS', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_ALL_PARTICIPANTS', []);
      commit('SET_PAGINATION', {});
      console.error('error_something_went_wrong');
    }
  },
  async fetchParticipantListFiltersDataForClientUser({ commit }) {
    try {
      const [studies, protocols, sites] = await Promise.all([
        fetchAllStudies(),
        fetchAllProtocols(),
        fetchSites(),
      ]);
      commit('SET_ALL_STUDIES', studies.data);
      commit('SET_ALL_PROTOCOLS', protocols.data);
      commit('SET_ALL_SITES', sites.data);
    } catch (error) {
      commit('SET_ALL_STUDIES', []);
      commit('SET_ALL_PROTOCOLS', []);
      commit('SET_ALL_SITES', []);
      this.$toast.error(this.$t('error_something_went_wrong'));
    }
  },
  async fetchFiltersData({ commit }) {
    try {
      const [clients, studies, protocols, sites] = await Promise.all([
        fetchClients(),
        fetchAllStudies(),
        fetchAllProtocols(),
        fetchSites(),
      ]);
      commit('SET_ALL_CLIENTS', clients.data);
      commit('SET_ALL_STUDIES', studies.data);
      commit('SET_ALL_PROTOCOLS', protocols.data);
      commit('SET_ALL_SITES', sites.data);
    } catch (error) {
      commit('SET_ALL_CLIENTS', []);
      commit('SET_ALL_STUDIES', []);
      commit('SET_ALL_PROTOCOLS', []);
      commit('SET_ALL_SITES', []);
      this.$toast.error(this.$t('error_something_went_wrong'));
    }
  },
  async sendParticipantNote(_, payload) {
    try {
      return await createParticipantNote(payload);
    } catch (error) {
      return error;
    }
  },
  async getParticipantNote({ commit }, payload) {
    try {
      const { data } = await getAllParticipantNotes(payload);
      commit('SET_PARTICIPANT_NOTES', data);
    } catch (error) {
      commit('SET_ALL_PARTICIPANTS', []);
      return error;
    }
  },
  async updateParticipantVisit(_, payload) {
    try {
      return await updateVisitSchedule(payload);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
