<template>
  <b-badge
    pill
    class="d-inline-flex justify-content-center"
    :class="large && 'text-lg font-weight-normal'"
    :variant="variant"
  >
    <slot></slot>
  </b-badge>
</template>

<script>
export default {
  name: 'app-badge',
  props: {
    variant: String,
    large: Boolean,
  },
};
</script>

<style scoped lang="scss">
.badge {
  font-size: 11px;
}

.badge-success {
  background-color: var(--green);
  color: #fff;
}

.badge-secondary {
  background-color: #a1a8c0;
  color: #fff;
}

.badge-grey {
  background: #e8e9ec;
  color: #a1a8c0;
}

.badge-danger {
  background: rgba(243, 86, 86, 0.2);
  color: #f35656;
}

.badge-orange {
  background-color: #f58b44;
  color: #fff;
}

.badge-green {
  background: #4dc185;
  color: #fff;
}

.badge-red {
  background: #f35656;
  color: #fff;
}

.badge-blue {
  background: rgba(4, 109, 179, 0.2);
  color: #046db3;
}
.text-lg {
  font-size: 1.125rem !important;
}
</style>
