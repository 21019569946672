<template>
  <b-row align-h="end" class="list-filters">
    <b-col :cols="vertical ? 12 : 3" v-if="filtersToDisplay.client">
      <v-select
        :options="clientsData"
        :placeholder="$t('client_select')"
        :selectable="() => !loading"
        v-model="filter.clientId"
        @input="applyFilter(filterTarget.Client)"
        label="title"
        :reduce="(i) => i.value"
        class="filter-dropdown client-dropdown"
      />
    </b-col>
    <b-col :cols="vertical ? 12 : 3" v-if="filtersToDisplay.study">
      <v-select
        :options="studiesData"
        :placeholder="$t('study_select')"
        :selectable="() => !loading"
        v-model="filter.studyId"
        @input="applyFilter(filterTarget.Study)"
        label="title"
        :reduce="(i) => i.value"
        class="filter-dropdown study-dropdown"
      />
    </b-col>
    <b-col :cols="vertical ? 12 : 3" v-if="filtersToDisplay.protocol">
      <v-select
        :options="protocolsData"
        :placeholder="$t('protocol_select')"
        :selectable="() => !loading"
        v-model="filter.protocolId"
        @input="applyFilter(filterTarget.Protocol)"
        label="title"
        :reduce="(i) => i.value"
        class="filter-dropdown protocol-dropdown"
      />
    </b-col>
    <b-col :cols="vertical ? 12 : 3" v-if="filtersToDisplay.site">
      <v-select
        :options="sitesData"
        :placeholder="$t('site_select')"
        :selectable="() => !loading"
        v-model="filter.siteId"
        @input="applyFilter(filterTarget.Site)"
        label="title"
        :reduce="(i) => i.value"
        class="filter-dropdown site-dropdown"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ListFiltersDataMixin from '@/mixins/ListFiltersDataMixin';
import {
  listClientFilterDataObjectMapper,
  listProtocolFilterDataObjectMapper,
  listSiteFilterDataObjectMapper,
  listStudyFilterDataObjectMapper,
} from '@/object-mappers/listFilters.object-mapper';

export default {
  name: 'ListFiltersComponent',
  mixins: [ListFiltersDataMixin],
  props: {
    filtersToDisplay: Object,
    vertical: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      filter: {
        studyId: '',
        protocolId: '',
        siteId: '',
        clientId: '',
      },
      filterTarget: {
        Client: 'client',
        Study: 'study',
        Protocol: 'protocol',
        Site: 'site',
      },
      clientsData: [],
      studiesData: [],
      protocolsData: [],
      sitesData: [],
    };
  },
  computed: {
    ...mapState({
      clients: ({ listFilters }) =>
        listClientFilterDataObjectMapper(listFilters.clients),
      studies: ({ listFilters }) =>
        listStudyFilterDataObjectMapper(listFilters.studies),
      protocols: ({ listFilters }) =>
        listProtocolFilterDataObjectMapper(listFilters.protocols),
      sites: ({ listFilters }) =>
        listSiteFilterDataObjectMapper(listFilters.sites),
    }),
  },
  created() {
    this.getFiltersData();
  },
  methods: {
    ...mapActions({
      fetchClientsData: 'listFilters/fetchClients',
      fetchStudiesData: 'listFilters/fetchStudies',
      fetchProtocolsData: 'listFilters/fetchProtocols',
      fetchSitesData: 'listFilters/fetchSites',
    }),
    async getFiltersData() {
      try {
        this.loading = true;
        this.initFiltersData();
        const promises = this.setFiltersPromises();
        await Promise.all(promises);
        this.setFiltersData(this.filtersToDisplay);
      } catch (error) {
        this.displayError('Error get list filters data', error);
      } finally {
        this.loading = false;
      }
    },
    initFiltersData() {
      this.clientsData = [this.$t('loading')];
      this.studiesData = [this.$t('loading')];
      this.protocolsData = [this.$t('loading')];
      this.sitesData = [this.$t('loading')];
    },
    setFiltersPromises() {
      const promises = [];

      if (this.filtersToDisplay.client) {
        promises.push(this.fetchClientsData());
      }
      if (this.filtersToDisplay.study) {
        promises.push(this.fetchStudiesData());
      }
      if (this.filtersToDisplay.protocol) {
        promises.push(this.fetchProtocolsData());
      }
      if (this.filtersToDisplay.site) {
        promises.push(this.fetchSitesData());
      }

      return promises;
    },
    setFiltersData(filtersToDisplay) {
      if (filtersToDisplay.client) {
        this.clientsData = [...this.clients];
      }
      if (filtersToDisplay.study) {
        this.studiesData = [...this.studies];
      }
      if (filtersToDisplay.protocol) {
        this.protocolsData = [...this.protocols];
      }
      if (filtersToDisplay.site) {
        this.sitesData = [...this.sites];
      }
    },
    applyFilter(target) {
      this.handleFiltersData(target);
      this.$emit('changeFilterEvent', this.filter);
    },
  },
};
</script>

<style lang="scss">
// selected option truncate ellipsis
.list-filters {
  &:not(.vs--open) .vs__selected + .vs__search {
    // force this to not use any space
    // we still need it to be rendered for the focus
    width: 0;
    padding: 0;
    margin: 0;
    border: none;
    height: 0;
  }

  .vs__selected-options {
    // do not allow growing
    width: 0;
  }

  .vs__selected {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    line-height: 2.2;
  }
}
</style>
