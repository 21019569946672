<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="17" fill="#E5E5E5" />
    <g id="Healios Users &#62; Left Menu" clip-path="url(#clip0_27_2)">
      <rect
        width="1440"
        height="1014"
        transform="translate(-26 -512)"
        fill="#F7F7FA"
      />
      <g id="side_bar/contacts">
        <g id="side_nav_bg" filter="url(#filter0_d_27_2)">
          <rect x="-26" y="-512" width="242" height="1014" fill="#046DB3" />
        </g>
        <g id="Frame 427318294">
          <g id="Frame 427318216">
            <g id="Frame 427318286">
              <g id="Frame 427318301">
                <g id="carbon:cloud-auditing" opacity="0.75">
                  <path
                    id="Vector"
                    d="M16.5 8V11.75H1.5V1.75H9V0.5H1.5C1.16848 0.5 0.850537 0.631696 0.616116 0.866116C0.381696 1.10054 0.25 1.41848 0.25 1.75V11.75C0.25 12.0815 0.381696 12.3995 0.616116 12.6339C0.850537 12.8683 1.16848 13 1.5 13H6.5V15.5H4V16.75H14V15.5H11.5V13H16.5C16.8315 13 17.1495 12.8683 17.3839 12.6339C17.6183 12.3995 17.75 12.0815 17.75 11.75V8H16.5ZM10.25 15.5H7.75V13H10.25V15.5Z"
                    fill="white"
                  />
                  <path
                    id="Vector_2"
                    d="M12.125 7.375L9 4.275L9.99375 3.29375L12.125 5.40625L16.7562 0.8125L17.75 1.8L12.125 7.375Z"
                    fill="white"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_27_2"
        x="-38"
        y="-530"
        width="278"
        height="1050"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_27_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_27_2"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_27_2">
        <rect
          width="1440"
          height="1014"
          fill="white"
          transform="translate(-26 -512)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
