<template>
  <div class="m-5">
    <b-row>
      <b-col>
        <h2>Navigator View</h2>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import setBreadCrumbData from './mixins/breadcrumbMixin';
export default {
  created() {
    this.setBreadCrumbData({
      navigation: [
        {
          text: this.$t('Navigation'),
          active: true,
        },
      ],
    });
  },
};
</script>
