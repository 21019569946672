export default {
  path: 'participants',
  component: {
    render(c) {
      return c('router-view');
    },
  },

  children: [
    {
      path: '',
      name: 'participants',
      component: () =>
        import(
          /* webpackChunkName: "participants" */ '../views/Participants/List/ParticipantListContainerView.vue'
        ),
    },
    {
      path: 'search',
      name: 'participants-search',
      component: () =>
        import(
          /* webpackChunkName: "participants" */ '../views/Participants/List/ParticipantListContainerView.vue'
        ),
    },
    {
      path: 'add',
      name: 'add-participant',
      component: () =>
        import(
          /* webpackChunkName: "createParticipant" */ '../views/Participants/Create.vue'
        ),
    },
    {
      path: ':id/basic-information',
      name: 'participant-update',
      component: () =>
        import(
          /* webpackChunkName: "createParticipant" */ '../views/Participants/Update.vue'
        ),
    },
    {
      path: ':id/change-consent',
      name: 'change-consent',
      component: () =>
        import(
          /* webpackChunkName: "TailorSchedule" */ '../views/Participants/ChangeConsent/Index.vue'
        ),
    },
    {
      path: ':id/tailor-schedule',
      name: 'tailor-schedule',
      component: () =>
        import(
          /* webpackChunkName: "TailorSchedule" */ '../views/Participants/TailorSchedule/Index.vue'
        ),
    },
    {
      path: 'participant/changes-history',
      name: 'changes-history',
      component: () =>
        import(
          /* webpackChunkName: "changes-history" */ '../views/ParticipantChanges/Index.vue'
        ),
    },
    {
      path: ':id',
      name: 'view-participant',
      component: () =>
        import(
          /* webpackChunkName: "viewParticipant" */ '../views/Participants/View.vue'
        ),
    },
    {
      path: 'relapses/:id',
      name: 'view-relapse',
      component: () =>
        import(
          /* webpackChunkName: "viewRelapse" */ '../views/Relapses/RelapseDetailView.vue'
        ),
    },

    {
      path: 'create-relapse-schedule',
      name: 'create-relapse-schedule',
      component: () =>
        import(
          /* webpackChunkName: "create-relapse-schedule" */ '../views/Relapses/CreateRelapseView.vue'
        ),
    },
  ],
};
