import request from '@/utils/api.utils';

/**
 * Get: fetch participant change records
 */
export function fetchParticipantChangeRecords(params) {
  const url = `/changes-history/participants`;
  return request.get(url, { params });
}

/**
 * PUT: update a participant
 */
export function sendConsent({
  clientId,
  studyId,
  protocolId,
  siteId,
  id,
  payload,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/consent`;
  return request.put(url, payload);
}
