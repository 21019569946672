<template>
  <div>
    <wizard-header
      title="Update Study Protocol"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <div class="p-4">
        <step-wizard :currentStep="step" wizardType="protocol"></step-wizard>
      </div>
    </div>

    <div class="bg-white p-5">
      <basic-information
        v-show="step === 0"
        @nextStep="handleNextClick"
        :basic="protocol.basic"
      />
      <protocolApprover
        v-show="step === 1"
        :delete="deletedProtocolApprover"
        @nextStep="handleApproversNextClick"
        @back="step--"
      />
      <schedule v-show="step === 2" @nextStep="step++" @back="step--" />
      <external
        v-show="step === 3"
        @back="step--"
        @nextStep="handleNextClick"
      />
      <overview
        :protocol="protocol"
        v-if="step === 4"
        :submitting="submitting"
        @back="step--"
        @updateProtocol="updateProtocol"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Update Study Protocol"
      title-tag="h3"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_update_protocol') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name:
                $route.name === 'edit-protocol'
                  ? 'view-protocol'
                  : `${$route.meta.parent}-view-protocol`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  updateProtocol,
  updateApprover,
  updateProtocolIntegrations,
  createApprover,
} from '@/api/protocol';
import { createExpert, updateExpert } from '@/api/experts';

import BasicInformation from '@/components/Protocol/BasicInformation';
import ProtocolApprover from '@/components/Protocol/ProtocolApprover.vue';
import Schedule from '@/components/Protocol/Schedule';
import External from '@/components/Protocol/External';
import Overview from '@/components/Protocol/Overview';
import WizardHeader from '@/components/WizardHeader.vue';

import { reduceKeys, hasKeysLength } from '@/utils';
import { mapState } from 'vuex';

export default {
  components: {
    BasicInformation,
    Schedule,
    Overview,
    ProtocolApprover,
    External,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      submitting: false,
      step: 0,
      deletedProtocolApprover: [],
      protocol: {
        basic: {
          name: '',
          description: '',
          studyDurationPerParticipant: '',
          plannedNumberOfParticipants: '',
        },
        approver: [],
        tests: {},
      },
    };
  },
  computed: {
    ...mapState({
      study: (state) => state.studies.selectedStudy,
      client: (state) => state.clients.selectedClient,
      selectedProtocol: (state) => state.protocols.selectedProtocol,
      protocolForEdit: (state) => state.protocols.protocolForEdit,
      protocolParams: (state) => state.protocols.protocolParams,
    }),
  },
  created() {
    this.setBreadCrumbData({
      'client-edit-protocol': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.client.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.$t('protocol_update'),
          active: true,
        },
      ],
      'study-edit-protocol': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.$t('protocol_update'),
          active: true,
        },
      ],
    });
  },
  mounted() {
    this.protocol = JSON.parse(JSON.stringify(this.protocolForEdit));
  },
  methods: {
    async updateProtocol() {
      this.submitting = true;

      try {
        await this.updateProtocolApprover();
        await this.updateProtocolBasic();
        await this.updateProtocolIntegrations();

        this.$toast.info(this.$t('toast_protocol_updated'));

        this.$router.push({
          name:
            this.$route.name === 'edit-protocol'
              ? 'protocols'
              : `${this.$route.meta.parent}-protocols`,
        });
      } catch (error) {
        this.displayError('Error update protocol', error);
      } finally {
        this.submitting = false;
      }
    },
    async updateProtocolApprover() {
      if (this.protocol.approver?.length > 0) {
        const approver = this.protocol.approver[0];
        const { roles, ...user } = approver;
        const { clientId, studyId, protocolId } = this.protocolParams;
        const protocolHasApprover =
          this.protocolForEdit?.approver !== undefined &&
          Object.keys(this.protocolForEdit?.approver).length > 0;
        let expertId;

        if (approver?.type === 'new' || !protocolHasApprover) {
          const { data } = await createExpert({
            ...user,
            clientId,
          });

          expertId = data.id;

          if (expertId) {
            const payload = {
              expertId,
              roles: roles.map((id) => ({ id })),
              finishUpdateProcess: false,
            };

            await createApprover(clientId, studyId, protocolId, payload);
          }
        } else if (user.expertId) {
          expertId = user.expertId;

          if (approver?.type === 'existing' || approver?.fieldsChanged) {
            await updateExpert(expertId, user);
          }

          if (approver?.type === 'existing' || approver?.rolesChanged) {
            const payload = {
              expertId,
              roles: roles.map((id) => ({ id })),
              finishUpdateProcess: false,
            };

            await updateApprover(this.protocolParams, payload);
          }
        }

        // bubble up the error and abort whole protocol update
        if (!expertId) {
          console.error('error update approver, no expert id');
          throw new Error();
        }
      }
    },
    async updateProtocolBasic() {
      const basic = reduceKeys(this.protocolForEdit.basic, {
        ...this.protocol.basic,
        ...(this.protocol.basic.description &&
          !this.protocol.basic.description.trim() && { description: null }),
      });

      if (hasKeysLength(basic)) {
        await updateProtocol(this.protocolParams, {
          ...this.protocol.basic,
          finishUpdateProcess: false,
        });
      }
    },
    async updateProtocolIntegrations() {
      let dataSources = [];
      let integrations = ['googleFit', 'appleHealth', 'fitbit'];

      integrations.forEach((integration, index) => {
        if (this.protocol[integration]) dataSources.push({ id: index + 1 });
      });

      await updateProtocolIntegrations(this.protocolParams, {
        dataSources,
        finishUpdateProcess: true,
      });
    },
    handleNextClick(payload) {
      if (payload) {
        this.protocol = { ...this.protocol, ...payload };
      }
      this.step++;
    },
    handleApproversNextClick(approver) {
      this.protocol.approver = approver;
      this.step++;
    },
    handleTestGamesNextClick(weekLength, games) {
      this.protocol.tests = {
        weekLength,
        games,
      };
      this.step++;
    },
    handleSurveysNextClick(surveys) {
      this.protocol.tests.surveys = [...surveys];
      this.step++;
    },
  },
};
</script>

<style></style>
