<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.59529 1.77284C9.84697 1.63301 10.153 1.63301 10.4047 1.77284L17.9047 5.9395C18.1692 6.08648 18.3333 6.36533 18.3333 6.66797C18.3333 6.97061 18.1692 7.24946 17.9047 7.39643L10.4047 11.5631C10.153 11.7029 9.84697 11.7029 9.59529 11.5631L2.09529 7.39643C1.83073 7.24946 1.66666 6.97061 1.66666 6.66797C1.66666 6.36533 1.83073 6.08648 2.09529 5.9395L9.59529 1.77284ZM4.21593 6.66797L9.99999 9.88134L15.784 6.66797L9.99999 3.4546L4.21593 6.66797Z"
      fill="#C5D8E4"
    />
    <path
      d="M1.77152 9.5966C1.99504 9.19428 2.50237 9.04933 2.90469 9.27284L9.99999 13.2147L17.0953 9.27284C17.4976 9.04933 18.0049 9.19428 18.2285 9.5966C18.452 9.99892 18.307 10.5063 17.9047 10.7298L10.4047 14.8964C10.153 15.0363 9.84697 15.0363 9.59529 14.8964L2.09529 10.7298C1.69297 10.5063 1.54801 9.99892 1.77152 9.5966Z"
      fill="#C5D8E4"
    />
    <path
      d="M1.77152 12.9299C1.99504 12.5276 2.50237 12.3827 2.90469 12.6062L9.99999 16.548L17.0953 12.6062C17.4976 12.3827 18.0049 12.5276 18.2285 12.9299C18.452 13.3323 18.307 13.8396 17.9047 14.0631L10.4047 18.2298C10.153 18.3696 9.84697 18.3696 9.59529 18.2298L2.09529 14.0631C1.69297 13.8396 1.54801 13.3323 1.77152 12.9299Z"
      fill="#C5D8E4"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
