import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

/**
 * POST: submit a new study against a client
 */
export function createStudy(clientId, payload) {
  const url = `/clients/${clientId}/studies`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, payload, { headers });
}

export function fetchStudiesV2(params) {
  const url = '/studies';
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { params, headers });
}

/**
 * GET: fetch the list of all studies against a client
 */
export function fetchClientStudies(params) {
  const url = `/studies?sort=-created_at`;
  return request.get(url, { params });
}

/**
 * GET: fetch the list of all studies
 */
export function fetchAllStudies() {
  const url = `/studies`;
  return request.get(url);
}

/**
 * POST: Create new coordinator against a study
 */
export function createCoordinator(clientId, studyId, params) {
  const url = `/clients/${clientId}/studies/${studyId}/coordinators`;
  return request.post(url, params);
}

/**
 * POST: add features against a study
 */
export function postFeatures(clientId, studyId, params) {
  const url = `/clients/${clientId}/studies/${studyId}/features-configuration`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, params, { headers });
}

/**
 * GET: fetch a study detail
 */
export function getStudyDetails({ clientId, studyId }) {
  const url = `/clients/${clientId}/studies/${studyId}`;
  return request.get(url);
}

/**
 * GET: fetch the list of study coordinators
 */
export function getStudyCoordinators({ clientId, studyId }) {
  const url = `/clients/${clientId}/studies/${studyId}/coordinators`;
  return request.get(url);
}

/**
 * PUT: Update Study (name, description)
 */
export function updateStudy(clientId, studyId, params) {
  const url = `/clients/${clientId}/studies/${studyId}`;
  return request.put(url, params);
}
/**
 * PUT: update study features
 */
export function updateFeatures(clientId, studyId, params) {
  const url = `/clients/${clientId}/studies/${studyId}/features-configuration`;
  const headers = { apiVersion: apiVersion.two };
  return request.put(url, params, { headers });
}

/**
 * DELETE: delete study coordinator
 */
export function deleteStudyCoordinator(clientId, studyId, coordinatorId) {
  const url = `/clients/${clientId}/studies/${studyId}/coordinators/${coordinatorId}`;
  return request.delete(url);
}

/**
 * PUT: update study coordinator
 */
export function updateStudyCoordinator({
  clientId,
  studyId,
  coordinatorId,
  payload,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/coordinators/${coordinatorId}`;
  return request.put(url, payload);
}

/**
 * GET: Retrieve a Study Coordinator details
 */
export function fetchStudyCoordinatorDetails({
  clientId,
  studyId,
  coordinatorId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/coordinators/${coordinatorId}`;
  return request.get(url);
}

/**
 * POST: Send test id's
 */
export function postStudyTests(clientId, studyId, tests) {
  const requestData = { testIds: tests };
  const url = `/clients/${clientId}/studies/${studyId}/tests`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, requestData, { headers });
}

/**
 * PUT: Send test id's to update
 */
export function putStudyTests(clientId, studyId, tests) {
  const requestData = { testIds: tests };
  const url = `/clients/${clientId}/studies/${studyId}/tests`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, requestData, { headers });
}


/**
 * GET: Get the data of first study week
 */

export function getFirstStudyWeekDate(clientId, studyId, protocolId, siteId) {
  let url = '/participants/first';
  const params = [];
  if (clientId !== null) {
    params.push(`clientId=${clientId}`);
  }
  if (studyId !== null) {
    params.push(`studyId=${studyId}`);
  }
  if (protocolId !== null) {
    params.push(`protocolId=${protocolId}`);
  }
  if (siteId !== null) {
    params.push(`siteId=${siteId}`);
  }
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { headers });
}
