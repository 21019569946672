export default {
  // We transform response in objects array to use in step 2
  methods: {
    refactor_partner_category_test_list(participantTask, excludedGames) {
      let prepareArrayTasks = [];
      if (participantTask.length) {
        // first loop
        for (let index = 0; index < participantTask.length; index++) {
          let partner = {
            name: participantTask[index].name,
            id: participantTask[index].id,
            highlighted: false,
            categories: {},
          };
          prepareArrayTasks.push(partner);
          // second loop
          for (
            let categoryIndex = 0;
            categoryIndex < participantTask[index].categories.length;
            categoryIndex++
          ) {
            partner.categories[categoryIndex] = {
              name: participantTask[index].categories[categoryIndex].name,
              id: participantTask[index].categories[categoryIndex].id,
              marked: false,
              tests: {},
            };
            // 3 loop
            for (
              let testIndex = 0;
              testIndex <
              participantTask[index].categories[categoryIndex].tests.length;
              testIndex++
            ) {
              partner.categories[categoryIndex].tests[testIndex] = {
                name: participantTask[index].categories[categoryIndex].tests[
                  testIndex
                ].name,
                id: participantTask[index].categories[categoryIndex].tests[
                  testIndex
                ].id,
                versions: {},
              };
              //4 loop
              for (
                let versionIndex = 0;
                versionIndex <
                participantTask[index].categories[categoryIndex].tests[
                  testIndex
                ].versions.length;
                versionIndex++
              ) {
                let versionSelected =
                  participantTask[index].categories[categoryIndex].tests[
                    testIndex
                  ].versions[versionIndex].id;
                //5 loop

                partner.categories[categoryIndex].tests[testIndex].versions = {
                  id: participantTask[index].categories[categoryIndex].tests[
                    testIndex
                  ].versions[versionIndex].id,
                  testId:
                    participantTask[index].categories[categoryIndex].tests[
                      testIndex
                    ].versions[versionIndex].testId,
                  disabled: excludedGames.includes(versionSelected),
                  enabled: !excludedGames.includes(versionSelected),
                  version:
                    participantTask[index].categories[categoryIndex].tests[
                      testIndex
                    ].versions[versionIndex].version,
                };

                ////////
              }
              //end 3 loop
            }
            //end second loop
          }

          //end first loop
        }

        // end of if
      }
      this.$store
        .dispatch('participants/saveTaskList', prepareArrayTasks)
        .then((resp) => {
          if (resp === 'ok') {
            this.loadCollapse = true;
          }
        });
    },
  },
};
