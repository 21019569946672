<template>
  <div>
    <participant-adherence-average-chart
      :adherence-data="sections"
      :total-num="total"
      :percentage="calculateCompletedPercentage"
    ></participant-adherence-average-chart>
  </div>
</template>

<script>
import ParticipantAdherenceAverageChart from '@/components/Reports/ParticipantAdherenceAverageChart.vue';
export default {
  name: 'ParticipantAdherenceAverageContainer',

  components: {
    ParticipantAdherenceAverageChart,
  },

  props: {
    statisticsData: {
      type: Array,
    },
  },

  data() {
    return {
      sections: [],
      total: 0,
    };
  },

  created() {
    this.calculateSections();
    this.calculateTotal();
  },

  computed: {
    calculateCompletedPercentage() {
      const completedSection = this.sections.find(
        (section) => section.label === 'completed'
      );
      const totalScheduledCompletedNotCompleted = this.sections.reduce(
        (sum, section) => sum + section.value,
        0
      );
      const completedPercentage =
        (completedSection.value / totalScheduledCompletedNotCompleted) * 100;
      return (completedSection.percentage = completedPercentage.toFixed(2));
    },
  },

  methods: {
    calculateSections() {
      this.sections = [
        {
          label: 'scheduled',
          value: this.calculateSum('total_scheduled'),
          color: '#98A9BC',
        },
        {
          label: 'completed',
          value: this.calculateSum('total_completed'),
          color: '#4FC14D',
        },
        {
          label: 'not_completed',
          value: this.calculateSum('total_not_completed'),
          color: '#FF4B55',
        },
      ];
    },
    calculateSum(field) {
      return this.statisticsData.reduce((sum, item) => sum + item[field], 0);
    },
    calculateTotal() {
      this.total = this.sections.reduce(
        (sum, section) => sum + section.value,
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
