<template>
  <div class="mt-4">
    <create-team-member
      @refreshList="getTeamMemberList"
      v-if="canCreateMember"
    />
    <b-spinner v-if="firstLoading" class="mx-auto d-block" />
    <div v-else>
      <team-members-table
        :teamMembersListTableData="members"
        :orderBy="orderBy"
        :isLoading="loading"
        :isSiteView="isSiteView"
        :class="{ 'loading-table': loading }"
        @deleteTeamMember="deleteTeamMember"
        @getTeamMembers="getTeamMemberList"
      />
      <PaginatorTableComponent
        :current-page="pagination.currentPage"
        :total-rows="pagination.totalCount"
        :page-size="pagination.pageSize"
        @changePageEvent="handleChangePageEvent"
      />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import CreateTeamMember from '@/views/TeamMember/Create';
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import TeamMembersTable from '@/components/Tables/TeamMembersTable.vue';
import { mapActions, mapState } from 'vuex';
import { paginationDefaults } from '@/constants/pagination-constants';

export default {
  name: 'TeamMemberList',
  components: { PaginatorTableComponent, TeamMembersTable, CreateTeamMember },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      members: ({ members }) => members.list,
      storePagination: ({ members }) => members.pagination,
      protocol: ({ protocols }) => protocols.selectedProtocol,
    }),
    canCreateMember() {
      return this.canCreateSite(this.protocol.id);
    },
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('team members');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
      isSiteView: this.$route.name.includes('site-list-team'),
    };
  },
  created() {
    this.getTeamMemberList();
  },
  methods: {
    ...mapActions({
      fetchListData: 'members/fetchTeamMembers',
    }),
    getTeamMemberList() {
      this.getListData('team members', true);
    },
    deleteTeamMember(member) {
      this.$emit('deleteTeamMember', member);
    },
  },
};
</script>
