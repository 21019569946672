import {
  fetchSites,
  fetchSite,
  getSiteContact,
  fetchSiteStatistics,
  fetchSitesV2,
} from '@/api/site';
import { getProtocolDetail, getProtocolApprover } from '@/api/protocol';

const initialState = {
  all: [],
  list: [],
  pagination: {},
  selectedSite: {},
  siteProtocol: {},
  siteParams: {},
  siteData: {},
  siteStatistics: {},
  siteContact: {},
  isProtocolView: false,
  showSiteButtons: false,
};
const state = { ...initialState };

const getters = {
  extractSites({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.siteId)?.length;
      !exists &&
        acc.push({
          title: curr.site.name,
          value: curr.site.id,
          clientId: curr.clientId,
          clientName: curr.clientName,
          studyId: curr.studyId,
          studyName: curr.studyName,
          protocolId: curr.protocolId,
          protocolName: curr.protocolName,
        });
      return acc;
    }, []);
  },
  extractClients({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.clientId)?.length;
      !exists && acc.push({ title: curr.clientName, value: curr.clientId });
      return acc;
    }, []);
  },

  extractStudies({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter((item) => item.value === curr.studyId)?.length;
      !exists &&
        acc.push({
          title: curr.studyName,
          value: curr.studyId,
          clientId: curr.clientId,
        });
      return acc;
    }, []);
  },
  extractProtocols({ all }) {
    return all.reduce((acc, curr) => {
      const exists = acc.filter(
        (item) => item.value === curr.protocolId
      )?.length;
      !exists &&
        acc.push({
          title: curr.protocolName,
          value: curr.protocolId,
          clientId: curr.clientId,
          studyId: curr.studyId,
        });
      return acc;
    }, []);
  },
};

const mutations = {
  SET_ALL_SITES: (state, sites) => {
    state.all = sites;
  },
  SET_LIST_SITES: (state, sites) => {
    state.list = sites;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  SET_SELECTED_SITE: (state, site) => {
    delete site.basic.status;
    state.selectedSite = site;
  },
  SET_SITE_PROTOCOL: (state, protocol) => {
    state.siteProtocol = protocol;
  },
  SET_SITE_PARAMS: (state, params) => {
    state.siteParams = params;
  },
  SET_PROTOCOL_VIEW: (state, isProtocolView) => {
    state.isProtocolView = isProtocolView;
  },
  SET_SITE_DATA: (state, params) => {
    state.siteData = params;
  },
  SET_SITE_STATISTICS: (state, params) => {
    state.siteStatistics = params;
  },
  SET_SITE_CONTACT: (state, params) => {
    state.siteContact = params;
  },
  SHOW_SITE_BUTTONS: (state, params) => {
    state.showSiteButtons = params;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async fetchSites({ commit }, payload) {
    try {
      const { data, pagination } = await fetchSitesV2(payload);
      commit('SET_LIST_SITES', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_LIST_SITES', []);
      commit('SET_PAGINATION', {});
      console.error(error);
      return error;
    }
  },
  async dispatchFetchSites({ commit }, payload) {
    try {
      const { data } = await fetchSites(payload);
      commit('SET_LIST_SITES', data);
    } catch (error) {
      commit('SET_LIST_SITES', []);
      console.log(error);
      return error;
    }
  },
  async dispatchFetchAllSites({ commit }) {
    try {
      const { data } = await fetchSites();
      commit('SET_ALL_SITES', data);
    } catch (error) {
      commit('SET_ALL_SITES', []);
      console.error(error);
      return error;
    }
  },
  async GET_SITE_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      fetchSite(payload).then(
        (resp) => {
          const data = resp.data;
          commit('SET_SITE_DATA', data);
          return resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async getSiteStatistics({ commit }, payload) {
    try {
      const { data } = await fetchSiteStatistics(payload);
      commit('SET_SITE_STATISTICS', data);
    } catch (error) {
      commit('SET_SITE_STATISTICS', {});
    }
  },
  async getProtocolDetails({ commit }, payload) {
    try {
      const { data: approver } = await getProtocolApprover(payload);
      const { data } = await getProtocolDetail(payload);
      commit('SET_SITE_PROTOCOL', { ...data, approver });
      commit('SHOW_SITE_BUTTONS', true);
    } catch (error) {
      commit('SHOW_SITE_BUTTONS', false);
      return error;
    }
  },
  async GET_SITE_CONTACT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getSiteContact(payload).then(
        (resp) => {
          const data = resp.data;
          commit('SET_SITE_CONTACT', data);
          return resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  setSiteParams({ commit }, params) {
    commit('SET_SITE_PARAMS', params);
  },
  setIsProtocolView({ commit }, isProtocolView) {
    commit('SET_PROTOCOL_VIEW', isProtocolView);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
