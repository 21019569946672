<template>
  <div class="card-container">
    <p class="mb-4">{{ $t(cardTitle) }}</p>
    <div class="d-flex flex-wrap">
      <div class="item" v-for="item in items" :key="item.name">
        <span class="opacity-6 card-label">{{ $t(item.label) }}</span>
        <p class="avenir-bold" v-if="item.label === 'gender'">
          {{ $t(item.value.name) }}
        </p>
        <p class="avenir-bold" v-if="item.label === 'type'">
          {{
            item.value[0] === 'healthy_control'
              ? $t('healthy_control')
              : $t('patient')
          }}
        </p>
        <p class="avenir-bold" v-if="item.label === 'height'">
          {{ item.value }} {{ $t('unit_cm') }}
        </p>
        <p class="avenir-bold" v-if="item.label === 'weight'">
          {{ item.value }} {{ $t('unit_kg') }}
        </p>
        <p class="avenir-bold" v-if="item.label != 'gender'">
          <span v-if="item.label != 'type'">
            <span v-if="item.label != 'height'">
              <span v-if="item.label != 'weight'">
                {{ item.value }}
              </span></span
            ></span
          >
        </p>
      </div>
      <div class="w-100 mt-4">
        <span class="opacity-6 card-label">{{ $t('icf_signed') }}</span>
        <p class="avenir-bold">
          {{ $t('yes') }}
        </p>
      </div>
      <div class="w-100 mt-4">
        <label
          :class="changeConsent ? 'avenir' : 'opacity-6'"
          class="card-label"
          >{{ $t('devices_third_party') }}</label
        >
        <div class="avenir-bold" v-for="partner in partners" :key="partner.Id">
          <div v-if="changeConsent" class="change-consent"></div>
          <div v-else>
            <i class="ni ni-check-bold text-primary mr-2"></i
            >{{ partner.partnerName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
          
<script>
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';

export default {
  mixins: [cardStudyInfoMixin],
  components: {},
  props: {
    cardTitle: {
      type: String,
    },
    selectedObject: {
      type: Object,
    },
    keysList: {
      type: Array,
    },
    keyNames: {
      type: Object,
    },
    keyOrder: {
      type: Object,
    },
    partners: {
      type: Array,
    },
    changeConsent: {
      type: Boolean,
    },
    enabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
      enableCheck: false,
    };
  },
  watch: {
    enableCheck() {},
  },

  computed: {},

  methods: {
    Object() {},
  },

  created() {
    this.builtObject(
      this.selectedObject,
      this.keysList,
      this.keyNames,
      this.keyOrder
    );
  },
};
</script>
         
    <style>
.item {
  width: 33%;
}
.card-container {
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.editing {
  color: #3331e0;
}
</style>
          