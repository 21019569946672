<template>
  <div>
    <base-dropdown
      class="pr-0"
      position="right"
      menuClasses="shadow border rounded-pill search-container"
      :keepOpen="keepOpen"
    >
      <div slot="title">
        <template>
          <i class="fas fa-search" @click="keepOpen = true"></i>
        </template>
      </div>

      <template class="rounded-pill">
        <b-row class="px-4">
          <b-col cols="8">
            <b-form-input
              v-model="searchText"
              :placeholder="$t('search_participants')"
              maxLength="20"
              data-testid="siteName-input"
              @keyup.enter="handleSearch"
            ></b-form-input>
          </b-col>

          <b-col cols="4 pr-0">
            <base-button
              type="primary"
              rounded
              @click="handleSearch"
              :disabled="!isValidSearchText"
              >{{ $t('search') }} >
            </base-button>
          </b-col>
        </b-row>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: null,
      keepOpen: true,
    };
  },
  computed: {
    isValidSearchText() {
      return this.searchText && this.searchText.trim() !== '';
    },
  },
  methods: {
    handleSearch() {
      this.keepOpen = false;
      this.$router.push({
        name: 'participants-search',
        query: { s: this.searchText.toLowerCase() },
      });
      this.searchText = null;
    },
  },
};
</script>

<style lang="scss">
.search-container {
  min-width: 400px !important;
}
</style>
