<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4869 9.8604L10.94 2.31743L10.4342 1.81157C10.3189 1.69707 10.163 1.63281 10.0006 1.63281C9.8381 1.63281 9.68223 1.69707 9.56697 1.81157L1.51424 9.8604C1.39614 9.97804 1.3028 10.1182 1.23973 10.2725C1.17667 10.4268 1.14516 10.5921 1.14705 10.7588C1.15487 11.4463 1.72713 11.9952 2.41463 11.9952H3.24471V18.3565H16.7564V11.9952H17.6041C17.9381 11.9952 18.2525 11.8643 18.4888 11.628C18.6052 11.512 18.6974 11.3741 18.7601 11.2222C18.8228 11.0703 18.8547 10.9075 18.8541 10.7432C18.8541 10.4112 18.7232 10.0967 18.4869 9.8604ZM11.0943 16.9502H8.90682V12.9659H11.0943V16.9502ZM15.3502 10.5889V16.9502H12.3443V12.4971C12.3443 12.0655 11.9947 11.7159 11.5631 11.7159H8.43807C8.00643 11.7159 7.65682 12.0655 7.65682 12.4971V16.9502H4.65096V10.5889H2.77596L10.0025 3.36821L10.4537 3.81938L17.2271 10.5889H15.3502Z"
      fill="#C5D8E4"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
