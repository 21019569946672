import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '@/layout/DashboardLayout';
import clientsRoutes from './clients';
import studiesRoutes from './studies';
import protocolsRoutes from './protocols';
import sitesRoutes from './sites';
import userRoutes from './users';
import reports from './reports';
import authRoutes from './auth';
import participants from './participants';
import navigation from './navigation';
import logs from './logs';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

export const constantRoutes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Dashboard/DashboardView.vue'
          ),
      },
      clientsRoutes,
      studiesRoutes,
      protocolsRoutes,
      sitesRoutes,
      logs,

      {
        path: 'change-password',
        name: 'change-password',
        component: () =>
          import(
            /* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue'
          ),
      },

      userRoutes,
      participants,
      navigation,
      reports,
    ],
  },
  authRoutes,
];

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    linkExactActiveClass: 'active',
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  router.go();
}

export default router;
