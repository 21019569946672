export default {
  clientRead: 'CLIENT_READ',
  clientWrite: 'CLIENT_WRITE',
  studyRead: 'STUDY_READ',
  studyWrite: 'STUDY_WRITE',
  protocolRead: 'PROTOCOL_READ',
  protocolWrite: 'PROTOCOL_WRITE',
  approve: 'PROTOCOL_APPROVE',
  siteRead: 'SITE_READ',
  siteWrite: 'SITE_WRITE',
  participantRead: 'SITE_PARTICIPANT_READ',
  participantWrite: 'SITE_PARTICIPANT_WRITE',
  roleRead: 'ROLE_STANDARD_READ',
  roleWrite: 'ROLE_STANDARD_WRITE',
  navigatorRead: 'NAVIGATOR_READ',
  navigatorWrite: 'NAVIGATOR_WRITE',
  manageUsers: 'MANAGE_USERS',
};

export const superAdmin = [
  'SITE_PARTICIPANT_WRITE',
  'USER_HEALIOS_READ',
  'SITE_PARTICIPANT_READ',
  'SITE_READ',
  'USER_HEALIOS_WRITE',
  'ROLE_HEALIOS_READ',
  'ROLE_HEALIOS_WRITE',
  'CLIENT_READ',
  'SITE_WRITE',
  'CLIENT_WRITE',
  'ROLE_STANDARD_READ',
  'ROLE_STANDARD_WRITE',
  'PROTOCOL_READ',
  'STUDY_READ',
  'STUDY_WRITE',
  'PROTOCOL_WRITE',
  'PROTOCOL_APPROVE',
];

export const programManager = [
  'SITE_WRITE',
  'SITE_PARTICIPANT_WRITE',
  'SITE_READ',
  'ROLE_STANDARD_READ',
  'ROLE_STANDARD_WRITE',
  'SITE_PARTICIPANT_READ',
  'CLIENT_READ',
  'STUDY_READ',
  'PROTOCOL_READ',
  'USER_HEALIOS_READ',
  'STUDY_WRITE',
  'ROLE_HEALIOS_READ',
  'CLIENT_WRITE',
  'PROTOCOL_WRITE',
];
