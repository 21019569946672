<template>
  <div>
    <h2>{{ $t('protocol_schedule') }}</h2>
    <b-form-checkbox v-model="schedule">{{
      $t('protocol_schedule_confirmation')
    }}</b-form-checkbox>
    <hr />
    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="schedule && $emit('nextStep')"
          :disabled="!schedule"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return { schedule: false };
  },
};
</script>

<style></style>
