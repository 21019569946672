<template>
  <div class="p-5">
    <ListFiltersComponent
      v-if="showListFilters"
      :filtersToDisplay="filtersToDisplay"
      @changeFilterEvent="handleChangeFilterEvent"
    />
    <StudyList
      :filterData="filterStudyData"
      :displayClientNameColumn="true"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import StudyList from '@/components/Study/List/StudyList.vue';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import { mapActions, mapState } from 'vuex';
import isClientUserMixin from '@/mixins/isClientUserMixin';

export default {
  name: 'StudyListContainerView',
  components: { ListFiltersComponent, StudyList },
  mixins: [isClientUserMixin],
  data() {
    return {
      filterStudyData: {},
      filtersToDisplay: {
        client: false,
        study: false,
        protocol: false,
        site: false,
      },
      showListFilters: false,
    };
  },
  created() {
    this.getResources();
    this.setBreadCrumbData({
      studies: [
        {
          text: this.$t('studies'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      userType: ({ auth }) => auth.userType,
    }),
  },
  methods: {
    ...mapActions({
      fetchResources: 'resources/dispatchFetchResources',
    }),
    async getResources() {
      try {
        await this.fetchResources();
      } catch (error) {
        console.error(error);
      }
    },
    handleChangeFilterEvent(newFilter) {
      this.filterStudyData = newFilter;
    },
    goToDetail() {
      this.$router.push({ name: 'study-details' });
    },
  },
};
</script>
