import { fetchClientsV2 } from '@/api/client';
import { fetchStudiesV2 } from '@/api/study';
import { fetchProtocolsV2 } from '@/api/protocol';
import { fetchSitesV2 } from '@/api/site';
import { paginationDefaults } from '@/constants/pagination-constants';

const state = {
  clients: [],
  studies: [],
  protocols: [],
  sites: [],
};

const mutations = {
  SET_ALL_CLIENTS: (state, data) => {
    state.clients = data;
  },
  SET_ALL_STUDIES: (state, data) => {
    state.studies = data;
  },
  SET_ALL_PROTOCOLS: (state, data) => {
    state.protocols = data;
  },
  SET_ALL_SITES: (state, data) => {
    state.sites = data;
  },
};

const actions = {
  async fetchClients({ commit }) {
    try {
      const { data } = await fetchClientsV2({
        sort: '-created_at',
        pageSize: paginationDefaults.allItemsInPage,
      });
      // Temporary sorting as it's not possible from Cloud
      const sortByName = (a, b) => {
        if (a.legalName < b.legalName) return -1;
        if (a.legalName > b.legalName) return 1;
        return 0;
      };
      commit('SET_ALL_CLIENTS', data.sort(sortByName));
    } catch (error) {
      commit('SET_ALL_CLIENTS', []);
      return error;
    }
  },
  async fetchStudies({ commit }) {
    try {
      const { data } = await fetchStudiesV2({
        sort: 'name',
        pageSize: paginationDefaults.allItemsInPage,
      });
      commit('SET_ALL_STUDIES', data);
    } catch (error) {
      commit('SET_ALL_STUDIES', []);
      return error;
    }
  },
  async fetchProtocols({ commit }) {
    try {
      const { data } = await fetchProtocolsV2({
        sort: 'name',
        pageSize: paginationDefaults.allItemsInPage,
      });
      commit('SET_ALL_PROTOCOLS', data);
    } catch (error) {
      commit('SET_ALL_PROTOCOLS', []);
      return error;
    }
  },
  async fetchSites({ commit }) {
    try {
      const { data } = await fetchSitesV2({
        sort: 'name',
        pageSize: paginationDefaults.allItemsInPage,
      });
      commit('SET_ALL_SITES', data);
    } catch (error) {
      commit('SET_ALL_SITES', []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
