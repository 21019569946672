import { paginationDefaults } from '@/constants/pagination-constants';
import { camelToSnakeCase } from '@/utils/stringTransform';

export default {
  methods: {
    async getListData(resourceName = 'list', isV1Endpoint = false) {
      try {
        this.loading = true;
        const payload = this.getPayload(
          this.pagination,
          this.orderBy,
          this.filterData,
          isV1Endpoint
        );
        await this.fetchListData(payload);
        this.pagination = { ...this.storePagination };
      } catch (error) {
        this.displayError(`Get ${resourceName} data error`, error);
      } finally {
        this.firstLoading = false;
        this.loading = false;
      }
    },
    handleChangePageEvent(pageIndex) {
      this.pagination.page = pageIndex;
      this.getListData().then();
    },
    handleSortingEvent(column, ascending) {
      this.resetPage();
      this.orderBy = { column, ascending };
      this.getListData().then();
    },
    resetPage() {
      this.pagination.page = paginationDefaults.firstPageIndex;
    },
    getPayload(pagination, orderBy, filterData = {}, isV1Endpoint = false) {
      const { page, pageSize } = pagination;
      let filters = {};

      Object.keys(filterData).forEach((key) => {
        if (filterData[key] !== '') {
          const formattedKey = isV1Endpoint
            ? `filter[${camelToSnakeCase(key)}]`
            : key;
          filters[formattedKey] = filterData[key];
        }
      });

      return {
        page,
        pageSize,
        sort: this.getSortParam(orderBy),
        ...filters,
      };
    },
    getSortParam(orderBy) {
      const { column, ascending } = orderBy;
      const sortParam = camelToSnakeCase(column);
      return ascending ? sortParam : `-${sortParam}`;
    },
  },
};
