<template>
  <li class="nav-item">
    <router-link
      v-if="!link.hasChild"
      :to="link.path"
      @click.native="linkClick"
      class="nav-link"
      :target="link.target"
      :href="link.path"
      :id="link.id"
      :class="{
        'link-active': link.linkActive,
        'fill-icon': link.iconType === 'fill',
      }"
    >
      <template>
        <slot name="icon"></slot>
        <span class="nav-link-text ml-3">{{ link.name }}</span>
      </template>
    </router-link>
    <template v-else>
      <b-button
        v-b-toggle="`collapse-${ link.id }`"
        variant="primary"
        class="nav-link-button w-100 pl-4"
        :class="{
          'link-active': link.linkActive,
          'fill-icon': link.iconType === 'fill',
        }"
      >
        <slot name="icon"></slot>
        <span class="nav-link-text ml-3">{{ link.name }} </span>

        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          class="arrow-icon arrow-down"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="arrow_forward_ios_24px">
            <path
              id="icon/navigation/arrow_forward_ios_24px"
              d="M1.99086 4.0823C1.66419 4.36813 1.66419 4.82896 1.99086 5.1148L7.53086 9.9623C7.79086 10.1898 8.21086 10.1898 8.47086 9.9623L14.0109 5.1148C14.3375 4.82896 14.3375 4.36813 14.0109 4.0823C13.6842 3.79646 13.1575 3.79646 12.8309 4.0823L7.99753 8.30563L3.16419 4.07646C2.84419 3.79646 2.31086 3.79646 1.99086 4.0823Z"
              :fill="link.linkActive ? '#046DB3' : '#fff'"
            />
          </g>
        </svg>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          class="arrow-icon arrow-up"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0111 6.9177C13.3378 6.63187 13.3378 6.17104 13.0111 5.8852L7.47109 1.0377C7.21109 0.810202 6.79109 0.810202 6.53109 1.0377L0.991094 5.8852C0.664427 6.17104 0.664427 6.63187 0.991094 6.9177C1.31776 7.20354 1.84443 7.20354 2.17109 6.9177L7.00443 2.69437L11.8378 6.92354C12.1578 7.20354 12.6911 7.20354 13.0111 6.9177Z"
            :fill="link.linkActive ? '#046DB3' : '#fff'"
          />
        </svg>
      </b-button>
      <b-collapse :id="`collapse-${ link.id }`" class="mt-2">
        <slot name="child"> </slot>
      </b-collapse>
    </template>
  </li>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'sidebar-item',
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: '',
          path: '',
          children: [],
          iconType: '',
        };
      },
      description:
        'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info',
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    ...mapState({
      sidebarVisiblity: (state) => state.sidebar.visible,
    })
  },
  methods: {
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
