<template>
  <div>
    <div class="card-container">
      <b-row>
        <b-col>
          <p>{{ $t('participant_information') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex flex-wrap">
            <div class="item">
              <span class="opacity-6 card-label">{{ $t('study') }}</span>
              <p class="avenir-bold">
                {{ selectedParticipantOrigin.studyName }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex flex-wrap">
            <div class="item">
              <span class="opacity-6 card-label">{{ $t('protocol') }}</span>
              <p class="avenir-bold">
                {{ selectedParticipantOrigin.protocolName }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex flex-wrap">
            <div class="item">
              <span class="opacity-6 card-label">{{ $t('site') }}</span>
              <p class="avenir-bold">
                {{ selectedParticipantOrigin.siteName }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex flex-wrap">
            <div class="item">
              <span class="opacity-6 card-label">{{
                $t('study_start_date')
              }}</span>
              <p class="avenir-bold">
                {{ selectedParticipantOrigin.dateStart }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex flex-wrap">
            <div class="item">
              <span class="opacity-6 card-label">{{
                $t('study_end_date_planned')
              }}</span>
              <p class="avenir-bold">
                {{ selectedParticipantOrigin.dateEnd }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </div>
    <!-- second participant info -->
    <b-row>
      <b-col>
        <div class="card-container">
          <b-row>
            <b-col>
              <p>{{ $t('study_information') }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('phone_number_mobile')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipantOrigin.phone }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('participant_id_subject')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipantOrigin.subjectCode }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('type') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipantOrigin.tags === ['healthy_control']
                        ? $t('healthy_control')
                        : $t('patient')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('gender') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipantOrigin.gender.name === 'gender_male'
                        ? $t('male')
                        : $t('female')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('height') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipantOrigin.height }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('weight') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipantOrigin.weight }}
                  </p>
                </div>
              </div></b-col
            >
          </b-row>
        </div>
      </b-col>
    </b-row>

    <HorizontalDivider colorLine="e8e9ec"></HorizontalDivider>
    <div class="card-container">
      <b-row>
        <b-col>
          <p class="cap mt-2 mb-4">{{ $t('schedule_tailor') }}</p>
        </b-col>
      </b-row>
      <div
        v-for="(partner, pi) in participantTestsOriginal"
        :key="partner.id"
        class="accordion partners-title"
        role="tablist"
      >
        <b-row>
          <b-col cols="4">
            <h3 class="pt-3 pb-2" v-b-toggle="`collapse-${partner.id}`">
              <span
                v-if="selectedTaskFromPreviousStep[pi].highlighted === true"
                class="editing"
              >
                {{ partner.name }}
                <edit-icon class="align-self-stretch ml-2"></edit-icon
              ></span>
              <span v-else>{{ partner.name }}</span>
            </h3>
          </b-col>
        </b-row>
        <b-collapse visible :id="`collapse-${partner.id}`" class="ml-4">
          <b-row
            v-for="(category, i) in partner.categories"
            :key="i"
            class="mt-3 pb-4"
          >
            <b-col>
              <b-row>
                <b-col cols="4">
                  <h4
                    v-b-toggle="`collapse-${category.id}${i}-inner`"
                    size="sm"
                    class="child-toggle"
                  >
                    <span
                      v-if="
                        selectedTaskFromPreviousStep[pi].categories[i]
                          .marked === true
                      "
                      class="editing"
                    >
                      {{ $t(category.name) }}
                      <edit-icon class="align-self-stretch ml-2"></edit-icon
                    ></span>
                    <span
                      v-if="
                        selectedTaskFromPreviousStep[pi].categories[i]
                          .marked === false
                      "
                    >
                      {{ $t(category.name) }}
                    </span>
                  </h4>
                </b-col>
                <b-col cols="4" class="d-flex align-items-center">
                  <p
                    :id="`testNumber${selectedTaskFromPreviousStep[pi].categories[i].id}`"
                    :class="
                      selectedTaskFromPreviousStep[pi].categories[i].marked ===
                      true
                        ? 'text-purple'
                        : 'text-blue'
                    "
                  >
                    <small>
                      {{
                        enabledNumberOfTests(
                          selectedTaskFromPreviousStep[pi].categories[i]
                        )
                      }}
                      {{ $t('/') }}
                      {{ totalNumberOfTests(category) }}
                      {{ $t('enabled') }}</small
                    >
                  </p></b-col
                >

                <b-col cols="4"> </b-col>
              </b-row>
              <b-row>
                <!-- Tasks collapse -->
                <b-collapse visible :id="`collapse-${category.id}${i}-inner`">
                  <b-col v-for="(test, a) in category.tests" :key="test.id">
                    <div
                      class="ml-3 mt-3"
                      v-if="
                        selectedTaskFromPreviousStep[pi].categories[i].tests[a]
                          .versions.enabled !=
                        participantTestsOriginal[pi].categories[i].tests[a]
                          .versions.enabled
                      "
                    >
                      <p class="avenir-bold">
                        <span class="text-purple">
                          <checked-icon
                            v-if="
                              participantTestsOriginal[pi].categories[i].tests[
                                a
                              ].versions.enabled
                            "
                            class="mr-2 opacity06"
                          />
                          <clear-icon
                            v-else
                            class="mr-2 opacity06"
                          ></clear-icon>
                          <arrow-short class="mr-2" />
                          <checked-icon
                            v-if="
                              selectedTaskFromPreviousStep[pi].categories[i]
                                .tests[a].versions.enabled
                            "
                            class="mr-2"
                          />
                          <clear-icon v-else class="mr-2" />
                          <span class="custom-name">{{ $t(test.name) }}</span>
                        </span>
                        <edit-icon class="align-self-stretch ml-2"></edit-icon>
                      </p>
                    </div>
                    <div v-else class="ml-3 mt-3">
                      <p class="avenir-bold">
                        <span class="text-grey"
                          ><checked-icon
                            v-if="
                              participantTestsOriginal[pi].categories[i].tests[
                                a
                              ].versions.enabled
                            "
                            class="mr-2 opacity06"
                          />
                          <clear-icon v-else class="mr-2 opacity06" />
                          <arrow-short class="mr-2" />
                          <checked-icon
                            v-if="
                              selectedTaskFromPreviousStep[pi].categories[i]
                                .tests[a].versions.enabled
                            "
                            class="mr-2"
                          />
                          <clear-icon v-else class="mr-2" />
                          <span
                            class="custom-name"
                            :class="
                              selectedTaskFromPreviousStep[pi].categories[i]
                                .tests[a].versions.enabled
                                ? 'dark'
                                : 'opacity05'
                            "
                          >
                            {{ $t(test.name) }}</span
                          >
                        </span>
                      </p>
                    </div>
                  </b-col>
                </b-collapse>
              </b-row>
            </b-col>
          </b-row>
        </b-collapse>
      </div>
    </div>

    <b-row
      class="mt-4"
      v-if="this.$store.state.participants.changeReason != null"
    >
      <b-col cols="4">
        <span class="editing mr-2">{{ $t('change_reason') }} </span>
        <edit-icon class="align-self-stretch"></edit-icon>
        <p class="avenir-bold mt-2">
          {{
            this.$store.state.participants.changeReason === 'change'
              ? $t('change_reason_change_short')
              : $t('change_reason_correction_short')
          }}
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <info-panel
          v-if="this.$store.state.participants.changeReason != null"
          class="mt-2"
          :title="alertTitle"
          :inputNum="1"
          :radioText="inputText"
          @check-confirm="checkConfirm"
        ></info-panel>
        <info-panel
          v-else
          class="mt-2"
          backgroundColor="#778CA2"
          :title="alertTitle2"
          :inputNum="2"
          :radioText="inputText"
          @check-confirm2="checkConfirm"
        ></info-panel>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          v-if="this.$store.state.participants.changeReason != null"
          type="primary"
          :disabled="disabledButton"
          @click="handleNextClick('update')"
          rounded
          data-testid="next-button"
        >
          {{ $t('participant_update') }}
        </base-button>
        <base-button
          v-else
          type="primary"
          :disabled="disabledButton"
          @click="handleNextClick('exit')"
          rounded
          data-testid="next-button"
        >
          {{ $t('exit') }}
        </base-button>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import EditIcon from '@/components/Icons/EditIcon';
import CheckedIcon from '@/components/Icons/CheckedIcon.vue';
import ClearIcon from '@/components/Icons/ClearIcon.vue';
import ArrowShort from '@/components/Icons/ArrowShort';
import InfoPanel from '@/components/InfoPanel';
import { sendExcludedTasks } from '@/api/participant';

export default {
  components: {
    //cardParticipantInfo,
    HorizontalDivider,
    //overviewStudy,
    EditIcon,
    CheckedIcon,
    ClearIcon,
    ArrowShort,
    InfoPanel,
  },
  mixins: [cardStudyInfoMixin],
  data() {
    return {
      alertTitle: this.$t('change_box_overview_schedule_changes'),
      alertMessage: '',
      inputText: this.$t('i_understand_continue'),
      alertTitle2: this.$t('change_box_no_changes'),
      backgroundColor: '#ffffff',
      disabledButton: true,
      displayPanel: true,
      componentKey: 0,
      testOne: false,
      tesTwo: false,
      titleStudyInfo: 'study_information',
      titleParticipantInfo: 'participant_information',
      partners: [],
      participantInfoCardData: {},
    };
  },
  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
      selectedParticipantOrigin: ({ participants }) =>
        participants.selectedParticipantOrigin,
      participantTestsOriginal: ({ participants }) =>
        participants.participantTests,
      selectedTaskFromPreviousStep: ({ participants }) =>
        participants.selected_tasks,
      changedTest: ({ participants }) => participants.changedTestsPayload,
      changeReason: ({ participants }) => participants.changeReason,
    }),
    ...mapGetters({
      extractStudyCardInfo: 'participants/extractStudyCardInfo',
    }),
  },

  created() {
    this.testOne = true;
    this.partners = this.selectedParticipant.partners;
    this.componentKey += 1;
  },
  mounted() {
    this.tesTwo = true;
  },
  methods: {
    handleNextClick(param) {
      if (param === 'exit') {
        this.$router.push({ name: 'view-participant' });
      }
      if (param === 'update') {
        const payload = { tests: this.changedTest, reason: this.changeReason };
        const { clientId, studyId, protocolId, siteId, id } =
          this.selectedParticipant;
        sendExcludedTasks({
          clientId,
          studyId,
          protocolId,
          siteId,
          id,
          payload,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.info(this.$t('toast_participant_updated'));
              this.$router.push({ name: 'view-participant' });
            } else {
              this.$toast.info(this.$t('error_something_went_wrong'));
              this.$router.push({ name: 'view-participant' });
            }
          })
          .catch((err) => {
            this.$toast.error(this.$t(err.data.msg));
          })
          .finally(() => {});
      }
    },
    enabledNumberOfTests(category) {
      let numbers = 0;
      for (let index = 0; index < Object.keys(category.tests).length; index++) {
        if (category.tests[index].versions.enabled) {
          numbers += 1;
        }
      }
      return numbers;
    },
    totalNumberOfTests(category) {
      return Object.keys(category.tests).length;
    },
    checkConfirm(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },
  },
};
</script>

<style>
.editing {
  color: #3331e0;
}
.opacity06 {
  opacity: 0.7;
}

.opacity05 {
  opacity: 0.5;
}

.text-blue {
  color: #046db3;
}
.dark {
  color: #595858;
}

.text-purple {
  color: #3331e0;
}
.custom-name {
  font-weight: bold !important;
}
.cap {
  text-transform: capitalize !important;
}
</style>
