<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 9.5H19.5L14 4V9.5ZM7 2.5H15L21 8.5V20.5C21 21.0304 20.7893 21.5391 20.4142 21.9142C20.0391 22.2893 19.5304 22.5 19 22.5H7C6.46957 22.5 5.96086 22.2893 5.58579 21.9142C5.21071 21.5391 5 21.0304 5 20.5V4.5C5 3.39 5.89 2.5 7 2.5ZM16 18.5V16.5H7V18.5H16ZM19 14.5V12.5H7V14.5H19Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
