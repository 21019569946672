import router from './routes';
import { i18n } from './plugins/i18n.js';

const publicPages = ['login', 'password-reset', 'update-password'];

router.beforeEach((to, from, next) => {
  if (publicPages.includes(to.name)) {
    if (localStorage.lang) {
      i18n.locale = localStorage.lang;
    }
    if (!localStorage.lang) {
      i18n.locale = navigator.language.includes('en')
        ? 'en'
        : navigator.language;
    }
    next();
  } else {
    next();
  }
});
