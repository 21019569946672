export const listClientFilterDataObjectMapper = (clients) => {
  return clients.map((client) => ({
    title: client.legalName,
    value: client.id,
  }));
};

export const listStudyFilterDataObjectMapper = (studies) => {
  return studies.map((study) => ({
    title: study.name,
    value: study.id,
    clientId: study.client.id,
  }));
};

export const listProtocolFilterDataObjectMapper = (protocols) => {
  return protocols.map((protocol) => ({
    title: protocol.name,
    value: protocol.id,
    clientId: protocol.client.id,
    studyId: protocol.study.id,
  }));
};

export const listSiteFilterDataObjectMapper = (sites) => {
  return sites.map((site) => ({
    title: site.name,
    value: site.id,
    clientId: site.client.id,
    studyId: site.study.id,
    protocolId: site.protocol.id,
  }));
};
