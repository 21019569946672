<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="16"
    height="16"
    viewBox="0 0 256 256"
    xml:space="preserve"
  >
    <desc>Created with Fabric.js 1.7.22</desc>
    <defs></defs>
    <g transform="translate(128 128) scale(1.72 1.72)" style="">
      <g
        style="
          stroke: none;
          stroke-width: 0;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(255, 255, 255);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
      ></g>
      <g
        style="
          stroke: none;
          stroke-width: 0;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: none;
          fill-rule: nonzero;
          opacity: 1;
        "
        transform="translate(-130.05 -130.05000000000004) scale(2.89 2.89)"
      >
        <path
          d="M 86.5 41.5 h -38 v -38 C 48.5 1.567 46.933 0 45 0 c -1.933 0 -3.5 1.567 -3.5 3.5 v 38 h -38 C 1.567 41.5 0 43.067 0 45 s 1.567 3.5 3.5 3.5 h 38 v 38 c 0 1.933 1.567 3.5 3.5 3.5 c 1.933 0 3.5 -1.567 3.5 -3.5 v -38 h 38 c 1.933 0 3.5 -1.567 3.5 -3.5 S 88.433 41.5 86.5 41.5 z"
          style="
            stroke: none;
            stroke-width: 1;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: #c5d8e4;
            fill-rule: nonzero;
            opacity: 1;
          "
          :fill="this.skinColorContent"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      skinColorContent: 'red',
    };
  },
};
</script>

<style></style>
