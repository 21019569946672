import {
  fetchStudyTeamMembers,
  fetchSiteTeamMembers,
  fetchTeamMembers,
} from '@/api/members';

const initialState = {
  list: [],
  pagination: {},
};
const state = { ...initialState };

const getters = {};

const mutations = {
  SET_TEAM_MEMBERS: (state, members) => {
    state.list = members;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async fetchTeamMembers({ commit }, payload) {
    try {
      const { data, pagination } = await fetchTeamMembers(payload);

      /* Temp: move to a mapper */
      data.map((member) => ({
        ...member,
        status: {
          name: member.status.name === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
        },
      }));

      commit('SET_TEAM_MEMBERS', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async dispatchFetchSiteMembers({ commit }, payload) {
    try {
      const { data } = await fetchSiteTeamMembers(payload);
      commit('SET_TEAM_MEMBERS', data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async dispatchFetchStudyMembers({ commit }, payload) {
    try {
      const { data } = await fetchStudyTeamMembers(payload);
      commit('SET_TEAM_MEMBERS', data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
