<template>
  <div>
    <div :class="badgeClass">{{ content }} %</div>
  </div>
</template>

<script>
export default {
  name: 'ReportAdherenceLevels',
  props: {
    content: {
      type: Number,
      required: true,
    },
  },
  created() {},
  computed: {
    badgeClass() {
      if (this.content === 100) {
        return 'badge colorGreenDark';
      }
      if (this.content < 100 && this.content >= 80) {
        return 'badge colorGreen';
      }
      if (this.content < 80 && this.content >= 60) {
        return 'badge colorYellow';
      }
      if (this.content < 60 && this.content >= 40) {
        return 'badge colorOrange';
      }
      if (this.content < 40 && this.content >= 20) {
        return 'badge colorOrangeDark';
      }
      if (this.content < 20 && this.content >= 0) {
        return 'badge colorRed';
      } else {
        return 'badge';
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.badge {
  display: inline-block;
  padding: 8px 5px;
  border-radius: 15px;
  font-size: 12px;
}

.colorGreenDark {
  background-color: #009648;
  color: #fff;
}
.colorGreen {
  background-color: #67eba6;
  color: #000000;
}
.colorYellow {
  background-color: #f5e344;
  color: #000000;
}
.colorOrange {
  background-color: #ff9c5a;
  color: #000000;
}
.colorOrangeDark {
  background-color: #ff6c45;
  color: #000000;
}
.colorRed {
  background-color: #d15259;
  color: #fff;
}
</style>
