<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_16774_15881)">
      <path
        d="M6.61349 8.69531V13.7156"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0275 6.29688V13.7203"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3869 11.3516V13.7192"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.72656 10.0292C2.72656 4.55262 4.55262 2.72656 10.0292 2.72656C15.5058 2.72656 17.3318 4.55262 17.3318 10.0292C17.3318 15.5058 15.5058 17.3318 10.0292 17.3318C4.55262 17.3318 2.72656 15.5058 2.72656 10.0292Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16774_15881">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
