<template>
  <div class="mb-3">
    <div class="d-flex flex-row align-items-center">
      <multiselect
        v-model="selected"
        track-by="prefix"
        :placeholder="''"
        :options="options"
        :show-labels="false"
        :searchable="false"
        :allow-empty="false"
        :openDirection="'bottom'"
        class="country-select"
        @open="openSelector()"
        @close="closeSelector()"
        ><span class="statusArrow" slot="caret">
          <arrow-down v-if="showArrowDown" />
          <arrow-down v-if="showArrowUp" class="arrow-up" />
        </span>
        <template slot="singleLabel" slot-scope="props">
          <img
            class="option__image"
            :src="props.option.img"
            alt="Country flag"
          />
        </template>
        <template slot="option" slot-scope="props">
          <div class="d-flex flex-row justify-content-between">
            <div>
              <img
                class="option__image"
                :src="props.option.img"
                alt="Country flag"
              />
              <span class="ml-2">{{ props.option.name }}</span>
            </div>
            <span class="ml-3">{{ props.option.prefix }}</span>
          </div>
        </template>
      </multiselect>

      <div
        v-if="selected"
        class="prefix d-flex flex-row justify-content-center"
      >
        {{ selected.prefix }}
      </div>

      <the-mask
        v-if="selected"
        v-model="phoneNumber"
        :mask="selected.mask"
        :tokens="tokens"
        :class="['form-control']"
        data-testid="phone-input"
      />
    </div>
    <div
      v-if="phoneNumber && phoneNumber.length && !isValidPhone"
      class="text-danger text-sm text-right"
    >
      {{ $t('enter_valid_phone_number') }}
    </div>
  </div>
</template>

<script>
import ArrowDown from './Icons/ArrowDown';

export default {
  components: { ArrowDown },
  name: 'PhoneNumberInputByCountry',
  created() {
    this.selected = this.options[1];
    this.setInputValuesFromRaw(this.value);
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
      showArrowDown: true,
      showArrowUp: false,
      options: [
        {
          name: this.$t('country_name_spain'),
          prefix: '+34',
          prefixRegExp: /^\+34/,
          img: '/img/icons/spain.png',
          mask: '### ### ###',
          regularExpression: /^\d{9}$/,
        },
        {
          name: this.$t('country_name_switzerland'),
          prefix: '+41',
          prefixRegExp: /^\+41/,
          img: '/img/icons/switzerland.png',
          mask: '## ### ####',
          regularExpression: /^\d{9}$/,
        },
        {
          name: this.$t('country_name_US'),
          prefix: '+1',
          prefixRegExp: /^\+1/,
          img: '/img/icons/united_states.png',
          mask: '###-###-####',
          regularExpression: /^\d{10}$/,
        },
        {
          name: this.$t('country_name_netherlands'),
          prefix: '+31',
          prefixRegExp: /^\+31/,
          img: '/img/icons/netherlands.png',
          mask: '#########',
          regularExpression: /^\d{9}$/,
        },
        {
          name: this.$t('country_name_france'),
          prefix: '+33',
          prefixRegExp: /^\+33/,
          img: '/img/icons/france.png',
          mask: '# ## ## ## ##',
          regularExpression: /^\d{9}$/,
        },
        {
          name: this.$t('country_name_germany'),
          prefix: '+49',
          prefixRegExp: /^\+49/,
          img: '/img/icons/germany.png',
          mask: '###############',
          regularExpression: /^\d{8,15}$/,
        },
      ],
      phoneNumber: null,
      tokens: {
        '#': { pattern: /\d/ },
      },
    };
  },
  computed: {
    isValidPhone() {
      return (
        this.selected && this.selected.regularExpression.test(this.phoneNumber)
      );
    },
  },
  methods: {
    openSelector() {
      this.showArrowDown = false;
      this.showArrowUp = true;
    },
    closeSelector() {
      this.showArrowDown = true;
      this.showArrowUp = false;
    },
    setInputValuesFromRaw(rawValue) {
      this.options.some((option) => {
        if (option.prefixRegExp.test(rawValue)) {
          let phoneWithoutPrefix = rawValue.replace(option.prefix, '');
          phoneWithoutPrefix = phoneWithoutPrefix.replace(/^\s/g, '');
          if (
            option.regularExpression.test(phoneWithoutPrefix) &&
            phoneWithoutPrefix !== this.phoneNumber
          ) {
            this.selected = { ...option };
            this.phoneNumber = phoneWithoutPrefix;
            return true;
          }
        }
        return false;
      });
    },
  },
  watch: {
    selected: function () {
      const phone = this.isValidPhone
        ? this.selected.prefix + ' ' + this.phoneNumber
        : null;
      this.$emit('input', phone);
    },
    phoneNumber: function () {
      const phone = this.isValidPhone
        ? this.selected.prefix + ' ' + this.phoneNumber
        : null;
      this.$emit('input', phone);
    },
    value: function () {
      this.setInputValuesFromRaw(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.country-select {
  width: 80px;
}

::v-deep .multiselect__content-wrapper {
  width: 200px;
}

span.statusArrow {
  position: absolute;
  top: 9px;
  right: 15px;
}
.arrow-up {
  transform: rotate(180deg);
}

.option__image {
  width: 20px;
}

.prefix {
  color: #046db3;
  width: 80px;
}
</style>
