<template>
  <div class="main-container">
    <div class="container-title box">
      <p class="pt-3 pl-3 avenir-bold">{{ $t('adherence_to_schedules') }}</p>
    </div>
    <div class="container-chart box pt-4 pl-3 pr-3">
      <vc-donut
        background="white"
        foreground="grey"
        :size="170"
        unit="px"
        :thickness="25"
        :sections="adherenceStatistics"
        :total="100"
        :start-angle="0"
        :auto-adjust-text-size="true"
        class="report-chart-grey"
      >
        <div class="chart-text">
          <span class="chartTitle"></span>
        </div>
      </vc-donut>

      <div>
        <div class="table-container mt-4 mb-4">
          <div
            v-for="section in adherenceStatistics"
            :key="section.color"
            class="table-cell"
          >
            <div
              class="color-circle"
              :style="{ backgroundColor: section.color }"
            ></div>
            <div class="table-data pl-3">
              <span class="value"
                >{{ section.value }}% ({{ section.number }})</span
              ><span class="label">{{ section.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParticipantAdherenceScheduleChart',
  props: {
    adherenceStatistics: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
}

.box {
  flex-grow: 1;
  border: 1px solid #e9ecef;
}
.container-chart {
  justify-content: center;
}
.chartTitle {
  font-size: 10px !important;
}
.table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
}

.table-cell {
  padding: 5px;
  display: flex;
  //justify-content: center;
  align-items: center;
}
.color-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.table-data {
  display: flex;
  flex-direction: column;
  .value {
    font-weight: bold;
    color: #000000;
  }
  .label {
    font-size: 11px;
    color: grey;
    text-transform: capitalize;
  }
}
</style>
