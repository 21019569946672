import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

/**
 * GET: fetch the list of existing clients
 */
export function fetchClients(params) {
  const url = '/clients?sort=-created_at';
  return request.get(url, { params });
}

export function fetchClientsV2(params) {
  const url = '/clients';
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { params, headers });
}

/**
 * GET: fetch the list of existing clients WITH PAGINATION
 */
export function fetchClientsServerSide(params) {
  const url = `clients`;
  return request.get(url, { params });
}

/**
 * POST: create client with basic information (Step 1 values)
 */
export function createClient(payload) {
  const url = '/clients';
  return request.post(url, payload);
}

/**
 * GET: fetch the client information only (Step 1 values)
 */
export function fetchClientBasicInfo(clientId) {
  const url = `/clients/${clientId}`;
  return request.get(url);
}

/**
 * POST: create representative against a client
 */
export function createRepresentative(clientId, params) {
  const url = `/clients/${clientId}/representatives`;
  return request.post(url, params);
}

/**
 * GET: fetch existing client contacts detail
 */
export function fetchClientContacts(clientId) {
  const url = `/clients/${clientId}/contacts`;
  return request.get(url);
}

/**
 * GET: fetch representatives associated to a client
 */
export function fetchClientRepresentatives(clientId) {
  const url = `/clients/${clientId}/representatives`;
  return request.get(url);
}

// UPDATE

export function updateClientBasicInfo(clientId, payload) {
  const url = `/clients/${clientId}`;
  return request.put(url, payload);
}

export function updateClientContact(clientId, contactId, payload) {
  const url = `/clients/${clientId}/contacts/${contactId}`;
  return request.put(url, payload);
}

// GET: Retrieve a Client Representative With roles
export function fetchClientRepresentativeDetails({
  clientId,
  representativeId,
}) {
  const url = `/clients/${clientId}/representatives/${representativeId}`;
  return request.get(url);
}

// DELETE
export function deleteClientRepresentative({ clientId, representativeId }) {
  const url = `/clients/${clientId}/representatives/${representativeId}`;
  return request.delete(url);
}
// DELETE
export function updateClientRepresentative({
  clientId,
  representativeId,
  payload,
}) {
  const url = `/clients/${clientId}/representatives/${representativeId}`;
  return request.put(url, payload);
}

// Get Retrieve a Client statistics
export function fetchClientStatistics({ clientId, studyId = null }) {
  const url = `/clients/${clientId}/statistics?filter[study_id]=${studyId}`;
  return request.get(url);
}
