const initialState = {
  crumbs: [],
};
const state = { ...initialState };

const getters = {
  crumbs: (state) => state.crumbs,
};

const mutations = {
  SET_CRUMBS: (state, crumbs) => {
    if (Array.isArray(crumbs)) {
      state.crumbs = [...crumbs];
    }
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  set({ commit }, crumbs) {
    commit('SET_CRUMBS', crumbs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
