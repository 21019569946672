<template>
  <b-spinner class="mt-4 mx-auto d-block" v-if="firstLoading" />
  <div class="mt-4" v-else>
    <protocols-table
      :protocolsListTableData="protocols"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
    <PaginatorTableComponent
      @changePageEvent="handleChangePageEvent"
      :current-page="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :page-size="pagination.pageSize"
    />
  </div>
</template>

<script>
import ProtocolsTable from '@/components/Tables/ProtocolsTable.vue';
import { mapActions, mapState } from 'vuex';
import { paginationDefaults } from '@/constants/pagination-constants';
import listMixin from '@/mixins/listMixin';
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';

export default {
  name: 'ProtocolList',
  components: {
    PaginatorTableComponent,
    ProtocolsTable,
  },
  mixins: [listMixin],
  props: {
    filterData: Object,
  },
  computed: {
    ...mapState({
      protocols: ({ protocols }) => protocols.list,
      storePagination: ({ protocols }) => protocols.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('protocols');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
    };
  },
  created() {
    this.getListData('protocols');
    this.fetchResources();
  },
  methods: {
    ...mapActions({
      fetchListData: 'protocols/fetchProtocols',
      fetchResources: 'resources/dispatchFetchResources',
      setProtocolParams: 'protocols/setProtocolParams',
    }),
    goToDetail(protocol) {
      this.setProtocolParams({
        clientId: protocol.client.id,
        studyId: protocol.study.id,
        protocolId: protocol.id,
      });
      this.$emit('goToDetail', protocol.id);
    },
  },
};
</script>
