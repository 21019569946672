import { fetchParticipantChangeRecords } from '@/api/participantsChanges';

const initialState = {
  list: [],
  pagination: [],
};
const state = { ...initialState };

const getters = {
  participantChangesList: (state) => state.list,
  participantChangesPagination: (state) => state.pagination,
};

const mutations = {
  SET_PARTICIPANT_CHANGES: (state, participantChanges) => {
    state.list = participantChanges;
  },
  SET_PARTICIPANT_CHANGES_PAGINATION: (state, participantChangesPagination) => {
    state.pagination = participantChangesPagination;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchParticipantChangeRecords({ commit }, payload) {
    try {
      const { data, pagination } = await fetchParticipantChangeRecords(payload);
      commit('SET_PARTICIPANT_CHANGES', data);
      commit('SET_PARTICIPANT_CHANGES_PAGINATION', pagination);
    } catch (error) {
      commit('SET_PARTICIPANT_CHANGES', []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
