<template>
  <span>
    <i class="fa fa-edit ml-2 cursor-pointer" @click="showModal"></i>

    <b-modal
      v-model="modalShow"
      v-if="modalShow"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      title-tag="h2"
      size="lg"
    >
      <template slot="modal-title"> {{ $t(title) }} </template>
      <template v-if="authLevel === 'site'">
        <label>{{ $t('site_name') }}: </label>
        <p>{{ siteName }}</p>
      </template>

      <div v-if="canManageUsers" class="mb-2">
        <label>{{ $t('user_status') }}</label>
        <b-form-checkbox
          id="assign_user_status"
          name="assign_user_status"
          switch
          v-model="userIsActive"
        >
          {{ userIsActive ? $t('ACTIVE') : $t('INACTIVE') }}
        </b-form-checkbox>
      </div>

      <b-row>
        <b-col cols="6">
          <label>{{ $t('name_first') }}</label>
          <b-form-input
            v-model="user.firstName"
            :placeholder="$t('name_first')"
          ></b-form-input>
        </b-col>
        <b-col cols="6">
          <label>{{ $t('name_last') }}</label>
          <b-form-input
            v-model="user.lastName"
            :placeholder="$t('name_last')"
          ></b-form-input>
        </b-col>
      </b-row>

      <label>{{ $t('title_work_job') }}</label>
      <b-form-input
        v-model="user.title"
        :placeholder="$t('title_work_job')"
      ></b-form-input>

      <label>{{ $t('email') }}</label>
      <b-form-input
        v-model="user.email"
        id="email"
        :state="isUserEmailValid"
        :placeholder="$t('email_tip')"
        aria-describedby="email-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="email-live-feedback">{{
        $t('error_email_not_valid_fe')
      }}</b-form-invalid-feedback>

      <label>{{ $t('phone') }}</label>
      <phone-number-input-by-country v-model="user.phone" />

      <h4 class="mt-5">{{ $t('roles') }}</h4>
      <div class="text-center" v-if="loadingRoles">
        <b-spinner />
      </div>
      <b-form-checkbox-group
        v-else
        stacked
        :options="roles"
        v-model="selectedUserRoles"
        value-field="id"
        text-field="name"
      />

      <div class="mt-5">
        <base-button type="button" class="ml-auto" @click="modalShow = false"
          >{{ $t('cancel') }}
        </base-button>
        <base-button
          type="primary"
          @click="updateUser"
          :disabled="
            loadingRoles || !isValidRep || (!fieldsChanged && !userRolesChanged)
          "
          >{{ $t('update') }}</base-button
        >
      </div>
    </b-modal>
  </span>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PhoneNumberInputByCountry from '@/components/PhoneNumberInput';
import { validEmail } from '@/utils/validations';
import { fetchClientRepresentativeDetails } from '@/api/client';
import { fetchStudyCoordinatorDetails } from '@/api/study';
import { getProtocolApprover } from '@/api/protocol';
import { getTeamMemberDetail } from '@/api/members';

export default {
  components: {
    PhoneNumberInputByCountry,
  },
  props: {
    title: String,
    member: Object,
    listOfMembers: Array,
    authLevel: String,
    clientId: String,
  },
  data() {
    return {
      userIsActive: null,
      modalShow: false,
      loading: false,
      loadingRoles: true,
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
      },
      initialUserRoles: [],
      selectedUserRoles: [],
      siteName: '',
    };
  },
  beforeMount() {
    this.$store.commit('experts/UPDATING_REPRESENTATIVE_DATA', []);
  },
  watch: {
    userIsActive(newValue) {
      // Changing the whole user to
      // force 'fieldsChanged' to recalculate
      this.user = {
        ...this.user,
        status: {
          ...this.user.status,
          name: newValue ? 'ACTIVE' : 'INACTIVE',
        },
      };
    },
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      roles: ({ resources }) => resources.roles,
      protocol: ({ protocols }) => protocols.protocolForEdit,
      siteParams: ({ sites }) => sites.siteParams,
      protocolParams: (state) => state.protocols.protocolParams,
    }),
    ...mapGetters({
      canManageUsers: 'auth/canManageUsers',
    }),
    isValidRep() {
      const { status, firstName, lastName, phone, title } = this.user;
      return (
        !!status &&
        !!firstName &&
        !!lastName &&
        !!phone &&
        !!this.isUserEmailValid &&
        !!title &&
        this.selectedUserRoles.length > 0
      );
    },
    isUserEmailValid() {
      return this.user.email?.length > 0 && validEmail(this.user.email);
    },
    fieldsChanged() {
      return (
        this.member.firstName !== this.user.firstName ||
        this.member.lastName !== this.user.lastName ||
        this.member.title !== this.user.title ||
        this.member.email !== this.user.email ||
        this.member.phone !== this.user.phone ||
        this.member.status.name !== this.user.status.name
      );
    },
    userRolesChanged() {
      return this.rolesDiff(this.selectedUserRoles, this.initialUserRoles);
    },
  },
  methods: {
    ...mapActions({
      fetchRoles: 'resources/dispatchFetchRoles',
    }),
    async showModal() {
      try {
        this.user = this.getUserFromMember();
        this.userIsActive = this.user?.status?.name?.toLowerCase() === 'active';
        this.modalShow = true;

        await this.fetchRoles({
          'filter[auth_level]': this.authLevel,
        });

        /*
        If updating a 'new' or 'existing' added roleUser, the user
        already includes selected roles so no need to fetch the user.
        But if the roleUser is a site team member always fetch the user as
        the update is not part of the higher level entity update
        (and the 'sitename' field returned is needed)
      */
        if (this.member.roles?.length > 0 && this.authLevel !== 'site') {
          this.initialUserRoles = [...this.member.roles];
          this.selectedUserRoles = [...this.member.roles];
        } else {
          const roles = await this.getUserRoles();
          this.initialUserRoles = [...roles];
          this.selectedUserRoles = [...roles];
        }

        this.loadingRoles = false;
      } catch (error) {
        this.displayError('Error show update user modal', error);
      }
    },
    closeModal() {
      this.modalShow = false;
    },
    async updateUser() {
      this.$emit('updateNewUser', {
        ...this.user,
        fieldsChanged: this.fieldsChanged,
        rolesChanged: this.userRolesChanged,
        roles: [...this.selectedUserRoles],
      });
      this.closeModal();
    },
    rolesDiff(selected, assigned) {
      if (Array.isArray(selected) && Array.isArray(assigned)) {
        return (
          selected.length !== assigned.length ||
          selected.filter((role) => !assigned.includes(role)).length > 0 ||
          assigned.filter((role) => !selected.includes(role)).length > 0
        );
      } else {
        this.displayError(
          'Error update user',
          new Error(this.$t('error_something_went_wrong'))
        );
      }
    },
    getUserFromMember() {
      const {
        id,
        userId,
        firstName,
        lastName,
        title,
        email,
        phone,
        status,
        type,
        tempId,
        expertId,
        resourceKeys,
      } = this.member;
      return {
        id,
        userId,
        firstName,
        lastName,
        title,
        email,
        phone,
        status,
        type,
        tempId,
        expertId,
        resourceKeys,
      };
    },
    async getUserRoles() {
      try {
        const { id: memberId } = this.member;
        let response;

        if (this.authLevel === 'client') {
          response = await fetchClientRepresentativeDetails({
            clientId: this.clientId,
            representativeId: memberId,
          });
        }
        if (this.authLevel === 'study') {
          response = await fetchStudyCoordinatorDetails({
            clientId: this.study.clientId,
            studyId: this.study.id,
            coordinatorId: memberId,
          });
        }
        if (this.authLevel === 'protocol') {
          response = await getProtocolApprover({
            clientId: this.clientId,
            studyId: this.protocolParams.studyId,
            protocolId: this.protocol.basic.id,
          });
        }
        if (this.authLevel === 'site') {
          const { clientId, studyId, protocolId, siteId } =
            this.member.resourceKeys;
          response = await getTeamMemberDetail({
            clientId,
            studyId,
            protocolId,
            siteId,
            memberId: memberId,
          });
        }

        const { roles, siteName } = response.data;
        if (siteName) this.siteName = siteName;
        return roles.map(({ role }) => role.id);
      } catch (error) {
        console.error(error);
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>
