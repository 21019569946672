<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.81106 4.11155C4.81106 2.34953 6.23793 0.922656 7.99995 0.922656C9.76196 0.922656 11.1888 2.34953 11.1888 4.11155C11.1888 5.87356 9.76196 7.30043 7.99995 7.30043C6.23793 7.30043 4.81106 5.87356 4.81106 4.11155ZM0.922168 12.8615C0.922168 12.4721 1.11169 12.0792 1.54717 11.6773C1.9874 11.271 2.63067 10.9045 3.39793 10.5971C4.93358 9.98193 6.78953 9.67266 7.99995 9.67266C9.21036 9.67266 11.0663 9.98193 12.602 10.5971C13.3692 10.9045 14.0125 11.271 14.4527 11.6773C14.8882 12.0792 15.0777 12.4721 15.0777 12.8615V15.0782H0.922168V12.8615Z"
      stroke="#C5D8E4"
      stroke-width="1.4"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
