<template>
  <div class="overview-card">
    <p class="text-lg">{{ $t('study_information') }}</p>
    <b-row>
      <b-col cols="3">
        <b-row>
          <b-col class="opacity-6">{{ $t('study_name') }}</b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="avenir-bold">{{ studyName }}</b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col class="opacity-6">
            {{ $t('description') }}
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="avenir-bold">{{ studyDescription }}</b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isProtocolView: this.$route.name.includes('protocol'),
    };
  },
  computed: {
    ...mapState({
      study: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.selectedProtocol,
    }),
    studyName() {
      return this.isProtocolView
        ? this.protocol?.study?.name
        : this.study?.name;
    },
    studyDescription() {
      return this.isProtocolView
        ? this.protocol?.study?.description
        : this.study?.description;
    },
  },
};
</script>

<style></style>
