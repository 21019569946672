import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

/**
 * GET: fetch the list of sites against a study or protocol
 */
export function fetchSites(params) {
  const url = `/sites?sort=-created_at`;
  return request.get(url, { params });
}

export function fetchSitesV2(params) {
  const url = '/sites';
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { params, headers });
}

/**
 * POST: submit a new site against a client study
 */
export function createSite({ clientId, studyId, protocolId, payload }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, payload, { headers });
}

/**
 * PUT: update site against a client study
 */
export function updateSite({ clientId, studyId, protocolId, siteId }, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}`;
  const headers = { apiVersion: apiVersion.two };
  return request.put(url, payload, { headers });
}

/**
 * POST: Add contact information against a site
 */
export function postSiteContact(
  clientId,
  studyId,
  protocolId,
  siteId,
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/contact`;
  return request.post(url, payload);
}

/**
 * GET: Retrieve site contact
 */
export function getSiteContact({ clientId, studyId, protocolId, siteId }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/contact`;
  return request.get(url);
}

/**
 * PUT: update contact information against a site
 */
export function updateSiteContact(
  { clientId, studyId, protocolId, siteId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/contact`;
  return request.put(url, payload);
}

/**
 * GET: fetch single site information
 */
export function fetchSite({ clientId, studyId, protocolId, siteId }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}`;
  return request.get(url);
}

export function fetchSiteStatistics({ clientId, studyId, protocolId, siteId }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/statistics`;
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { headers });
}
