import {
  fetchStudiesV2,
  getStudyCoordinators,
  getStudyDetails,
} from '@/api/study';
import { fetchSites } from '@/api/site';
import { fetchClients } from '@/api/client';

const initialState = {
  studies: [],
  clients: [],
  pagination: {},
  selectedStudy: {},
  protocols: [],
  sites: [],
  studyParams: {},
  selectedTasksId: [],
  selectedTasksData: [],
};
const state = initialState;

const getters = {
  tasksFromAlreadyCreatedStudy({ selectedStudy }) {
    if (selectedStudy.tests) {
      const groupedData = selectedStudy.tests.reduce((acc, item) => {
        const partnerName = item.partner.name;
        if (!acc[partnerName]) {
          acc[partnerName] = {
            partnerName,
            tests: {
              names: [],
              ids: [],
            },
          };
        }
        acc[partnerName].tests.names.push(item.name);
        acc[partnerName].tests.ids.push(item.id);
        return acc;
      }, {});

      return Object.values(groupedData).sort((a, b) => {
        return a.partnerName.localeCompare(b.partnerName);
      });
      //return Object.values(groupedData);
    }
  },
  extractFilterClients({ clients }) {
    return clients.map((client) => ({
      title: client.legalName,
      value: client.id,
    }));
  },
  hasAppropriateProtocols: ({ selectedStudy }) => {
    const { protocolsStatus } = selectedStudy;
    const noProtocols = !protocolsStatus || protocolsStatus.length === 0;
    const allowedProtocolStatus =
      protocolsStatus &&
      Object.values(protocolsStatus)?.length > 0 &&
      Object.values(protocolsStatus).every(
        (status) => status !== 'ACTIVE' && status !== 'WAITING FOR APPROVAL'
      );
    return noProtocols || allowedProtocolStatus;
  },
  allTestIds(state) {
    return state.selectedTasksData.reduce((testIds, partner) => {
      return [...testIds, ...partner.testIds];
    }, []);
  },
  testIdsForUpdate(state) {
    if (state.selectedStudy.tests.length) {
      const selectedTestIds = state.selectedStudy.tests.map((test) => test.id);
      const filteredTestIds = state.selectedTasksData.reduce(
        (testIds, partner) => {
          const filteredIds = partner.testIds.filter(
            (testId) => !selectedTestIds.includes(testId)
          );
          return [...testIds, ...filteredIds];
        },
        []
      );
      return filteredTestIds;
    }
  },
};
const mutations = {
  SET_LIST_STUDIES: (state, studies) => {
    state.studies = studies;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  SELECTED_STUDY: (state, study) => {
    state.selectedStudy = study;
  },
  STUDY_COORDINATORS: (state, coordinators) => {
    state.selectedStudy.representatives = coordinators;
  },
  SET_LIST_STUDY_PROTOCOLS: (state, protocols) => {
    state.protocols = protocols;
  },
  SET_ALL_CLIENTS: (state, data) => {
    state.clients = data;
  },
  SET_STUDY_PARAMS: (state, params) => {
    state.studyParams = params;
  },
  SET_LIST_STUDY_SITES: (state, sites) => {
    state.sites = sites;
  },
  SELECTED_TASKS(state, tasksData) {
    state.selectedTasksData = tasksData;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async fetchStudies({ commit }, payload) {
    try {
      const { data, pagination } = await fetchStudiesV2(payload);
      commit('SET_LIST_STUDIES', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_LIST_STUDIES', []);
      commit('SET_PAGINATION', {});
      return error;
    }
  },
  async dispatchFetchSites({ commit }, payload) {
    try {
      const { data } = await fetchSites(payload);
      commit('SET_LIST_STUDY_SITES', data);
    } catch (error) {
      commit('SET_LIST_STUDY_SITES', []);
      return error;
    }
  },
  async dispatchFetchStudyCoordinator({ commit }, payload) {
    try {
      const { data } = await getStudyCoordinators(payload);
      commit('STUDY_COORDINATORS', data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async fetchFilterData({ commit }) {
    try {
      const clients = await fetchClients();
      commit('SET_ALL_CLIENTS', clients.data);
    } catch (error) {
      commit('SET_ALL_CLIENTS', []);
      this.$toast.error(this.$t('error_something_went_wrong'));
    }
  },
  async getStudyDetails({ commit }, payload) {
    try {
      const { data } = await getStudyDetails(payload);
      commit('SELECTED_STUDY', data);
    } catch (error) {
      this.$toast.error(this.$t('error_something_went_wrong'));
      console.error(error);
    }
  },
  setStudyParams({ commit }, params) {
    commit('SET_STUDY_PARAMS', params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
