export const participantStatusId = {
  active: 1,
  complete: 2,
  onTrack: 10,
  relapse: 14,
  discontinuedReason: 15,
  attention: 16,
  critical: 17,
  screenedOut: 18,
  draft: 19,
};

export const participantStatusName = {
  active: 'ACTIVE',
  complete: 'COMPLETE',
  onTrack: 'ON_TRACK',
  relapse: 'RELAPSE',
  discontinuedReason: 'DISCONTINUED_REASON',
  attention: 'ATTENTION',
  critical: 'CRITICAL',
  screenedOut: 'SCREENED_OUT',
  draft: 'DRAFT',
};

export const participantDiscontinuedStatus = {
  dropped_out: 'DROPPED_OUT',
  consent_withdrawn: 'CONSENT_WITHDRAWN',
  lost: 'LOST',
  other: 'OTHER',
};

export const participantDiscontinuedKeys = {
  dropped_out: 'dropped-out',
  consent_withdrawn: 'consent-withdrawn',
  lost: 'lost',
  other: 'other',
};
