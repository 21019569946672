<template>
  <base-nav
    class="bg-white border-bottom"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <template slot="brand">
      <app-bread-crumbs />
    </template>
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto">
      <li class="nav-item dropdown cursor-pointer mr-4"><search-input /></li>
      <li class="nav-item dropdown cursor-pointer mr-4"><support-modal /></li>
      <li class="nav-item dropdown mr-4" @click="handleNotificationsIconClick">
        <base-dropdown
          class="pr-0"
          position="right"
          menuClasses="shadow border notification-dropdown"
          split
        >
          <div slot="title">
            <template>
              <span
                v-show="notifications.length > 0 && newNotifications"
                class="unread-dot right-dot"
              ></span>
              <i class="fas fa-bell cursor-pointer"> </i>
            </template>
          </div>
          <template>
            <p class="text-lg mb-0 mx-3 border-bottom py-3">
              {{ $t('notification') }}
            </p>
            <p
              class="py-3 text-center notification-message"
              v-if="notifications.length === 0"
            >
              {{ $t('no_notifications') }}
            </p>

            <b-list-group
              flush
              v-show="notifications.length > 0"
              v-for="noti in notifications"
              :key="noti.id"
            >
              <b-list-group-item
                class="d-flex align-items-center cursor-pointer"
                @click="handleNotificationClick(noti)"
              >
                <div class="icon-container">
                  <span v-if="!noti.readAt" class="unread-dot"></span>
                  <img
                    :src="
                      require(`../assets/icons/${
                        noti.type === 'user-password'
                          ? 'password-changed'
                          : noti.type
                      }.svg`)
                    "
                  />
                </div>

                <div class="ml-3">
                  <p class="mb-0">{{ $t(noti.message) }}</p>
                  <small
                    class="opactify-6 text-gray"
                    v-html="renderNotificationActions(noti)"
                  />
                  <small>
                    {{ moment.unix(noti.createdAt).format('D/MM/YYYY HH:mm') }}
                  </small>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </base-dropdown>
      </li>
      <li class="nav-item dropdown cursor-pointer">
        <base-dropdown class="pr-0 dropdown-menu--fixed-width" position="right">
          <div class="d-flex align-items-center" slot="title">
            <i class="ni ni-single-02" />
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold username-text">
                {{ userName }}
              </span>
              <img src="../assets/icons/arrow_down.svg" alt="arrow" />
            </div>
          </div>

          <button
            class="header-dropdown-item btn-reset"
            data-testid="language-item"
            @click="$store.commit('modals/SET_VISIBLE', true)"
          >
            <img src="@/assets/icons/language-world.svg" alt="language" />
            <span>{{ $t('language_preference') }}</span>
          </button>

          <router-link
            :to="{ name: 'change-password' }"
            class="header-dropdown-item btn-reset"
            tag="button"
          >
            <img src="@/assets/icons/settings.svg" alt="change password" />
            <span>{{ $t('password_change') }}</span>
          </router-link>

          <button
            class="header-dropdown-item btn-reset"
            @click="showConfirmation = true"
            data-testid="logout-item"
          >
            <img src="@/assets/icons/logout.svg" alt="change password" />
            <span>{{ $t('logout') }}</span>
          </button>
        </base-dropdown>
      </li>
    </ul>

    <modal
      :show.sync="showConfirmation"
      centered
      content-class="text-center"
      footerClasses="justify-content-center"
    >
      <!-- footer-class="d-flex flex-row justify-content-center" -->
      <template slot="header">
        {{ $t('logout') }}
      </template>

      <div
        v-html="$t('logout_are_you_sure')"
        class="text-center font-weight-bold"
      ></div>

      <template slot="footer">
        <base-button
          type="primary"
          @click="logout"
          :disabled="loading"
          data-testid="logout-button"
          >{{ $t('logout').toUpperCase() }}
          <b-spinner small v-if="loading"></b-spinner>
        </base-button>

        <base-button
          type="secondary"
          @click="showConfirmation = false"
          :disabled="loading"
          >{{ $t('logout_stay_logged_in').toUpperCase() }}
        </base-button>
      </template>
    </modal>

    <AddParticipantModal />
    <!-- change password -->
  </base-nav>
</template>
<script>
import AppBreadCrumbs from '@/components/AppBreadCrumbs';
import SupportModal from '@/components/SupportModal';
import SearchInput from '@/components/SearchInput';
import AddParticipantModal from '@/components/Participants/AddParticipantModal';
import { mapState, mapActions, mapGetters } from 'vuex';
import { updateExpertNotification } from '@/api/notifications';

export default {
  components: {
    AppBreadCrumbs,
    SupportModal,
    SearchInput,
    AddParticipantModal,
  },

  data() {
    return {
      loading: false,
      showConfirmation: false,
      notificationPulling: null,
      protocolTypes: [
        'protocol-waiting-for-approval',
        'protocol-approved',
        'protocol-rejected',
      ],
    };
  },

  computed: {
    ...mapState({
      userName: ({ auth }) => `${auth.firstName} ${auth.lastName}`,
      expertId: (state) => state.auth.expertId,
      newNotifications: (state) => state.notifications.newNotifications,
    }),
    ...mapGetters({
      notifications: 'notifications/LatestNotifications',
    }),
  },

  created() {
    this.getNotifications();
    this.notificationPulling = setInterval(() => {
      this.getNotifications();
    }, 60000);
  },

  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/dispatchFetchExpertNotifications',
      setNotificationsStatus: 'notifications/setNotificationsStatus',
    }),

    logout() {
      this.loading = true;
      this.$store.dispatch('auth/logout').then(() => {
        this.$store.dispatch('reset');
        this.$router.push(`/login`);
        this.loading = false;
      });
    },

    renderNotificationActions(noti) {
      if (noti.type === 'password-changed') return null;
      if (this.protocolTypes.includes(noti.type)) {
        return noti.params && noti.params.protocolName
          ? `${noti.params.studyName} &bull; ${noti.params.protocolName},`
          : '';
      }
      if (noti.type === 'relapse-reported') {
        return noti.params && noti.params.participantMobile
          ? `${noti.params.participantMobile},`
          : '';
      }
    },

    async getNotifications() {
      try {
        await this.fetchNotifications(this.expertId);
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    async handleNotificationClick(noti) {
      try {
        if (!noti.readAt) {
          await updateExpertNotification({
            expertId: this.expertId,
            notificationId: noti.id,
          });
          await this.getNotifications();
        }
        if (noti.type === 'user-password') {
          this.$router.push({
            name: 'change-password',
          });
        }
        if (this.protocolTypes.includes(noti.type)) {
          this.$store.commit('protocols/SET_PROTOCOL_PARAMS', {
            clientId: noti.params.clientId,
            studyId: noti.params.studyId,
            protocolId: noti.params.protocolId,
          });
          this.$router.push({
            name: 'view-protocol',
            params: { id: noti.params.protocolId },
          });
        }
        if (noti.type === 'relapse-reported') {
          this.$store.commit('relapses/SET_RELAPSE_PARAMS', {
            clientId: noti.params.clientId,
            studyId: noti.params.studyId,
            participantId: noti.params.participantId,
            relapseId: noti.params.relapseId,
          });
          this.$router.push({
            name: 'view-relapse',
            params: { id: noti.params.relapseId },
          });
        }
        if (noti.type === 'pending-tasks') {
          this.$store.commit('participants/SET_PARTICIPANT_PARAMS', {
            clientId: noti.params.clientId,
            studyId: noti.params.studyId,
            protocolId: noti.params.protocolId,
            siteId: noti.params.siteId,
            participantId: noti.params.participantId,
          });
          if (this.$route.name === 'view-participant') {
            window.location.reload();
          }
          this.$router.push({ name: 'view-participant' });
        }
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      }
    },

    handleNotificationsIconClick() {
      this.setNotificationsStatus();
    },
  },
  beforeDestroy() {
    clearInterval(this.notificationPulling);
  },
};
</script>
<style lang="scss">
.dropdown-menu--fixed-width {
  width: 220px;
}

.header-dropdown-item {
  display: block;
  padding: 13px 25px !important;
  color: #046db3 !important;
  width: 100% !important;
  img {
    max-width: 16px;
  }
  span {
    margin-left: 17px;
    font-size: 13px;
    line-height: 18px;
    text-transform: capitalize;
  }
}

.username-text {
  margin-right: 10px;
}

.notification-dropdown {
  width: 46vw;

  .icon-container {
    min-width: 50px;
    height: 50px;
    background: var(--blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .notification-message {
    color: var(--blue);
  }
}
.unread-dot {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  width: 6px;
  background: var(--red);
  border-radius: 50%;
  &.right-dot {
    right: -4px;
    left: unset;
  }
}
</style>
