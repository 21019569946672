<template>
  <div>
    <!--  -->
    <b-row>
      <b-col>
        <div class="card-container">
          <b-row>
            <b-col>
              <p>{{ $t('participant_information') }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('phone_number_mobile')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.phone }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('participant_id_subject')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.subjectCode }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('type') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipant.tags === ['healthy_control']
                        ? $t('healthy_control')
                        : $t('patient')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('gender') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipant.gender.name === 'gender_male'
                        ? $t('male')
                        : $t('female')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('height') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.height }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('weight') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.weight }}
                  </p>
                </div>
              </div></b-col
            >
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <h3>{{ $t('devices_third_party') }}</h3></b-col
            >
          </b-row>
          <b-row>
            <b-col v-for="(partner, i) in partners" :key="i">
              <div v-if="partners[i].isEnabled === partnersCopy[i].isEnabled">
                <p>
                  <b-form-checkbox
                    class="ml-3"
                    name="check-button-step2"
                    switch
                    v-model="partners[i].isEnabled"
                    @click="thirdPartyStatus()"
                    >{{ partner.partnerName }}</b-form-checkbox
                  >
                </p>
              </div>
              <div v-else class="editing d-block">
                <p>
                  <b-form-checkbox
                    class="ml-3"
                    name="check-button-step3"
                    switch
                    v-model="partners[i].isEnabled"
                    @click="thirdPartyStatus()"
                    >{{ partner.partnerName }}
                    <edit-icon class="align-self-stretch ml-2"></edit-icon
                  ></b-form-checkbox>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <!--  -->

    <!-- change reason -->
    <b-row v-if="showChangesReason" class="mt-4 mb-4">
      <b-col>
        <h3>{{ $t('change_reason') }}</h3>
        <span>{{ $t('change_reason_info') }}</span>
        <b-form-radio-group v-model="changeReason" class="custom-radio">
          <b-form-radio value="change" class="d-block">
            <span class="opacity-6 avenir ml-0">
              {{ $t('change_reason_change') }}
            </span>
          </b-form-radio>
          <b-form-radio value="correction" class="d-block">
            <span class="opacity-6 avenir">
              {{ $t('change_reason_correction') }}
            </span>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <!-- info panel -->
    <b-row class="info-panel">
      <b-col>
        <info-panel
          v-if="changeReason && showChangesReason"
          class="mt-2"
          :title="alertTitle"
          :message="alertMessage"
          :inputNum="2"
          :radioText="inputText"
          @check-confirm2="checkConfirm"
        ></info-panel>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="primary"
          @click="handleNextClick"
          rounded
          data-testid="next-button"
          :disabled="disabledButton"
        >
          {{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </div>
</template>
          
  <script>
import { mapGetters, mapState } from 'vuex';
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';
import { isEqual, cloneDeep } from 'lodash';
import EditIcon from '@/components/Icons/EditIcon';
import InfoPanel from '@/components/InfoPanel';

export default {
  components: { EditIcon, InfoPanel },
  mixins: [cardStudyInfoMixin],
  data() {
    return {
      testOne: false,
      tesTwo: false,
      titleStudyInfo: 'study_information',
      titleParticipantInfo: 'participant_information',
      keyListParticipant: [
        'phone',
        'subjectId',
        'tags',
        'gender',
        'height',
        'weight',
      ],
      tagNamesParticipant: {
        phone: 'phone_number_mobile',
        subjectId: 'participant_id_subject',
        tags: 'type',
        gender: 'gender',
        height: 'height',
        weight: 'weight',
      },
      tagsOrderParticipant: {
        phone: 1,
        subjectId: 2,
        tags: 3,
        gender: 4,
        height: 5,
        weight: 6,
      },
      partners: [],
      partnersCopy: [],
      participantInfoCardData: {},
      disabledButton: false,
      showChangesReason: false,
      changeReason: '',
      alertTitle: '',
      inputText: this.$t('i_understand_continue'),
      alertMessage: '',
    };
  },
  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({
      extractStudyCardInfo: 'participants/extractStudyCardInfo',
    }),
  },

  watch: {
    changeReason(value) {
      this.setInfoPanelTexts(value);
      //this.$store.state.participants.changeReason;
    },
    partners: {
      handler() {
        if (isEqual(this.partners, this.partnersCopy)) {
          this.showChangesReason = false;
        } else {
          this.showChangesReason = true;
        }
      },
      deep: true,
    },
    showChangesReason: {
      handler(value) {
        value ? (this.disabledButton = true) : (this.disabledButton = false);
      },
    },
  },
  created() {
    this.testOne = true;
    this.partners = cloneDeep(this.selectedParticipant.partners);
    this.partnersCopy = cloneDeep(this.selectedParticipant.partners);
  },
  mounted() {
    this.tesTwo = true;
  },
  methods: {
    thirdPartyStatus() {
      if (isEqual(this.partners, this.partnersCopy)) {
        this.showChangesReason = false;
      } else {
        this.showChangesReason = true;
      }
    },
    checkConfirm(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },
    setInfoPanelTexts(value) {
      value === 'change'
        ? ((this.alertTitle = this.$t('change_box_schedule_header')),
          (this.alertMessage = this.$t('change_box_schedule_change')))
        : ((this.alertTitle = this.$t('change_box_info_correction_header')),
          (this.alertMessage = this.$t('change_box_info_correction_detail')));
    },
    async handleNextClick() {
      this.loading = true;
      this.componentKey += 1;
      this.$emit('nextStep', this.partners, this.changeReason);
      this.loading = false;
    },
  },
};
</script>
          
  <style scoped lang="scss">
.editing {
  color: #3331e0;
}
.info-panel {
  padding: 20px;
}
</style>
          