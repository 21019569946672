import request from '@/utils/api.utils';

/**
 * GET: fetch roles list
 */
export function fetchRoles(params) {
  return request.get('/roles', { params });
}

/**
 * POST: create role
 */
export function createRole(payload) {
  return request.post('/roles', payload);
}

/**
 * PUT: update a role
 */
export function updateRole(roleId, payload) {
  return request.put(`/roles/${roleId}`, payload);
}
