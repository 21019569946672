<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3335 3.33464C3.3335 2.41416 4.07969 1.66797 5.00016 1.66797H11.6668C11.8878 1.66797 12.0998 1.75577 12.2561 1.91205L16.4228 6.07871C16.579 6.23499 16.6668 6.44696 16.6668 6.66797V16.668C16.6668 17.5884 15.9206 18.3346 15.0002 18.3346H5.00016C4.07969 18.3346 3.3335 17.5884 3.3335 16.668V3.33464ZM14.655 6.66797L11.6668 3.67981V6.66797H14.655ZM10.0002 3.33464L5.00016 3.33464V16.668H15.0002V8.33464H10.8335C10.3733 8.33464 10.0002 7.96154 10.0002 7.5013V3.33464ZM6.66683 10.8346C6.66683 10.3744 7.03993 10.0013 7.50016 10.0013H12.5002C12.9604 10.0013 13.3335 10.3744 13.3335 10.8346C13.3335 11.2949 12.9604 11.668 12.5002 11.668H7.50016C7.03993 11.668 6.66683 11.2949 6.66683 10.8346ZM6.66683 14.168C6.66683 13.7077 7.03993 13.3346 7.50016 13.3346H12.5002C12.9604 13.3346 13.3335 13.7077 13.3335 14.168C13.3335 14.6282 12.9604 15.0013 12.5002 15.0013H7.50016C7.03993 15.0013 6.66683 14.6282 6.66683 14.168Z"
      fill="#C5D8E4"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
