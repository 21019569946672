<template>
  <div>
    <div class="card shadow mb-5">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="mb-0">{{ $t('protocol_approver') }}</h4>
          </div>
          <div class="col text-right" v-if="approvers.length === 0">
            <!-- Add Representative Modal -->
            <assign-user-form
              button="Set Protocol Approver"
              title="Add Protocol Approver"
              authLevel="protocol"
              :role-users="approvers"
              @add="handleAddRepresentative"
              code="PROTOCOL_APPROVER"
              :resourceId="protocol.basic && protocol.basic.id"
              :isEdit="isEdit"
              :clientId="
                isEdit ? selectedProtocol.study.clientId : study.clientId
              "
            />
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush"
          :thead-classes="'thead-light'"
          tbody-classes="list"
          :data="approvers"
        >
          <template slot="columns">
            <th>{{ $t('name') }}</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('email') }}</th>
            <th>{{ $t('phone') }}</th>
            <th>{{ $t('status') }}</th>
            <th></th>
          </template>

          <template slot-scope="{ row }">
            <td scope="row">
              <span class="name mb-0 text-sm">
                {{ row.firstName }} {{ row.lastName }}
              </span>
            </td>
            <td class="">
              {{ row.title }}
            </td>
            <td>
              {{ row.email }}
            </td>
            <td>
              {{ row.phone }}
            </td>
            <td>
              <app-badge
                :variant="
                  row.status.name.toLowerCase() === 'active'
                    ? 'success'
                    : 'secondary'
                "
              >
                {{
                  row.status.name.toLowerCase() === 'active'
                    ? $t('ACTIVE')
                    : $t('INACTIVE')
                }}
              </app-badge>
            </td>

            <td>
              <update-user
                v-if="isEdit"
                title="Update Protocol Approver"
                authLevel="protocol"
                :member="row"
                :clientId="selectedProtocol.study.clientId"
                ref="updateUser"
                class="mr-2"
                @updateNewUser="updateApproverData"
              />
              <i
                class="fa fa-trash cursor-pointer"
                @click="deleteRepresentative(row)"
              ></i>
            </td>
          </template>
        </base-table>
      </div>
    </div>
    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="approvers.length === 0"
          @click="handleNextClick"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AssignUserForm from '@/components/AssignUserForm';
import { mapState } from 'vuex';
import UpdateUser from '@/components/UpdateUser';

import { fetchExperts } from '@/api/experts';
import { randomString } from '@/utils';
export default {
  components: {
    AssignUserForm,
    UpdateUser,
  },
  data() {
    return {
      addingRep: false,
      approvers: [],
      experts: [],
      isEdit: this.$route.name.includes('edit-protocol'),
    };
  },
  props: {
    delete: Array,
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      selectedApprover: ({ protocols }) => protocols.selectedApprover,
      protocolParams: ({ protocols }) => protocols.protocolParams,
      protocol: ({ protocols }) => protocols.protocolForEdit,
    }),
  },
  created() {
    if (this.isEdit) this.getSelectedProtocolApprover();
  },
  methods: {
    handleNextClick() {
      this.$emit('nextStep', this.approvers);
    },
    handleAddRepresentative(user) {
      const tempId = randomString();
      this.approvers = [{ ...user, tempId }];
      this.$toast.info(this.$t('toast_protocol_approver_added'));
    },
    async updateApproverData(approver) {
      this.approvers = [
        {
          ...approver,
        },
      ];
    },
    deleteRepresentative(user) {
      // If updating the protocol, add the 'userId' to the 'delete' array
      if (this.isEdit && user?.id?.length > 0 && !user?.tempId) {
        this.delete.push(user.id);
        this.$toast.info(this.$t('toast_protocol_approver_removed'));
      }

      this.approvers = this.approvers.filter(
        (approver) => approver.id !== user.id
      );
    },
    async getSelectedProtocolApprover() {
      const validApprover =
        this.selectedApprover?.user?.email && this.selectedApprover?.expertId;

      if (validApprover) {
        const status = await this.getExpertStatus(
          this.selectedApprover.user.email,
          this.selectedApprover.expertId
        );
        this.approvers = [
          {
            ...this.selectedApprover.user,
            status,
            expertId: this.selectedApprover.expertId,
          },
        ];
      } else {
        this.approvers = [];
      }
    },
    async getExpertStatus(email, expertId) {
      const { data: experts } = await fetchExperts({
        [`filter[email]`]: email,
      });
      return experts.find((expert) => expert.id === expertId)?.user?.status;
    },
  },
};
</script>

<style></style>
