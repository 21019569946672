export function validateUrl(url) {
  return /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9._]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9_)]*(\/[-a-zA-Z0-9_)]+)*)\/?$/.test(
    url
  );
}

export function validEmail(email) {
  return (
    !!email && /^[a-zA-Z+0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
  );
}
