import Badge from '../components/Badge';
import BaseAlert from '../components/BaseAlert';
import BaseButton from '../components/BaseButton';
import BaseCheckbox from '../components/BaseCheckbox';
import BaseInput from '../components/BaseInput';
import BaseDropdown from '../components/BaseDropdown';
import BaseNav from '../components/BaseNav';
import BaseTable from '../components/BaseTable';
import BaseHeader from '../components/BaseHeader';
import Card from '../components/Card';
import StatsCard from '../components/StatsCard';
import Modal from '../components/Modal';
import StepWizard from '../components/StepWizard';

export default {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(Card.name, Card);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(Modal.name, Modal);
    Vue.component(StepWizard.name, StepWizard);
  },
};
