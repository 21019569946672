export default {
  path: 'studies',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'studies',
      component: () =>
        import(
          /* webpackChunkName: "allStudies" */ '../views/Studies/List/StudyListContainerView.vue'
        ),
    },
    {
      path: 'study',
      component: () =>
        import(/* webpackChunkName: "studyView" */ '../views/Studies/View.vue'),

      children: [
        {
          path: '',
          name: 'study-details',
          component: () =>
            import(
              /* webpackChunkName: "studyDetails" */ '../views/Studies/Details.vue'
            ),
        },
        {
          path: 'edit',
          name: 'edit-study',
          meta: { parent: 'study' },
          component: () =>
            import(
              /* webpackChunkName: "editStudy" */ '../views/Studies/Edit.vue'
            ),
        },

        {
          path: 'protocols',
          meta: {
            parent: 'study',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'study-protocols',
              meta: {
                parent: 'study',
              },
              component: () =>
                import(
                  /* webpackChunkName: "protocols" */ '../views/Protocol/List/QuickProtocolListContainerView.vue'
                ),
            },

            {
              path: 'add',
              name: 'study-create-protocol',
              meta: {
                parent: 'study',
              },
              component: () =>
                import(
                  /* webpackChunkName: "createProtocol" */ '../views/Protocol/Create.vue'
                ),
            },
            {
              path: 'protocol',
              meta: {
                parent: 'study',
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: 'study-view-protocol',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "ViewProtocol" */ '../views/Protocol/Details.vue'
                    ),
                },
                {
                  path: 'edit',
                  name: 'study-edit-protocol',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "ViewProtocol" */ '../views/Protocol/Edit.vue'
                    ),
                },
                {
                  path: 'sites',
                  name: 'study-protocol-sites',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "protocols" */ '../views/Sites/List/QuickSiteListContainerView.vue'
                    ),
                },

                {
                  path: 'participants',
                  name: 'study-protocol-participants',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "protocols" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                    ),
                },
              ],
            },
          ],
        },

        {
          path: 'sites',

          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'study-sites',
              meta: {
                parent: 'study',
              },
              component: () =>
                import(
                  /* webpackChunkName: "protocols" */ '../views/Sites/List/QuickSiteListContainerView.vue'
                ),
            },
            {
              path: 'add',
              name: 'study-create-site',
              meta: {
                parent: 'study',
              },
              component: () =>
                import(
                  /* webpackChunkName: "createSite" */ '../views/Sites/Create.vue'
                ),
            },
            {
              path: 'site',

              component: {
                render(c) {
                  return c('router-view');
                },
              },

              children: [
                {
                  path: '',
                  name: 'study-view-site',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "viewSite" */ '../views/Sites/Details.vue'
                    ),
                },
                {
                  path: 'edit',
                  name: 'study-edit-site',
                  meta: {
                    parent: 'study',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "updateSite" */ '../views/Sites/Edit.vue'
                    ),
                },
                {
                  path: 'members',
                  name: 'study-site-list-team',
                  component: () =>
                    import(
                      /* webpackChunkName: "TeamMembersList" */ '../views/TeamMember/List/TeamMemberListContainer.vue'
                    ),
                },
                {
                  path: 'participants',
                  meta: {
                    parent: 'study',
                  },
                  component: {
                    render(c) {
                      return c('router-view');
                    },
                  },

                  children: [
                    {
                      path: '',
                      name: 'study-site-participants',
                      meta: {
                        parent: 'study',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "viewParticipant" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                        ),
                    },
                    {
                      path: 'participant',
                      name: 'study-view-participant',
                      meta: {
                        parent: 'study',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "viewParticipant" */ '../views/Participants/View.vue'
                        ),
                    },

                    {
                      path: 'add',
                      name: 'study-create-participant',
                      meta: {
                        parent: 'study',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "createParticipant" */ '../views/Participants/Create.vue'
                        ),
                    },

                    {
                      path: 'view-relapse',
                      name: 'study-view-relapse',
                      meta: {
                        parent: 'study',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "viewRelapse" */ '../views/Relapses/RelapseDetailView.vue'
                        ),
                    },

                    {
                      path: 'create-relapse-schedule',
                      name: 'study-create-relapse-schedule',
                      meta: {
                        parent: 'study',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "create-relapse-schedule" */ '../views/Relapses/CreateRelapseView.vue'
                        ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'participants',
          name: 'study-participants',
          meta: {
            parent: 'study',
          },
          component: () =>
            import(
              /* webpackChunkName: "protocols" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
            ),
        },
        {
          path: 'team',
          name: 'study-list-team',

          component: () =>
            import(
              /* webpackChunkName: "TeamMembersList" */ '../views/TeamMember/List/TeamMemberListContainer.vue'
            ),
        },
      ],
    },
  ],
};
