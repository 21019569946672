<template>
  <b-spinner v-if="firstLoading" class="mt-4 mx-auto d-block" />
  <div class="mt-4" v-else>
    <sites-table
      :sitesListTableData="sites"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
    <PaginatorTableComponent
      :current-page="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :page-size="pagination.pageSize"
      @changePageEvent="handleChangePageEvent"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import listMixin from '@/mixins/listMixin';
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import { paginationDefaults } from '@/constants/pagination-constants';
import SitesTable from '@/components/Tables/SitesTable.vue';

export default {
  name: 'SitesList',
  components: { PaginatorTableComponent, SitesTable },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      sites: ({ sites }) => sites.list,
      storePagination: ({ sites }) => sites.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('sites');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('sites');
  },
  methods: {
    ...mapActions({
      fetchListData: 'sites/fetchSites',
      setSiteParams: 'sites/setSiteParams',
      setIsProtocolView: 'sites/setIsProtocolView',
    }),
    goToDetail(site) {
      this.setSiteParams({
        clientId: site.client.id,
        studyId: site.study.id,
        protocolId: site.protocol.id,
        siteId: site.id,
      });
      this.setIsProtocolView(this.isProtocolView());
      this.$emit('goToDetail');
    },
    isProtocolView() {
      return this.$route.name.includes('protocol-sites');
    },
  },
};
</script>
