<template>
  <div>
    <!--  -->
    <b-row>
      <b-col>
        <div class="card-container">
          <b-row>
            <b-col>
              <p>{{ $t('study_information') }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('phone_number_mobile')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.phone }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{
                    $t('participant_id_subject')
                  }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.subjectCode }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('type') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipant.tags === ['healthy_control']
                        ? $t('healthy_control')
                        : $t('patient')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('gender') }}</span>
                  <p class="avenir-bold">
                    {{
                      selectedParticipant.gender.name === 'gender_male'
                        ? $t('male')
                        : $t('female')
                    }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('height') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.height }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-wrap">
                <div class="item">
                  <span class="opacity-6 card-label">{{ $t('weight') }}</span>
                  <p class="avenir-bold">
                    {{ selectedParticipant.weight }}
                  </p>
                </div>
              </div></b-col
            >
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <h3
                :class="
                  partnersChanged[0].isEnabled != partnersOriginal[0].isEnabled
                    ? 'editing'
                    : 'avenir'
                "
              >
                {{ $t('devices_third_party') }}
              </h3></b-col
            >
          </b-row>
          <b-row>
            <b-col v-for="(partner, i) in partnersChanged" :key="i">
              <div>
                <p class="avenir-bold">
                  <span class="text-purple">
                    <checked-icon
                      v-if="partnersOriginal[i].isEnabled"
                      class="mr-2 opacity06"
                    />
                    <clear-icon v-else class="mr-2 opacity06"></clear-icon>
                    <arrow-short class="mr-2" />
                    <checked-icon v-if="partner.isEnabled" class="mr-2" />
                    <clear-icon v-else class="mr-2" />
                    <span
                      class="custom-name"
                      :class="!partner.isEnabled ? 'opacity-6' : 'normal'"
                      >{{ $t(partner.name) }}</span
                    >
                  </span>
                  <edit-icon
                    v-if="partnersOriginal[i].isEnabled != partner.isEnabled"
                    class="align-self-stretch ml-2"
                  ></edit-icon>
                </p>
              </div>
            </b-col>
          </b-row>
          <div v-if="changeReason">
            <b-row class="mt-3">
              <b-col>
                <h3 class="editing">
                  {{ $t('change_reason') }}
                  <edit-icon class="align-self-stretch ml-2"></edit-icon></h3
              ></b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="avenir-bold capitalize">
                  {{ $t(changeReason)
                  }}<edit-icon class="align-self-stretch ml-2"></edit-icon>
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- info panel -->
        <b-row class="info-panel">
          <b-col>
            <info-panel
              v-if="changeReason"
              class="mt-2"
              :title="alertTitle"
              :message="alertMessage"
              :inputNum="3"
              :radioText="inputText"
              @check-confirm3="checkConfirm3"
              componentKey
            ></info-panel>
            <info-panel
              v-else
              class="mt-2"
              :title="alertMessageNoChanges"
              message=""
              :backgroundColor="alertColorNoChanges"
              :inputNum="4"
              :radioText="inputText"
              @check-confirm4="checkConfirm4"
            ></info-panel>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          rounded
          data-testid="next-button"
        >
          {{ $t('back') }}
        </base-button>
        <base-button
          v-if="changeReason"
          type="primary"
          :disabled="disabledUpdateButton"
          @click="updateConsent()"
          rounded
          data-testid="next-button"
        >
          {{ $t('participant_update') }}
        </base-button>
        <base-button
          v-else
          type="primary"
          :disabled="disabledButton"
          @click="handleNextClick('exit')"
          rounded
          data-testid="next-button"
        >
          {{ $t('exit') }}
        </base-button>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';
import EditIcon from '@/components/Icons/EditIcon';
import CheckedIcon from '@/components/Icons/CheckedIcon.vue';
import ClearIcon from '@/components/Icons/ClearIcon.vue';
import ArrowShort from '@/components/Icons/ArrowShort';
import InfoPanel from '@/components/InfoPanel';
import { sendConsent } from '@/api/participantsChanges';

export default {
  components: { EditIcon, CheckedIcon, ClearIcon, ArrowShort, InfoPanel },
  mixins: [cardStudyInfoMixin],
  props: {
    partnersChanged: {
      type: Array,
    },
    changeReason: {
      type: String,
    },
  },
  data() {
    return {
      testOne: false,
      tesTwo: false,
      thirdPartyEnabled: true,
      titleStudyInfo: 'study_information',
      titleParticipantInfo: 'participant_information',
      partners: [],
      participantInfoCardData: {},
      disabledButton: true,
      disabledUpdateButton: true,
      partnersOriginal: [],
      alertTitle: this.$t('change_box_3pd_disabled'),
      inputText: this.$t('i_understand_continue'),
      alertMessage: this.$t('change_box_overview_schedule_changes'),
      alertMessageNoChanges: this.$t('change_box_no_changes'),
      alertColorNoChanges: '#778CA2',
      componentKey: 1,
    };
  },

  watch: {},
  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({
      extractStudyCardInfo: 'participants/extractStudyCardInfo',
    }),
  },

  created() {
    this.testOne = true;
    this.partners = this.selectedParticipant.partners;
    this.partnersOriginal = this.selectedParticipant.partners;
    this.componentKey += 1;
  },
  mounted() {
    this.tesTwo = true;
  },
  methods: {
    async handleNextClick(value) {
      if (value === 'exit') {
        this.$router.push({ name: 'view-participant' });
      }
    },
    checkConfirm3(event) {
      !event.target.checked
        ? (this.disabledUpdateButton = true)
        : (this.disabledUpdateButton = false);
    },
    checkConfirm4(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },
    setInfoPanelTexts() {
      this.changeReason
        ? ((this.alertTitle = this.$t('change_box_3pd_disabled')),
          (this.alertMessage = this.$t('change_box_overview_schedule_changes')))
        : ((this.alertTitle = this.$t('change_box_no_changes')),
          (this.alertMessage = this.$t('')));
    },
    updateConsent() {
      const selectedPartner = this.selectedParticipant.partners;
      const payload = {
        partners: [selectedPartner[0].partnerId],
        reason: this.changeReason,
      };
      const { clientId, studyId, protocolId, siteId, id } =
        this.selectedParticipant;
      sendConsent({
        clientId,
        studyId,
        protocolId,
        siteId,
        id,
        payload,
      })
        .then((res) => {
          res.code === 200
            ? this.$toast.info(this.$t('toast_participant_updated'))
            : this.$toast.error(this.$t(res.msg));
          this.$router.push({ name: 'view-participant' });
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.data.msg));
        });
    },
  },
};
</script>

<style scoped lang="scss">
.editing {
  color: #3331e0;
}
.capitalize {
  text-transform: capitalize;
}
.info-panel {
  padding: 20px;
}
</style>
