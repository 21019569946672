<template>
  <div class="card-container">
    <p class="mb-4">{{ $t(cardTitle) }}</p>
    <div class="d-flex flex-wrap">
      <div class="item" v-for="item in items" :key="item.name">
        <span class="opacity-6 card-label">{{ $t(item.label) }}</span>
        <p class="avenir-bold">
          {{ item.value }}
        </p>
      </div>
    </div>
  </div>
</template>
        
  <script>
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';
//import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  mixins: [cardStudyInfoMixin],
  components: {},
  props: {
    cardTitle: {
      type: String,
    },
    selectedObject: {
      type: Object,
    },
    keysList: {
      type: Array,
    },
    keyNames: {
      type: Object,
    },
    keyOrder: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {},

  methods: {
    Object() {},
  },

  created() {
    this.builtObject(
      this.selectedObject,
      this.keysList,
      this.keyNames,
      this.keyOrder
    );
  },
};
</script>
       
  <style>
.item {
  width: 33%;
}
.card-container {
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
</style>
        