<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-blue"
    id="sidenav-main"
    :class="{ 'sidebar-closed': !sidebarVisiblity }"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand text-left" to="/">
        <img
          src="../../assets/img/logo.svg"
          class="navbar-brand-img"
          alt="..."
        />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    alt="Image placeholder"
                    src="img/theme/team-1-800x800.jpg"
                  />
                </span>
              </div>
            </a>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <div class="position-absolute top-divider" />

          <slot name="links"> </slot>
          <hr class="my-3 w-100 middle-divider" />

          <slot name="links-after"> </slot>
        </ul>

        <div class="sidebar-toggle" @click="toggleSidebar">
          <img
            :src="require(`@/assets/icons/toggle.svg`)"
            class="toggle-icon"
          />
          <h6 class="ml-4">Toggle Sidebar</h6>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from '@/components/NavbarToggleButton';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: '../../assets/img/logo.svg',
      description: 'Sidebar app logo',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  data() {
    return {
      isSidebarClosed: false,
    };
  },
  computed: {
    ...mapState({
      sidebarVisiblity: (state) => state.sidebar.visible,
    }),
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'sidebar/toggle',
      showSidebar: 'sidebar/show',
      hideSidebar: 'sidebar/hide',
    }),
    toggleSidebar() {
      if (this.isSidebarClosed) {
        this.showSidebar();
      } else {
        this.hideSidebar();
      }
      this.isSidebarClosed = !this.isSidebarClosed;
    },

    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    // showSidebar() {
    //   this.$sidebar.displaySidebar(true);
    // },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    },
    handleMouseover() {
      if (!this.isSidebarClosed) return;
      this.showSidebar();
    },
    handleMouseleave() {
      if (!this.isSidebarClosed) return;
      this.hideSidebar();
    },
  },

  created() {
    this.showSidebar();
  },
};
</script>
