<template>
  <div class="main-container" :class="{ expanded: periodInfoMessage }">
    <div class="pt-3 pb-3 pl-4">
      <span class="avenir-bold">
        {{ $t('report_and_period_selection') }}
      </span>
    </div>

    <transition name="fade">
      <div v-if="periodInfoMessage" class="warning-message pt-3 pb-3 pl-3">
        <span class="d-flex align-items-center">
          <info-square-icon class="mr-2"></info-square-icon>
          {{ $t(periodInfoMessage) }}
        </span>
      </div>
    </transition>

    <b-row class="d-flex justify-content-around pl-5 pt-4 pb-6">
      <b-col
        ><v-select
          id="typeSelect"
          :options="reportOptions"
          :placeholder="this.$t('report')"
          v-model="periodReportSelections.reportData"
          class="filter-dropdown full report-dropdown"
          :clearable="true"
      /></b-col>
      <b-col>
        <v-select
          id="periodSelect"
          :options="periodOptions"
          :placeholder="this.$t('period')"
          v-model="periodReportSelections.periodData"
          label="title"
          class="filter-dropdown full period-dropdown"
          :clearable="true"
          :disabled="false"
      /></b-col>
      <b-col
        ><b-button
          variant="primary"
          class="rounded-pill"
          :disabled="disableButton"
          @click="applySelection()"
        >
          {{ $t('apply') }}
        </b-button></b-col
      >
    </b-row>
  </div>
</template>

<script>
import InfoSquareIcon from '@/components/Icons/InfoSquareIcon.vue';
import { calculateWeeksUntilDate } from '@/utils/dateTransform';
import {
  typeSelectorOptions,
  periodSelectorOptions,
  messageKeys,
} from '@/constants/type&PeriodSelectorOptions';
export default {
  name: 'ParticipantAdherenceReportTypeAndPeriodComponent',
  components: {
    InfoSquareIcon,
  },
  props: {
    firstStudyWeekDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      disableButton: true,
      periodInfoMessage: messageKeys.MESSAGE_1,
      periodReportSelections: {
        reportData: typeSelectorOptions.SUMMARY,
        periodData: periodSelectorOptions.OVERALL,
      },
      reportOptions: [
        typeSelectorOptions.SUMMARY,
        typeSelectorOptions.DETAILED,
      ],
      periodOptions: [],
      detailedVersion: false,
    };
  },
  created() {
    this.setPeriodOptions();
  },
  watch: {
    periodReportSelections: {
      handler() {
        this.checkSelections();
      },
      deep: true,
    },
  },

  methods: {
    applySelection() {
      this.$emit('selections', this.periodReportSelections);
      this.disableButton = true;
    },

    checkSelections() {
      const { reportData, periodData } = this.periodReportSelections;
      this.detailedVersion = reportData === typeSelectorOptions.DETAILED;
      this.disableButton = !(periodData && reportData);

      if (
        (this.detailedVersion &&
          (periodData === periodSelectorOptions.OVERALL ||
            periodData === periodSelectorOptions.THIS_WEEK)) ||
        (!this.detailedVersion &&
          periodData !== periodSelectorOptions.OVERALL &&
          periodData !== periodSelectorOptions.THIS_WEEK &&
          periodData !== periodSelectorOptions.FIRST_TO_LAST_WEEK)
      ) {
        this.periodReportSelections.periodData = '';
      }

      this.setPeriodOptions();
      this.setMessage();
    },

    setMessage: function () {
      const { periodData } = this.periodReportSelections;
      if (
        periodData === periodSelectorOptions.OVERALL ||
        periodData === periodSelectorOptions.THIS_WEEK
      ) {
        this.periodInfoMessage = messageKeys.MESSAGE_1;
      } else if (periodData === periodSelectorOptions.FIRST_TO_LAST_WEEK) {
        this.periodInfoMessage = messageKeys.MESSAGE_2;
      } else {
        this.periodInfoMessage = '';
      }
    },

    setPeriodOptions() {
      if (this.detailedVersion) {
        this.periodOptions = this.firstStudyWeekDate
          ? calculateWeeksUntilDate(this.firstStudyWeekDate)
          : [
              periodSelectorOptions.FIRST_TO_LAST_WEEK,
              periodSelectorOptions.LAST_WEEK,
            ];
      } else {
        this.periodOptions = [
          periodSelectorOptions.OVERALL,
          periodSelectorOptions.THIS_WEEK,
          periodSelectorOptions.FIRST_TO_LAST_WEEK,
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  border: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  height: auto;
  background: #fff;
  transition: height 0.3s ease;
}
.main-container.expanded {
  height: 250px;
}

.warning-message {
  height: 60px;
  background: #e8e9ec;
  display: flex;
  align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
