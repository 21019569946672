<template>
  <b-row>
    <b-col cols="11">
      <b-row
        :class="{ primary: borderColor === '' }"
        :style="{ backgroundColor, borderColor, color: colorText }"
        class="alert-component"
      >
        <b-col cols="2" class="d-flex justify-content-center">
          <exclamation-circle />
        </b-col>
        <b-col cols="10">
          <p class="avenir-bold">{{ title }}</p>
          <p>{{ message }}</p>
          <div class="item">
            <div class="checkbox-circle">
              <input
                v-if="inputNum === 1"
                type="checkbox"
                id="checkbox-circle1"
                name="check"
                @change="$emit('check-confirm', $event)"
              />
              <input
                v-if="inputNum === 2"
                type="checkbox"
                id="checkbox-circle2"
                name="check2"
                @change="$emit('check-confirm2', $event)"
              />
              <input
                v-if="inputNum === 3"
                type="checkbox"
                id="checkbox-circle3"
                name="check3"
                @change="$emit('check-confirm3', $event)"
              />
              <input
                v-if="inputNum === 4"
                type="checkbox"
                id="checkbox-circle4"
                name="check4"
                @change="$emit('check-confirm4', $event)"
              />
              <label v-if="inputNum === 1" for="checkbox-circle1">
                {{ radioText }}</label
              >
              <label v-if="inputNum === 2" for="checkbox-circle2">
                {{ radioText }}</label
              >
              <label v-if="inputNum === 3" for="checkbox-circle3">
                {{ radioText }}</label
              >
              <label v-if="inputNum === 4" for="checkbox-circle4">
                {{ radioText }}</label
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
  
  <script>
import ExclamationCircle from './Icons/ExclamationCircle.vue';

export default {
  name: 'InfoPanel',
  status: 'ready',

  components: { ExclamationCircle },

  props: {
    /**
     * To set the title og the info panel.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * To set the text of the radioCheck button.
     */
    radioText: {
      type: String,
      default: '',
    },
    /**
     * To set the main massage of the info panel.
     */
    message: {
      type: String,
      default: '',
    },
    /**
     * To set the text color.
     */
    colorText: {
      type: String,
      default: '#FFFFFF',
    },
    /**
     * To set the background color
     */
    backgroundColor: {
      type: String,
      default: '#046DB3',
    },
    /**
     * To set the border color color
     */
    borderColor: {
      type: String,
      default: '',
    },
    /**
     * It could be usefull if in a future we want to have the possibility of adding new icons
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * To set icon color.
     */
    iconColor: {
      type: String,
      default: '',
    },
    /**
     * By default there is one checkbox but this may cause errors when we need different functions for the same checkbox.
     * So we have the option off adding extra inputs in the component and use a different function in each one.
     */
    inputNum: {
      type: Number,
      default: 1,
    },
  },

  watch: {
    backgroundColor(value) {
      return { '--checked-color': `${value}` };
    },
  },
};
</script>
  
<style lang="scss" scoped >
.alert-component {
  border-radius: 15px;
  padding: 20px 10px;
}
/* checkbox-circle */
.checkbox-circle input[type='checkbox'] {
  display: none;
}
.checkbox-circle input[type='checkbox'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.checkbox-circle input[type='checkbox'] + label:last-child {
  margin-bottom: 0;
}
.checkbox-circle input[type='checkbox'] + label:before {
  content: '';
  display: block;
  width: 1.2em;
  height: 1.2em;
  border: 1px solid #fefefe;
  border-radius: 1em;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.2s, transform 0.3s ease-in-out;
  background: #f3f3f3;
}
.checkbox-circle input[type='checkbox']:checked + label:before {
  border-radius: 1em;
  border: 4px solid #fff;
  background: var(--checked-color);
  box-shadow: 0 0 0 1px #fdfdfd;
}
/* checkbox-circle end */
</style>
  