import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      userType: ({ auth }) => auth.userType,
    }),
  },
  watch: {
    userType: {
      handler(newValue) {
        if (newValue.length) {
          this.filtersToDisplay.client = newValue !== 'client';
          this.showListFilters = true;
        }
      },
      immediate: true,
    },
  },
};
