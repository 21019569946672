import request from '@/utils/api.utils';

/**
 * GET: fetch the list of members for a study
 */
export function fetchStudyTeamMembers(params) {
  const url = `/members?sort=-created_at`;
  return request.get(url, { params });
}

/**
 * GET: fetch the list of members for a site
 */
export function fetchSiteTeamMembers(params) {
  const url = `/members?sort=-created_at`;
  return request.get(url, { params });
}

export function fetchTeamMembers(params) {
  const url = '/members';
  return request.get(url, { params });
}

/**
 * POST: Create team member
 */
export function postTeamMember(
  { clientId, studyId, protocolId, siteId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/members`;
  return request.post(url, payload);
}

/**
 * DELETE: delete a team member
 */
export function deleteTeamMember({
  clientId,
  studyId,
  protocolId,
  siteId,
  memberId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/members/${memberId}`;
  return request.delete(url);
}

/**
 * GET: Retrieve a Site Member With Permissions
 */
export function getTeamMemberDetail({
  clientId,
  studyId,
  protocolId,
  siteId,
  memberId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/members/${memberId}`;
  return request.get(url);
}

/**
 * PUT: Update Site Member Permissions
 */
export function updateTeamMember(
  { clientId, studyId, protocolId, siteId, memberId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/members/${memberId}`;
  return request.put(url, payload);
}
