<template>
  <div>
    <el-steps
      v-if="wizardType == [wizardType]"
      :active="currentStep"
      align-center
    >
      <el-step
        v-for="(item, index) in stepNames[wizardType]"
        :key="index"
        :title="$t(`${item.name}`)"
      />
    </el-steps>
  </div>
</template>

<script>
export default {
  name: 'step-wizard',
  props: {
    /**
     * The current step of the wizard
     */
    currentStep: {
      type: Number,
      default: 0,
    },
    /**
     * This property allow us to show the different types of wizard. We have seven types (same component but different data),
     * that are related to the ata stepNames  ( clients, studies, protocols, participants, releases, sites, userManagement )
     */
    wizardType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      stepNames: {
        clients: [
          { name: 'client_information' },
          { name: 'client_representatives' },
          { name: 'client_address_official_contact' },
          { name: 'client_address_invoice' },
          { name: 'overview' },
        ],
        createParticipants: [
          { name: 'personal_information' },
          { name: 'schedule_tailor' },
          { name: 'overview' },
        ],
        updateParticipants: [
          { name: 'personal_information' },
          { name: 'overview' },
        ],
        updateParticipantDraft: [
          { name: 'personal_information' },
          { name: 'schedule_tailor' },
          { name: 'overview' },
        ],
        tailorSchedule: [
          { name: 'personal_information' },
          { name: 'schedule_tailor' },
          { name: 'overview' },
        ],
        changeConsent: [{ name: 'personal_information' }, { name: 'overview' }],
        protocol: [
          { name: 'protocol_information' },
          { name: 'protocol_approver' },
          { name: 'protocol_schedule' },
          { name: 'protocol_integrations' },
          { name: 'overview' },
        ],
        sites: [
          { name: 'site_information' },
          { name: 'site_address' },
          { name: 'overview' },
        ],
        studies: [
          { name: 'study_information' },
          { name: 'study_coordinators' },
          { name: 'study_features' },
          { name: 'study_tasks' },
          { name: 'overview' },
        ],
        relapses: [
          { name: 'schedule_information' },
          { name: 'tests_games' },
          { name: 'overview' },
        ],
        userManagement: [
          { name: 'role_information' },
          { name: 'permissions' },
          { name: 'overview' },
        ],
      },
    };
  },
};
</script>

<style></style>
