import { fetchExpertNotifications } from '@/api/notifications';
import { notificationsDiff } from '../../utils';

const initialState = {
  list: [],
  newNotifications: false,
};

const state = { ...initialState };

const getters = {
  LatestNotifications: ({ list }) => {
    return list.slice(0, 10);
  },
};

const mutations = {
  SET_NOTIFICATIONS_LIST: (state, notifications) => {
    if (
      notifications.length &&
      notifications.filter((noti) => !noti.readAt).length > 0
    ) {
      if (notificationsDiff(notifications, state.list)) {
        state.newNotifications = true;
      }
    } else {
      state.newNotifications = false;
    }
    state.list = notifications;
  },
  SET_NOTIFICATIONS_STATUS: (state) => {
    state.newNotifications = false;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchExpertNotifications({ commit }, expertId) {
    try {
      const notifications = await fetchExpertNotifications(expertId);
      commit('SET_NOTIFICATIONS_LIST', notifications.data);
    } catch (error) {
      console.log(error);
    }
  },

  setNotificationsStatus({ commit }) {
    commit('SET_NOTIFICATIONS_STATUS');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
