<template>
  <div class="pt-5 px-5">
    <div>
      <h4 class="mb-3">Select an option text</h4>
      <b-form-select
        v-model="selected"
        :options="tablesList"
        @change="setTableData($event)"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >Please select an option</b-form-select-option
          >
        </template>
      </b-form-select>
      <div v-if="showActionFilter">
        <h4 class="mb-3 mt-3">Select action text</h4>
        <b-form-select
          v-model="selectedActions"
          :options="actionSelectorData"
          @change="setFilterAction($event)"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Filter Action</b-form-select-option
            >
          </template></b-form-select
        >
      </div>
      <div class="mt-3" v-if="selected">
        <h2>
          <strong>{{ selected }} Table</strong>
        </h2>
      </div>
    </div>
    <b-spinner class="mx-auto d-block" v-if="loading"></b-spinner>
    <div v-else>
      <v-client-table
        :data="tableData"
        :columns="columns"
        :options="options"
        class="audit-table"
      >
        <template slot="created_at" slot-scope="props">
          {{ moment.unix(props.row.created_at).format('DD/MM/YYYY') }}
        </template>
        <template slot="request" slot-scope="props">
          <json-viewer
            :value="props.row.request"
            :expand-depth="0"
          ></json-viewer>
        </template>
      </v-client-table>
      <div v-if="selected">
        <b-pagination
          v-model="startPage"
          :total-rows="rows"
          :perPage="this.limit"
          class="mt-4"
          aria-controls="my-table"
          align="center"
          @input="updateTable(startPage)"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getTablePage, getTablesName, getTableActions } from '@/api/logs';

export default {
  data() {
    return {
      sortBy: 'created_at',
      sortDesc: false,
      fields: [],
      startPage: 1,
      currentPage: 1,
      limit: 5,
      offset: 0,
      rows: 0,
      tableData: [],
      selected: null,
      selectedActions: null,
      tablesList: [],
      jsonData: {},
      showActionFilter: false,
      tableName: '',
      actionSelectorData: [],
      actionFilter: '',
      columns: [],
      loading: false,
      options: {
        headings: {
          actions: 'act',
        },
        sortable: ['created_at'],
        // filterable: ['actions'],
        orderBy: {
          column: 'created_at',
          ascending: false,
        },
        perPage: 9999,
      },
    };
  },

  resetPagination() {
    //this.offset = 1;
  },

  created() {
    this.setBreadCrumbData({
      'audit-logs': [
        {
          text: this.$t('Audit Logs Demo'),
          active: true,
        },
      ],
    }),
      this.getSelectorTables();
  },
  computed: {
    hasCancelListener() {
      return this.$listeners && this.$listeners.change;
    },
  },

  methods: {
    async getSelectorTables() {
      try {
        const { data } = await getTablesName();
        this.tablesList = data;
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      }
    },

    setTableData(event) {
      this.offset = 0;
      this.startPage = 1;
      const tableName = event;
      this.tableName = tableName;
      this.actionFilter = '';
      this.getTableData();
    },

    setFilterAction(event) {
      this.offset = 0;
      this.startPage = 1;
      const filter = event;
      this.actionFilter = filter;
      this.getTableData();
    },
    async getTableData() {
      let payload = {};
      if (this.actionFilter) {
        let payloadFilter = {
          tableName: this.tableName,
          limit: this.limit,
          offset: this.offset,
          filter: { action: this.actionFilter },
        };
        payload = { ...payloadFilter };
      } else {
        let payloadDefault = {
          tableName: this.tableName,
          limit: this.limit,
          offset: this.offset,
        };
        payload = { ...payloadDefault };
      }
      try {
        this.loading = true;
        const { data } = await getTablePage(payload);
        this.rows = data.total;
        this.getFieldsNames(data);
        this.transformData(data);
        this.showActionSelector(data.items);
        this.loading = false;
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      }
    },

    transformData(data) {
      let dataParticipantsTable = data.items.map((item) => {
        return this.transformRow(item);
      });
      const TransData = dataParticipantsTable;
      this.tableData = TransData;
    },

    transformRow(item) {
      return {
        ...item,
      };
    },

    showActionSelector(items) {
      const hasKey = !!items[0]['action'];
      hasKey ? this.getSelectableActions() : (this.showActionFilter = false);
    },

    async getSelectableActions() {
      this.showActionFilter = true;
      try {
        const { data } = await getTableActions(this.selected);
        this.actionSelectorData = data;
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      }
    },

    getFieldsNames(data) {
      const fieldNames = Object.keys(data.items[0]);
      this.columns = fieldNames;
    },

    async getFilterAction(event) {
      this.actionFilter = event;
      try {
        const { data } = await getTablePage({
          tableName: this.tableName,
          limit: this.limit,
          offset: this.offset,
          filter: { action: this.actionFilter },
        });
        this.rows = data.total;
        this.transformData(data);
        this.showActionSelector(data.items);
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      }
    },

    updateTable(num) {
      let pageRows = this.limit * (num - 1);
      this.offset = pageRows;
      this.getTableData();
    },
  },
};
</script>
<style scoped lang="scss">
.VueTables::v-deep {
  .table.app-table tr {
    cursor: pointer;
  }
  .table.app-table td {
    max-width: fit-content !important;
    font-size: 14px;
    color: grey;
  }
  thead {
    font-size: 22px;
    background: red !important;
  }
}
.jv-container::v-deep .jv-code {
  padding: 2px !important;
}
</style>
