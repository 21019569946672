<template>
  <b-modal
    :visible="showParticipantModal"
    :static="true"
    @change="handleModalChange"
    class="add-participant-modal"
    hide-header
    no-close-on-backdrop
    size="xl"
  >
    <div class="border-bottom pb-3 text-left text-lg font-weight-900">
      {{ $t('participant_add_modal_header') }}
    </div>

    <ListFiltersComponent
      v-if="showParticipantModal"
      ref="listFilters"
      :filtersToDisplay="filtersToDisplay"
      :vertical="true"
      @changeFilterEvent="handleChangeFilterEvent"
    />

    <template slot="modal-footer">
      <b-row class="my-4">
        <base-button type="secondary" @click="handleModalChange"
          >{{ $t('cancel') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="!filter.siteId"
          @click="addParticipant()"
          >{{ $t('participant_add') }}</base-button
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { getProtocolDetail } from '@/api/protocol';
import { mapState } from 'vuex';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import isClientUserMixin from '@/mixins/isClientUserMixin';

export default {
  components: { ListFiltersComponent },
  mixins: [isClientUserMixin],
  data() {
    return {
      filtersToDisplay: {
        client: false,
        study: true,
        protocol: true,
        site: true,
      },
      filter: {},
      showListFilters: false,
    };
  },
  computed: {
    ...mapState({
      showParticipantModal: (state) => state.participants.showParticipantModal,
      clients: ({ listFilters }) => listFilters.clients,
      sites: ({ listFilters }) => listFilters.sites,
    }),
  },
  methods: {
    async addParticipant() {
      const { siteId, protocolId, studyId, clientId } = this.filter;
      // temporary: sites are not returning currently the client name
      const clientName = this.getClientNameFromId(clientId);
      const { siteName, protocolName, studyName } =
        this.getSiteDataFromId(siteId);
      const payload = {
        siteId,
        siteName,
        protocolId,
        protocolName,
        studyId,
        studyName,
        clientId,
        clientName,
      };

      this.$store.commit('participants/SET_ASSOCIATION', payload);
      this.$router.push({ name: 'add-participant' });
      this.handleModalChange();

      await this.setSiteProtocol();
    },
    async setSiteProtocol() {
      const { protocolId, studyId, clientId } = this.filter;
      const payload = {
        clientId,
        studyId,
        protocolId,
      };
      try {
        const { data } = await getProtocolDetail(payload);
        this.$store.commit('sites/SET_SITE_PROTOCOL', { ...data });
      } catch (error) {
        this.displayError('Error set site protocol', error);
      }
    },
    handleModalChange() {
      this.$store.commit('participants/SET_TOGGLE_PARTICIPANT_MODAL');
    },
    handleChangeFilterEvent(newFilter) {
      this.filter = { ...newFilter };
    },
    getSiteDataFromId(siteId) {
      const siteData = this.sites.find((site) => site.id === siteId);
      return siteData
        ? {
            studyName: siteData.study.name,
            protocolName: siteData.protocol.name,
            siteName: siteData.name,
          }
        : {};
    },
    getClientNameFromId(clientId) {
      const clientData = this.clients.find((client) => client.id === clientId);
      return clientData ? clientData.legalName : '';
    },
  },
};
</script>

<style lang="scss">
.add-participant-modal {
  .modal-dialog {
    max-width: 1000px !important;
  }
  .modal-footer {
    justify-content: center;
  }
  .filter-dropdown {
    margin: 1rem 0;
  }
}
</style>
