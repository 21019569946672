import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './modules/auth';
import experts from './modules/experts';
import resources from './modules/resources';
import clients from './modules/clients';
import studies from './modules/studies';
import sites from './modules/sites';
import protocols from './modules/protocols';
import members from './modules/members';
import participants from './modules/participants';
import roles from './modules/roles';
import sidebar from './modules/sidebar';
import breadcrumb from './modules/breadcrumb';
import notifications from './modules/notifications';
import relapses from './modules/relapses';
import participantsChanges from './modules/participantChanges';
import modals from './modules/modals';
import listFilters from './modules/listFilters';

Vue.use(Vuex);

const modules = {
  auth,
  experts,
  resources,
  clients,
  studies,
  sites,
  protocols,
  members,
  participants,
  roles,
  sidebar,
  breadcrumb,
  notifications,
  relapses,
  participantsChanges,
  modals,
  listFilters,
};

const getters = {
  token: (state) => state.auth.token,
};

const actions = {
  reset: ({ commit }) => {
    Object.keys(modules).forEach((module) => {
      commit(`${module}/RESET`);
    });
  },
};

const vuexLocal = new VuexPersistence({
  reducer: (state) => {
    const reducedState = {};
    Object.keys(modules).forEach((module) => {
      /* eslint-disable */
      const {
        all,
        list,
        pagination,
        clients,
        studies,
        protocols,
        sites,
        ...rest
      } = state[module];
      /* eslint-enable */
      reducedState[module] = { ...rest };
    });
    return reducedState;
  },
});

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocal.plugin],
  modules,
  getters,
  actions,
});

export default store;
