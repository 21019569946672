export default {
  path: 'reports',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'adherence-reports',
      name: 'adherence-reports',
      component: () =>
        import(
          /* webpackChunkName: "allStudies" */ '../views/Reports/ParticipantAdherenceReportView.vue'
        ),
    },
  ],
};
