export const studyListDataTableObjectMapper = (studies) => {
  return studies.map((study) => ({
    id: study.id,
    name: study.name,
    createdAt: study.createdAt,
    statusName: study.status.slug,
    clientName: study.client.name,
    clientId: study.client.id,
  }));
};
