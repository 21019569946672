<template>
  <svg
    :class="colorClass"
    width="12"
    height="11"
    viewBox="0 0 12 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2516 0.259884C11.0959 0.10384 10.8845 0.0161458 10.6641 0.0161458C10.4436 0.0161458 10.2323 0.10384 10.0766 0.259884L6.00156 4.32655L1.92656 0.251551C1.77087 0.0955066 1.55949 0.0078125 1.33906 0.0078125C1.11863 0.0078125 0.907255 0.0955066 0.751563 0.251551C0.426562 0.57655 0.426562 1.10155 0.751563 1.42655L4.82656 5.50155L0.751563 9.57655C0.426562 9.90155 0.426562 10.4266 0.751563 10.7516C1.07656 11.0766 1.60156 11.0766 1.92656 10.7516L6.00156 6.67655L10.0766 10.7516C10.4016 11.0766 10.9266 11.0766 11.2516 10.7516C11.5766 10.4266 11.5766 9.90155 11.2516 9.57655L7.17656 5.50155L11.2516 1.42655C11.5682 1.10988 11.5682 0.576551 11.2516 0.259884Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    colorClass: {
      type: String,
      default: 'red',
    },
  },
};
</script>

<style lang="scss">
$red-color: #f35656;

.red {
  & path {
    fill: #f35656;
  }
}
</style>
