<template>
  <b-breadcrumb :items="crumbs" class="m-0 p-0 bg-transparent" />
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AppBreadCrumbs',
  computed: {
    ...mapState({
      crumbs: (state) => state.breadcrumb.crumbs,
    }),
  },
};
</script>

<style scoped lang="scss">
.breadcrumb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
li {
  a {
    font-size: 18px;
    line-height: 170%;
    font-weight: 500 !important;
    color: #565f77 !important;
  }
}

li.active {
  opacity: 1;
  span {
    font-weight: bold !important;
    font-size: 18px !important;
    color: #565f77 !important;
    line-height: 170%;
    font-family: Avenir-Bold, Helvetica, Arial !important;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding: 0px 0.5rem 0 0.3rem;
  color: #046db3;
  font-weight: normal !important;
  font-size: 22px !important;
  content: '>';
}
</style>
