<template>
  <div>
    <h2>{{ $t('protocol_integrations') }}</h2>
    <p>
      {{ $t('study_features_info') }}
    </p>
    <hr />
    <b-row>
      <b-col>
        <h2>{{ $t('mobile_platforms') }}</h2>
        <b-form-checkbox v-model="googleFit" class="protocol-ul mb-4">
          {{ $t('data_source_google_fit') }}
        </b-form-checkbox>
        <div
          v-for="item in externals[0].dataSources[0].description"
          :key="item.description"
        >
          <ul class="mb-4">
            <li>{{ $t(`${item}`) }}</li>
          </ul>
        </div>

        <b-form-checkbox v-model="appleHealth" class="protocol-ul mb-4">
          {{ $t('data_source_apple_health') }}
        </b-form-checkbox>
        <div
          v-for="item in externals[0].dataSources[1].description"
          :key="item.description"
        >
          <ul class="list-group-content">
            <li>{{ $t(`${item}`) }}</li>
          </ul>
        </div>
      </b-col>

      <b-col>
        <h2>{{ $t('external_devices') }}</h2>
        <template v-for="source in externals[1].dataSources">
          <b-form-checkbox
            v-model="fitbit"
            :key="source.id"
            class="protocol-ul mb-4"
            >{{ $t('data_source_fitbit') }}</b-form-checkbox
          >
          <div v-for="item in source.description" :key="item.description">
            <ul>
              <li>{{ $t(`${item}`) }}</li>
            </ul>
          </div>
        </template>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="handleNextClick"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      numberOfWeeks: 5,
      googleFit: false,
      appleHealth: false,
      fitbit: false,
      isEdit: this.$route.name.includes('edit-protocol'),
    };
  },
  created() {
    if (this.isEdit) {
      this.selectedProtocol.googleFit &&
        (this.googleFit = this.selectedProtocol.googleFit);
      this.selectedProtocol.appleHealth &&
        (this.appleHealth = this.selectedProtocol.appleHealth);
      this.selectedProtocol.fitbit &&
        (this.fitbit = this.selectedProtocol.fitbit);
    }
  },
  computed: {
    ...mapState({
      selectedProtocol: (state) => state.protocols.protocolForEdit,
      externals: (state) => state.resources.dataSources,
    }),
  },
  methods: {
    handleNextClick() {
      const { googleFit, appleHealth, fitbit } = this;
      this.$emit('nextStep', { googleFit, appleHealth, fitbit });
    },
  },
};
</script>

<style scoped lang="scss">
.protocol-ul::v-deep .custom-control-label {
  font-size: 16px !important;
}
</style>
