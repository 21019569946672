import {
  fetchRoles,
  fetchResources,
  fetchPermissions,
  fetchTests,
  fetchRoleTypes,
  fetchPartnersList,
} from '@/api/resources';

const initialState = {
  countries: [],
  clientTypes: [],
  roles: [],
  permissions: [],
  tests: [],
  roleTypes: [],
  dataSources: [],
  partners: [],
};
const state = { ...initialState };

const getters = {
  movements: (state) => {
    let arr = [];
    state.tests.forEach((test) => {
      test.categories.forEach((category) => {
        if (category.name === 'Movement') {
          arr.push(...category.tests);
        }
      });
    });
    return arr;
  },
  surveys: (state) => {
    let arr = [];
    state.tests.forEach((test) => {
      test.categories.forEach((category) => {
        if (category.name === 'Survey') {
          arr.push(...category.tests);
        }
      });
    });
    return arr;
  },

  extractPartners: (state) => {
    let partners = state.partners.filter((partner) => {
      return partner.id > 2;
    });
    return partners;
  },
};

const mutations = {
  SET_RESOURCES: (state, resources) => {
    state.countries = resources.countries;
    state.clientTypes = resources.clientTypes;
    state.dataSources = resources.dataSources;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_TEST_GAMES: (state, tests) => {
    state.tests = tests;
  },
  SET_ROLE_TYPES: (state, types) => {
    state.roleTypes = types;
  },
  SET_PARTNERS: (state, partners) => {
    state.partners = partners;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchResources({ commit }) {
    try {
      const resources = await fetchResources();
      commit('SET_RESOURCES', resources.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dispatchFetchPermissions({ commit }, isClient) {
    try {
      const res = await fetchPermissions(isClient);
      commit('SET_PERMISSIONS', res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dispatchFetchRoles({ commit }, params) {
    try {
      const res = await fetchRoles(params);
      commit('SET_ROLES', res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dispatchTestGames({ commit }, payload) {
    return new Promise((resolve, reject) => {
      fetchTests(payload)
        .then((response) => {
          commit('SET_TEST_GAMES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async dispatchFetchRoleTypes({ commit }) {
    try {
      const { data } = await fetchRoleTypes();
      commit('SET_ROLE_TYPES', data);
    } catch (error) {
      console.log(error);
    }
  },

  async dispatchFetchPartners({ commit }) {
    try {
      const { data } = await fetchPartnersList();
      commit('SET_PARTNERS', data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
