export const columns = [
  'participant_id',
  'subject_id',
  'total_scheduled',
  'total_completed',
  'total_not_completed',
  'total_completed_percent',
];

export const columnsDetailed = [
  'participant_id',
  'gender',
  'baseline_date',
  'study_start_date',
  'study_end_date',
  'total_scheduled',
  'total_completed',
  'total_completed_percent',
  'dreaMS_scheduled',
  'dreaMS_completed',
  'dreaMS_completed_percent',
  'Roche - Floodlight_scheduled',
  'Roche - Floodlight_completed',
  'Roche - Floodlight_completed_percent',
  'movement_scheduled',
  'movement_completed',
  'movement_completed_percent',
  'dexterity_scheduled',
  'dexterity_completed',
  'dexterity_completed_percent',
  'balance_scheduled',
  'balance_completed',
  'balance_completed_percent',
  'cognitive_scheduled',
  'cognitive_completed',
  'cognitive_completed_percent',
  'vision_scheduled',
  'vision_completed',
  'vision_completed_percent',
  'survey_scheduled',
  'survey_completed',
  'survey_completed_percent',
  'relapse_reported',
];
