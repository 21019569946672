import { fetchRoles } from '@/api/roles';

const initialState = {
  list: [],
  selectedRole: {},
};
const state = { ...initialState };

const getters = {
  displayedRoleFields: ({ selectedRole }) => {
    return {
      id: selectedRole.id,
      name: selectedRole.name,
      description: selectedRole.description || null,
      roleType: selectedRole.roleType.name,
      authLevel: selectedRole.authLevel.displayName,
      permissions: selectedRole.permissions.map((permission) => ({
        id: permission.permission.id,
        name: permission.permission.name,
      })),
    };
  },
};

const mutations = {
  SET_ROLES_LIST: (state, roles) => {
    state.list = roles;
  },
  SET_SELECTED_ROLE: (state, role) => {
    state.selectedRole = role;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchRoles({ commit }) {
    try {
      const resources = await fetchRoles();
      commit('SET_ROLES_LIST', resources.data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
