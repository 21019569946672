<template>
  <div class="tabs-container">
    <ul class="tabs-list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tabs-container {
  background-color: white;
  display: inline-block;
  border-radius: 50rem;
  .tabs-list {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    min-height: 45px;
  }
}
</style>
