import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions({
      setBreadcrumb: 'breadcrumb/set',
    }),

    setBreadCrumbData(data) {
      this.setBreadcrumb(data[this.$route.name]);
    },
  },
};
