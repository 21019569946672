export default {
  methods: {
    displayError(message, error) {
      let toastMessage = 'error_something_went_wrong';

      if (error?.message) {
        toastMessage = error.message;
      } else if (error?.data?.message) {
        toastMessage = error.data.message;
      } else if (error?.data?.msg) {
        toastMessage = error.data.msg;
      }

      this.$toast.error(this.$t(toastMessage));

      console.error(message, error);
    },
  },
};
