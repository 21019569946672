<template>
  <div>
    <i class="fas fa-question-circle" @click="show = true"></i>
    <modal :show="show">
      <div class="border-bottom pb-3 text-lg avenir-bold font-weight-600">
        {{ $t('contact_support') }}
      </div>
      <label class="text-sm" for="">Subject</label>
      <b-form-input
        v-model="subject"
        :placeholder="$t('contact_support_subject_tip')"
        maxLength="99"
      ></b-form-input>
      <label class="text-sm" for="">{{ $t('description') }}</label>
      <b-form-textarea
        id="textarea"
        v-model="message"
        :placeholder="$t('description_tip')"
        rows="3"
        maxLength="999"
      ></b-form-textarea>
      <div class="mt-5">
        <base-button type="secondary" @click="show = false" :disabled="loading"
          >{{ $t('cancel') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="loading || !isValid"
          @click="handleSubmit"
          >{{ $t('submit') }}
          <b-spinner small v-if="loading"></b-spinner>
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { postTicket } from '@/api/support';

export default {
  data() {
    return {
      message: '',
      show: false,
      loading: false,
      subject: '',
    };
  },
  computed: {
    isValid() {
      return this.message && this.subject;
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      const { firstName, lastName } = this.$store.state.auth;
      const email = localStorage.getItem('user_email');
      // TODO: Resolve the CORS solution for 201
      try {
        await postTicket({
          ticket: {
            subject: this.subject,
            comment: {
              body: this.message,
            },
            requester: {
              name: `${firstName} ${lastName}`,
              email,
            },
          },
        });
      } finally {
        this.$toast.info('Ticket created successfully.');
        this.subject = '';
        this.message = '';
        this.show = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
