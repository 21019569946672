<template>
  <div class="p-5">
    <TeamMemberList
      ref="teamMemberList"
      :filterData="filterMembersData"
      @deleteTeamMember="confirmDeleteTeamMember"
    />

    <!-- Delete Member Confirmation Modal -->
    <modal :show.sync="showDeleteConfirmationModal">
      <template #header> Remove Member </template>
      <div class="text-center">Are you sure you want to remove?</div>
      <template v-slot:footer>
        <base-button
          type="secondary"
          @click="showDeleteConfirmationModal = false"
          :disabled="loading"
          >{{ $t('no') }}</base-button
        >
        <base-button
          type="primary"
          @click="deleteTeamMember"
          :disabled="loading"
          >{{ $t('yes') }}</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import TeamMemberList from '@/components/TeamMembers/List/TeamMemberList.vue';
import { mapState } from 'vuex';
import { deleteTeamMember } from '@/api/members';

export default {
  name: 'TeamMemberListContainer',
  components: { TeamMemberList },
  computed: {
    ...mapState({
      members: ({ members }) => members.list,
      study: ({ studies }) => studies.selectedStudy,
      site: ({ sites }) => sites.selectedSite,
      siteParams: ({ sites }) => sites.siteParams,
    }),
    filterMembersData() {
      return this.isSiteView
        ? { siteId: this.site.basic.id }
        : { studyId: this.study.id };
    },
  },
  data() {
    return {
      loading: false,
      isSiteView: this.$route.name.includes('site-list-team'),
      showDeleteConfirmationModal: false,
      memberToDelete: {},
      selectedClient: (state) => state.clients.selectedClient,
    };
  },
  created() {
    this.setBreadCrumbData({
      'client-list-team': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study.name,
          active: true,
        },
      ],
      'study-list-team': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
      ],
      'study-site-list-team': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.site.basic?.name,
          active: true,
        },
      ],
      'site-list-team': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.site.basic?.name,
          active: true,
        },
      ],
    });
  },
  methods: {
    confirmDeleteTeamMember(member) {
      this.showDeleteConfirmationModal = true;
      this.memberToDelete = member;
    },
    async deleteTeamMember() {
      this.loading = true;
      const payload = {
        ...this.siteParams,
        memberId: this.memberToDelete.id,
      };
      try {
        await deleteTeamMember(payload);
        this.$refs.teamMemberList.getTeamMemberList();
        this.$toast.info(this.$t('toast_team_member_removed'));
        this.showDeleteConfirmationModal = false;
      } catch (error) {
        this.displayError('Error delete team member', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
