import request from '@/utils/api.utils';

/**
 * GET: Audit log all data
 */
export function getLogsData({ limit, offset }) {
  const url = `/audit-logs?limit=${limit}&offset=${offset}`;
  return request.get(url);
}

/**
 * GET: Selectable tables for audit logs
 */

export function getTablesName() {
  const url = '/audit-logs/tables';
  return request.get(url);
}

/**
 * GET: Selected table data
 */
export function getSelectedTableData({ limit, offset, event }) {
  const url = `/audit-logs/table/${event}?limit=${limit}&offset=${offset}`;
  return request.get(url);
}

/**
 * POST: Get table page with given parameters
 */
export function getTablePage({
  tableName = '',
  limit = 5,
  offset = 0,
  filter = {},
  sortBy = { created_at: -1 },
}) {
  const url = `/audit-logs/table/${tableName}`;
  return request.post(url, {
    offset,
    limit,
    filter,
    sortBy,
  });
}

/**
 * GET: Selected actions data
 */
export function getTableActions(tableName) {
  const url = `/audit-logs/table/${tableName}/action`;
  return request.get(url);
}
