import request from '@/utils/api.utils';

/**
 * Get: fetch participant relapses list
 */
export function fetchParticipantRelapses(params) {
  const url = `/relapses?sort=-created_at`;
  return request.get(url, { params });
}

/**
 * GET: Retrieve a participant relapse
 */
export function getParticipantRelapse({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  relapseId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapses/${relapseId}`;
  return request.get(url);
}

/**
 * Get: fetch Participant Relapse Schedule Statistics
 */
export function fetchRelapseScheduleStatistics({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  dateStart,
  dateEnd,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapse-schedule-statistics?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  return request.get(url);
}

/**
 * PUT: Activate a relapse schedule
 */
export function activateRelapseSchedule({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  relapseId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapses/${relapseId}/schedule`;
  return request.put(url);
}

/**
 * DELETE: Delete a relapse schedule
 */
export function deleteRelapseSchedule({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  relapseId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapses/${relapseId}/schedule`;
  return request.delete(url);
}

/**
 * POST: Creates a relapse schedule, adding general information
 */
export function postRelapseSchedule(
  { clientId, studyId, protocolId, siteId, participantId, relapseId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapses/${relapseId}/schedule`;
  return request.post(url, payload);
}
