<template>
  <v-client-table
    :data="sitesListTableData"
    :columns="columns"
    :options="options"
    :class="[{ 'mt-4': !isDashboardView }, tableClass]"
    ref="sitesTable"
    @sorted="handleSorted"
  >
    <template slot="name" slot-scope="props">
      <span>{{ props.row.name }}</span>
      <span
        v-if="showHighlightTag && props.row.id === $route.params.siteId"
        class="highlight-tag"
        >{{ highlightTagText }}</span
      >
    </template>
    <template slot="protocol" slot-scope="props">
      {{ props.row.protocol.name }}
    </template>
    <template slot="createdAt" slot-scope="props">
      {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
    </template>
    <template slot="status.name" slot-scope="props">
      {{ props.row.status.name.toLowerCase() }}
    </template>
    <template slot="actions" slot-scope="props">
      <div class="cursor-pointer text-right" @click="goToDetail(props.row)">
        <span class="view-details">{{ $t('view') }}</span>
        <i class="fas fa-chevron-right ml-2"></i>
      </div>
    </template>
  </v-client-table>
</template>

<script>
import listHighLightMixin from '@/mixins/listHighLightMixin';

export default {
  props: {
    sitesListTableData: Array,
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [listHighLightMixin],
  data() {
    return {
      isDashboardView: this.$route.name === 'dashboard',
      columns: [
        'name',
        'protocol',
        'createdAt',
        'country.name',
        'status.name',
        'actions',
      ],
      options: {
        headings: {
          name: this.$t('name'),
          protocol: this.$t('protocol'),
          createdAt: this.$t('date_creation'),
          'country.name': this.$t('country'),
          'status.name': this.$t('status'),
          actions: '',
        },
        sortable: ['createdAt', 'name'],
        orderBy: {
          column: 'createdAt',
          ascending: false,
        },
        perPage: 9999,
      },
    };
  },
  methods: {
    goToDetail(row) {
      if (!this.isLoading) {
        this.$emit('goToDetail', row);
      }
    },
    handleSorted({ column, ascending }) {
      this.$refs.sitesTable.setOrder(column, ascending);
      this.$emit('sorting', column, ascending);
    },
  },
};
</script>
