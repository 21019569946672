import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

/**
 * fetch all resources
 */
export function fetchResources() {
  const url = `/lists/all`;
  return request.get(url);
}

/**
 * GET: fetch roles list
 */
export function fetchRoles(params) {
  const url = `/roles`;
  return request.get(url, { params });
}

/**
 * GET: fetch permissions list
 */
export function fetchPermissions(isClient) {
  const url = isClient
    ? `/lists/permissions?filter[is_client_allowed]=true`
    : `/lists/permissions`;
  return request.get(url);
}

/**
 *
 * GET: fetch auth levels
 */
export function fetchAuthLevels(authLevelType) {
  const url = `/lists/auth_levels?filter[auth_level_type]=${authLevelType}`;
  return request.get(url);
}

/**
 *
 * GET: fetch role types list
 */
export function fetchRoleTypes() {
  const url = `/lists/role_types`;
  return request.get(url);
}

/**
 * GET: Get Client Types
 */
export function fetchClientTypes() {
  const url = `/lists/client_types`;
  return request.get(url);
}

/**
 * GET: Returns a list of static Test & Games list
 * lists/tests?filter[protocol]={protocolId}&filter[game_mode]=true
 */
export function fetchTests(params) {
  const url = `/lists/tests`;
  return request.get(url, { params });
}

/**
 * GET: Returns a list of static Test & Games list
 */
export function fetchDataSources(params) {
  const url = `/lists/data_sources`;
  return request.get(url, { params });
}

/**
 * GET: Returns a list of partners
 */
export function fetchPartnersList() {
  const url = `/lists/partners`;
  return request.get(url);
}

export function fetchLanguages() {
  const url = `/lists/languages`;
  return request.get(url);
}

export function getReportData(
  limit,
  offset,
  sort,
  isDetailed,
  monday,
  sunday,
  clientId,
  studyId,
  protocolId,
  siteId,
  fileType
) {
  let url = `adherence-report?limit=${limit}&offset=${offset}&sort=${sort}&isDetailed=${isDetailed}`;
  if (monday && sunday) {
    url += `&from=${monday}&to=${sunday}`;
  }
  if (clientId && studyId && protocolId && siteId) {
    url += `&clientId=${clientId}&studyId=${studyId}&protocolId=${protocolId}&siteId=${siteId}`;
  }
  if (fileType) {
    url += `&view=csv`;
  }
  return request.get(url);
}
export function getAdherenceChartData(clientId, studyId, protocolId, siteId) {
  let url = `adherence-report/statistics`;
  if (clientId && studyId && protocolId && siteId) {
    url += `?&clientId=${clientId}&studyId=${studyId}&protocolId=${protocolId}&siteId=${siteId}`;
  }
  return request.get(url);
}

export function getFeaturesConfig() {
  const url = `/lists/widgets`;
  return request.get(url);
}

/**
 * GET: Returns a list of tasks
 */
export function getTasksList() {
  const url = `/tests`;
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { headers });
}
