<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9948 40.3359C27.7503 40.3359 28.3841 40.0799 28.8961 39.5679C29.4063 39.0577 29.6615 38.4248 29.6615 37.6693V26.9359C29.6615 26.1804 29.4063 25.5582 28.8961 25.0693C28.3841 24.5804 27.7503 24.3359 26.9948 24.3359C26.2392 24.3359 25.6063 24.591 25.0961 25.1013C24.5841 25.6133 24.3281 26.247 24.3281 27.0026V37.7359C24.3281 38.4915 24.5841 39.1137 25.0961 39.6026C25.6063 40.0915 26.2392 40.3359 26.9948 40.3359ZM26.9948 19.0026C27.7503 19.0026 28.3841 18.7466 28.8961 18.2346C29.4063 17.7244 29.6615 17.0915 29.6615 16.3359C29.6615 15.5804 29.4063 14.9466 28.8961 14.4346C28.3841 13.9244 27.7503 13.6693 26.9948 13.6693C26.2392 13.6693 25.6063 13.9244 25.0961 14.4346C24.5841 14.9466 24.3281 15.5804 24.3281 16.3359C24.3281 17.0915 24.5841 17.7244 25.0961 18.2346C25.6063 18.7466 26.2392 19.0026 26.9948 19.0026ZM26.9948 53.6693C23.3059 53.6693 19.8392 52.9688 16.5948 51.5679C13.3503 50.1688 10.5281 48.2693 8.12813 45.8693C5.72813 43.4693 3.82857 40.647 2.42946 37.4026C1.02857 34.1582 0.328125 30.6915 0.328125 27.0026C0.328125 23.3137 1.02857 19.847 2.42946 16.6026C3.82857 13.3582 5.72813 10.5359 8.12813 8.13594C10.5281 5.73594 13.3503 3.83549 16.5948 2.4346C19.8392 1.03549 23.3059 0.335938 26.9948 0.335938C30.6837 0.335938 34.1503 1.03549 37.3948 2.4346C40.6392 3.83549 43.4615 5.73594 45.8615 8.13594C48.2615 10.5359 50.161 13.3582 51.5601 16.6026C52.961 19.847 53.6615 23.3137 53.6615 27.0026C53.6615 30.6915 52.961 34.1582 51.5601 37.4026C50.161 40.647 48.2615 43.4693 45.8615 45.8693C43.4615 48.2693 40.6392 50.1688 37.3948 51.5679C34.1503 52.9688 30.6837 53.6693 26.9948 53.6693Z"
      fill="white"
    />
  </svg>
</template>
    
    <script>
export default {};
</script>
    
    <style></style>

