export default {
  path: 'navigation',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'navigation',
      component: () =>
        import(
          /* webpackChunkName: "allStudies" */ '../views/Navigation/index.vue'
        ),
    },
  ],
};
