<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.875 7.37083H11.2525L8.07625 11.1495C8.00271 11.2371 7.94732 11.3383 7.91323 11.4471C7.87915 11.5559 7.86704 11.6704 7.87761 11.7838C7.89894 12.013 8.01131 12.2244 8.19 12.3715C8.36869 12.5186 8.59906 12.5894 8.83043 12.5683C9.06181 12.5471 9.27523 12.4358 9.42375 12.2588L13.7987 7.05884C13.8282 7.01747 13.8545 6.97402 13.8775 6.92883C13.8775 6.8855 13.9213 6.8595 13.9388 6.81617C13.9784 6.7168 13.9992 6.61102 14 6.50417C13.9992 6.39732 13.9784 6.29154 13.9388 6.19217C13.9388 6.14884 13.895 6.12284 13.8775 6.0795C13.8545 6.03431 13.8282 5.99086 13.7987 5.9495L9.42375 0.749502C9.34148 0.65167 9.23846 0.572994 9.12201 0.519069C9.00556 0.465144 8.87855 0.437295 8.75 0.437502C8.54555 0.437106 8.34742 0.507631 8.19 0.636835C8.1014 0.709591 8.02816 0.798945 7.97448 0.899779C7.92079 1.00061 7.88772 1.11095 7.87716 1.22446C7.86659 1.33797 7.87874 1.45244 7.9129 1.56129C7.94706 1.67015 8.00257 1.77126 8.07625 1.85883L11.2525 5.6375H0.875C0.642936 5.6375 0.420376 5.72881 0.256282 5.89134C0.0921874 6.05387 0 6.27431 0 6.50417C0 6.73402 0.0921874 6.95446 0.256282 7.11699C0.420376 7.27953 0.642936 7.37083 0.875 7.37083Z"
      fill="#565F77"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    svgColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
  
  <style></style>