<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 2.5H7C6.46957 2.5 5.96086 2.71071 5.58579 3.08579C5.21071 3.46086 5 3.96957 5 4.5V20.5C5 21.0304 5.21071 21.5391 5.58579 21.9142C5.96086 22.2893 6.46957 22.5 7 22.5H19C19.5304 22.5 20.0391 22.2893 20.4142 21.9142C20.7893 21.5391 21 21.0304 21 20.5V8.5L15 2.5ZM16.8 20.5H15L13 17.1L11 20.5H9.2L12.1 16L9.2 11.5H11L13 14.9L15 11.5H16.8L13.9 16L16.8 20.5ZM14 9.5V4L19.5 9.5H14Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
