export const unix_timestampToYYYY_MM_DD_hh_mm = (UNIX_timestamp) => {
  const a = new Date(UNIX_timestamp * 1000);
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  return `${date}/${month}/${year} ${hour}:${min}`;
};

export const getMondayFromDate = (date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 1
  );
};

export const daysBetweenDates = (date1, date2) => {
  const diffTime = date1.getTime() - date2.getTime();
  const diffDays = diffTime / (1000 * 3600 * 24);
  return diffDays < 0 ? Math.floor(diffDays) : Math.ceil(diffDays);
};

export const weeksBetweenDates = (date1, date2) => {
  const daysBetween = daysBetweenDates(
    getMondayFromDate(date1),
    getMondayFromDate(date2)
  );
  return Math.floor(daysBetween / 7);
};

export const weeksFromNow = (date) => {
  const thisMonday = getMondayFromDate(new Date());
  return weeksBetweenDates(date, thisMonday);
};

export const dateToObjectValues = (date) => {
  return {
    date: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    day: date.getDay(),
  };
};

export const lastWeekMondayDate = () => {
  const now = new Date();
  const lastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );
  return getMondayFromDate(lastWeek);
};

export const dateToReverseDateString = (date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const addWeeksToDate = (date, numberOfWeeks) => {
  date.setDate(date.getDate() + 7 * numberOfWeeks);
  return date;
};

export const getMondayAndSundayFromCurrentWeek = () => {
  const today = new Date();
  const currentDay = today.getDay();
  const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay;

  const monday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + mondayOffset
  );
  const sunday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + (7 - currentDay)
  );

  const mondayFormatted = formatDate(monday);
  const sundayFormatted = formatDate(sunday);

  return {
    monday: mondayFormatted,
    sunday: sundayFormatted,
  };
};

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getMondayAndSundayFromLastWeek() {
  const today = new Date();
  const currentDayOfWeek = today.getDay();

  const daysToLastMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
  const daysToLastSunday = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysToLastMonday - 7);
  const lastSunday = new Date(today);
  lastSunday.setDate(today.getDate() + daysToLastSunday - 7);

  return {
    monday: formatDate(lastMonday),
    sunday: formatDate(lastSunday),
  };
}

export const transformDate_WeekDay_MonthDay_Year_To_YYYY_MM_DD = (
  dateString
) => {
  const months = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };
  const [, day, monthName, year] = dateString.match(/(\d+)\s(\w+)\s(\d+)/);
  const month = months[monthName];
  return `${year}-${month}-${day}`;
};

export const calculateWeeksUntilDate = (dateStart) => {
  const startDate = new Date(dateStart);
  const mondayDates = [];
  mondayDates.push('First-to-last-week');

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - today.getDay() + 1);

  const prevWeekMonday = new Date(today);
  prevWeekMonday.setDate(today.getDate() - 7);
  mondayDates.push(`Last Week (${prevWeekMonday.toLocaleDateString('en-GB')})`);

  let weeksAgo = 2;
  while (startDate < lastMonday) {
    const prevMonday = new Date(lastMonday);
    prevMonday.setDate(lastMonday.getDate() - weeksAgo * 7);
    mondayDates.push(
      `${weeksAgo} Weeks Ago (${prevMonday.toLocaleDateString('en-GB')})`
    );
    weeksAgo++;
    startDate.setDate(startDate.getDate() + 7);
  }
  return mondayDates;
};

export const getSundayOfMondayDateInYYYYMMDD = (mondayDate) => {
  const dateParts = mondayDate.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);

  const monday = new Date(year, month, day);
  const sunday = new Date(monday);

  sunday.setDate(monday.getDate() + 6);

  return formatDateToYYYYMMDD(sunday);
};

function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
