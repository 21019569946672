<template>
  <div :class="{ 'p-5': $route.name === 'tailor-schedule' }">
    <wizard-header
      :title="this.$t('participant_tailor_schedule')"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard
            :currentStep="step"
            wizardType="tailorSchedule"
          ></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <step-one v-show="step === 0" @nextStep="handleNext" />
      <step-two
        v-show="step === 1"
        v-if="step === 1"
        @nextStep="handleNext"
        @back="step--"
        :key="componentKey"
        :fromStepThree="comingFromStepThree"
      />
      <step-three
        v-show="step === 2"
        v-if="step === 2"
        @back="manageWizardSteps('back')"
      />
    </div>

    <b-modal
      v-model="showConfirmationModal"
      title="Add new Participant"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_add_participant') }}
      </p>
      <template #modal-footer="{ hide }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="$router.push({ name: 'view-participant' })"
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="hide()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WizardHeader from '@/components/WizardHeader';
import tailorScheduleMixin from '@/mixins/tailorScheduleMixin';
import { mapActions, mapGetters, mapState } from 'vuex';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

export default {
  components: {
    WizardHeader,
    StepOne,
    StepTwo,
    StepThree,
  },
  mixins: [tailorScheduleMixin],
  data() {
    return {
      componentKey: 0,
      loadCollapse: false,
      showConfirmationModal: false,
      step: 0,
      comingFromStepThree: false,
      participant: {
        subjectCode: '',
        gender: null,
        height: null,
        weight: null,
        partners: [],
        tags: [],
        reason: null,
      },
    };
  },
  computed: {
    ...mapState({
      site: (state) => state.sites.selectedSite,
      study: (state) => state.studies.selectedStudy,
      selectedClient: (state) => state.clients.selectedClient,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      participantParams: (state) => state.participants.participantParams,
      partnerTestData: (state) => state.resources.tests,
      excludedTask: (state) => state.participants.excluded_games,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
  },

  created() {
    this.$store.commit('participants/TAILOR_SCHEDULE_SPINNER', true);
    this.cleanExcludedGames();
    this.setBreadCrumbData({
      'client-create-participant': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'client-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'study-create-participant': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'study-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'protocol-create-participant': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },

        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'protocol-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'site-create-participant': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.site?.basic?.name,
          to: { name: 'view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'add-participant': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },

        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
    });
  },
  watch: {
    loadCollapse(value) {
      if (value === true) {
        this.loading = false;
        this.forceRender();
      }
    },
  },

  methods: {
    ...mapActions({
      fetchPartners: 'resources/dispatchFetchPartners',
    }),
    manageWizardSteps(param) {
      if (param === 'back') {
        this.step--;
        this.comingFromStepThree = true;
      }
    },
    forceRender() {
      this.componentKey += 1;
      this.$store.commit('participants/TAILOR_SCHEDULE_SPINNER', false);
    },
    handleNext() {
      this.step++;
      this.comingFromStepThree = false;
    },
    handleTailorScheduleNextClick(games) {
      this.participant.games = games;
      this.step++;
    },
    cleanExcludedGames() {
      this.$store.commit('participants/SET_EXCLUDED_GAMES', []);
      this.getExcludedGames();
    },
    // We store excluded games in state for Step 2
    async getExcludedGames() {
      const payload = this.participantParams;
      try {
        this.$store
          .dispatch('participants/tasksEnabled', payload)
          .then((resp) => {
            resp.code === 200
              ? this.fetchMovementTests()
              : this.$toast.error(this.$t('error_something_went_wrong'));
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    // We transform API call and store it in state for Step 2
    async fetchMovementTests() {
      try {
        this.$store
          .dispatch('resources/dispatchTestGames', {
            'filter[protocol]': this.participantParams.protocolId,
            'filter[game_mode]': true,
          })
          .then((resp) => {
            if (resp.code === 200) {
              this.refactor_partner_category_test_list(
                this.partnerTestData,
                this.excludedTask
              );
            }
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style></style>
