import perm from '@/constants/permissions';
import { login } from '@/api/auth';
import { logout } from '@/api/auth';
import router from '@/routes';
import { i18n } from '@/plugins/i18n.js';

const initialState = {
  token: null,
  userId: null,
  expertId: null,
  lang: '',
  title: '',
  firstName: null,
  lastName: null,
  profile: {},
  clientId: '',
  permissions: [],
  userType: '',
};
const state = { ...initialState };

const getters = {
  canManageUsers({ permissions }) {
    return permissions.includes(perm.manageUsers) ?? false;
  },
  canCreateClient({ permissions }) {
    return permissions.includes(perm.clientWrite) ?? false;
  },
  canReadClient({ permissions }) {
    return permissions.includes(perm.clientRead) ?? false;
  },
  canReadStudy:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.protocolRead) ?? false
        : profile?.study?.[id]?.permissions.includes(perm.studyRead) ?? false;
    },
  canCreateStudy({ permissions }) {
    return permissions.includes(perm.studyWrite) ?? false;
  },
  canReadProtocol:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.protocolRead) ?? false
        : (permissions.includes(perm.protocolRead) ||
            profile?.study?.[id]?.permissions.includes(perm.protocolRead)) ??
            false;
    },
  canCreateProtocol({ permissions }) {
    return permissions.includes(perm.protocolWrite) ?? false;
  },
  canReadSite:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.protocolRead) ?? false
        : (profile?.protocol?.[id]?.permissions.includes(perm.siteRead) ||
            profile?.study?.[id]?.permissions.includes(perm.protocolRead)) ??
            false;
    },
  canCreateSite:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.siteWrite) ?? false
        : profile?.protocol?.[id]?.permissions.includes(perm.siteWrite) ??
            false;
    },
  canReadParticipant({ permissions }) {
    return permissions.includes(perm.participantRead) ?? false;
  },
  canApproveProtocol:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.approve) ?? false
        : profile?.protocol?.[id]?.permissions.includes(perm.approve) ?? false;
    },
  canReadRole({ permissions }) {
    return permissions.includes(perm.roleRead) ?? false;
  },
  canCreateRole({ permissions }) {
    return permissions.includes(perm.roleWrite) ?? false;
  },
  canCreateParticipant:
    ({ profile, userType, permissions }) =>
    (id) => {
      return userType === 'healios'
        ? permissions.includes(perm.participantWrite) ?? false
        : profile?.protocol?.[id]?.permissions.includes(
            perm.participantWrite
          ) ?? false;
    },
  canWriteParticipant: ({ profile, permissions, clientId, userType }) => {
    let permissionsArray = [];
    if (userType === 'healios' && permissions.includes(perm.participantWrite)) {
      return true;
    }
    if (profile?.study) {
      const studyId = Object.keys(profile?.study)[0];
      let studyPermissions = profile?.study[studyId].permissions ?? [];
      permissionsArray = [...permissionsArray, ...studyPermissions];
    }
    if (profile?.protocol) {
      const protocolId = Object.keys(profile.protocol)[0];
      let protocolPermissions = profile?.protocol[protocolId].permissions ?? [];
      permissionsArray = [...permissionsArray, ...protocolPermissions];
    }
    if (profile?.site) {
      const siteId = Object.keys(profile.site)[0];
      let sitePermissions = profile?.site[siteId].permissions ?? [];
      permissionsArray = [...permissionsArray, ...sitePermissions];
    }
    if (profile?.client) {
      let clientPermissions = profile?.client?.[clientId].permissions ?? [];
      permissionsArray = [...permissionsArray, ...clientPermissions];
    }
    return permissionsArray.includes(perm.participantWrite);
  },
  canWriteSite:
    ({ profile, userType, permissions }) =>
    (siteId, protocol = {}) => {
      const writePermission = perm.siteWrite;
      const {
        clientId = protocol?.study?.clientId,
        studyId = protocol?.study?.id,
        protocolId = protocol?.id,
      } = protocol;
      return userType === 'healios'
        ? permissions.includes(writePermission)
        : profile?.client?.[clientId]?.permissions.includes(writePermission) ||
            profile?.study?.[studyId]?.permissions.includes(writePermission) ||
            profile?.protocol?.[protocolId]?.permissions.includes(
              writePermission
            ) ||
            profile?.site?.[siteId]?.permissions.includes(writePermission);
    },
  navigatorPermissions: ({ userType, permissions }) => {
    const navigatorReadPermission = perm.navigatorRead;
    const navigatorWritePermission = perm.navigatorWrite;
    if (
      userType === 'healios' &&
      permissions.includes(navigatorReadPermission) &&
      permissions.includes(navigatorWritePermission)
    ) {
      return true;
    }
  },

  // User types
  isClientUser({ userType }) {
    return userType === 'client';
  },
  isHealiosUser({ userType }) {
    return userType === 'healios';
  },

  hasReadLogsRights({ userType, permissions }) {
    return userType === 'healios' && permissions.includes('READ_LOGS');
  },
};

const mutations = {
  SET_USER_LANGUAGE: (state, lang) => {
    if (lang) {
      state.lang = lang;
      i18n.locale = lang;
      localStorage.lang = lang;
    } else {
      i18n.locale = window.navigator.language;
    }
  },

  SET_USER_SESSION: (state, user) => {
    Object.keys(state).forEach((name) => (state[name] = user[name]));
    if (user.profile.healios) {
      // get superAdmin or programManager
      const profileType = Object.keys(user.profile.healios)[0];
      state.permissions = user.profile.healios[profileType].permissions ?? [];
      state.userType = 'healios';
    } else {
      state.permissions =
        user.profile?.client?.[user.clientId].permissions ?? [];
      state.profile = user.profile;
      state.userType = 'client';
    }
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  GET_USER_SESSION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      login(payload)
        .then((resp) => {
          commit('SET_USER_SESSION', resp.data);
          commit('SET_USER_LANGUAGE', resp.data.lang);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      logout().then(() => {
        commit('RESET');
        router.push({ name: 'login' });
        resolve();
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
