<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 0C12.163 0 12.7989 0.263392 13.2678 0.732233C13.7366 1.20107 14 1.83696 14 2.5V6.6C13.6804 6.43647 13.3454 6.30483 13 6.207V4H1V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H6.207C6.306 13.349 6.437 13.683 6.6 14H2.5C1.83696 14 1.20107 13.7366 0.732233 13.2678C0.263392 12.7989 0 12.163 0 11.5V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H11.5ZM11.5 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V3H13V2.5C13 2.10218 12.842 1.72064 12.5607 1.43934C12.2794 1.15804 11.8978 1 11.5 1ZM16 11.5C16 12.6935 15.5259 13.8381 14.682 14.682C13.8381 15.5259 12.6935 16 11.5 16C10.3065 16 9.16193 15.5259 8.31802 14.682C7.47411 13.8381 7 12.6935 7 11.5C7 10.3065 7.47411 9.16193 8.31802 8.31802C9.16193 7.47411 10.3065 7 11.5 7C12.6935 7 13.8381 7.47411 14.682 8.31802C15.5259 9.16193 16 10.3065 16 11.5ZM13.854 9.646C13.8076 9.59944 13.7524 9.56249 13.6916 9.53729C13.6309 9.51208 13.5658 9.49911 13.5 9.49911C13.4342 9.49911 13.3691 9.51208 13.3084 9.53729C13.2476 9.56249 13.1924 9.59944 13.146 9.646L10.5 12.293L9.854 11.646C9.76011 11.5521 9.63278 11.4994 9.5 11.4994C9.36722 11.4994 9.23989 11.5521 9.146 11.646C9.05211 11.7399 8.99937 11.8672 8.99937 12C8.99937 12.1328 9.05211 12.2601 9.146 12.354L10.146 13.354C10.1924 13.4006 10.2476 13.4375 10.3084 13.4627C10.3691 13.4879 10.4342 13.5009 10.5 13.5009C10.5658 13.5009 10.6309 13.4879 10.6916 13.4627C10.7524 13.4375 10.8076 13.4006 10.854 13.354L13.854 10.354C13.9006 10.3076 13.9375 10.2524 13.9627 10.1916C13.9879 10.1309 14.0009 10.0658 14.0009 10C14.0009 9.93423 13.9879 9.86911 13.9627 9.80837C13.9375 9.74762 13.9006 9.69245 13.854 9.646Z"
      class="icon-style"
      stroke="#ffffff"
      stroke-width="1.1"
    />
  </svg>
</template>

<script>
export default {};
</script>
