/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import IdleVue from 'idle-vue';
import { BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';
import VueTheMask from 'vue-the-mask';
import VueToast from 'vue-toast-notification';
import { Steps, Step } from 'element-ui';
import { ServerTable, ClientTable, Event } from 'vue-tables-2';
import moment from 'moment';
import Donut from 'vue-css-donut-chart';
import momentTz from 'moment-timezone';

import App from './App.vue';
import store from './store/index';
import router from './routes';
import { i18n } from './plugins/i18n.js';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-css-donut-chart/dist/vcdonut.css';

import './browser-language';
import './registerServiceWorker';
import './vee-validate';
import './authChecker';

import ArrowRight from './components/Icons/ArrowRight';
import ArgonDashboard from './plugins/argon-dashboard';
import permissionMixin from '@/mixins/permissionMixin';
import RouteTabs from './components/RouteTabs/RouteTabs.vue';
import RouteTab from './components/RouteTabs/RouteTab.vue';
import VCalendar from 'v-calendar';
import FilterSelect from './components/FilterSelect.vue';
import breadcrumbMixin from './mixins/breadcrumbMixin';
import AppBadge from './components/AppBadge/AppBadge.vue';
import JsonViewer from 'vue-json-viewer';
import displayErrorMixin from './mixins/displayErrorMixin';

const eventsHub = new Vue();

Vue.config.productionTip = false;
Vue.component('arrow-right', ArrowRight);
Vue.component('b-spinner', BSpinner);
Vue.component('v-select', vSelect);
Vue.component('multiselect', Multiselect);
Vue.component('filter-select', FilterSelect);
Vue.component('app-badge', AppBadge);
Vue.component('route-tabs', RouteTabs);
Vue.component('route-tab', RouteTab);
Vue.use(ArgonDashboard);
Vue.use(VueTheMask);
Vue.prototype.moment = moment;
momentTz.tz.setDefault(momentTz.tz.guess());
Vue.prototype.momentTz = momentTz;

Vue.use(Steps);
Vue.use(Step);
Vue.use(VueToast, {
  // One of the options
  position: 'top-right',
  duration: 4000,
});
Vue.use(Donut);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_IDLE_TIME ?? 10000, // 10 seconds, if you need to change it, use your .env
  startAtIdle: false,
  events: ['keydown', 'mousedown', 'touchstart'],
});
Vue.use(JsonViewer);

let vueTablesOptions = {
  theme: 'bootstrap4',
  sortIcon: {
    base: 'ml-2 fas',
    up: 'fa-sort-up',
    down: 'fa-sort-down',
    is: 'fa-sort',
  },
  skin: 'table app-table',
  pagination: {
    show: false,
    nav: 'scroll',
  },
};
Vue.use(ClientTable, vueTablesOptions);
Vue.use(ServerTable, vueTablesOptions);
Vue.use(Event);
Vue.mixin(permissionMixin);
Vue.mixin(breadcrumbMixin);
Vue.mixin(displayErrorMixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
