var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"The Admin","title":"The Admin"},scopedSlots:_vm._u([(_vm.navigatorPermissions != true)?{key:"links-after",fn:function(){return [(!_vm.isClientUser)?[_c('h6',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarVisibility),expression:"sidebarVisibility"}],staticClass:"navbar-heading pl-4"},[_vm._v(" "+_vm._s(_vm.$t('tools'))+" ")]),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('user_management'),
            icon: 'user-icon',
            path: '/user-management/roles',
            linkActive: _vm.$route.matched[1].path === '/user-management',
            hasChild: true,
            iconType: 'fill',
            id: 'user-management',
          },"title":"User Management"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('UserIcon',{staticClass:"mr-0"})]},proxy:true},{key:"child",fn:function(){return [_c('router-link',{staticClass:"nav-child-link",class:_vm.$route.matched[2] &&
                _vm.$route.matched[2].path === '/user-management/roles' &&
                'nav-child-link-active',attrs:{"to":{ name: 'list-roles' }}},[(
                  _vm.$route.matched[2] &&
                  _vm.$route.matched[2].path === '/user-management/roles'
                )?_c('span',{staticClass:"ml-1"},[_vm._v(" • ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('roles'))+" ")])]},proxy:true}],null,false,1027864711)})]:_vm._e(),_c('sidebar-item',{staticClass:"special-class",attrs:{"link":{
          name: _vm.$t('reports'),
          icon: 'report-icon',
          path: '/reports',
          linkActive: _vm.$route.matched[1].path === '/adherence-report',
          hasChild: true,
          iconType: 'fill',
          id: 'adherence-reports',
        },"title":"Reports"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ReportIcon',{staticClass:"mr-0 report-icon"})]},proxy:true},{key:"child",fn:function(){return [_c('router-link',{staticClass:"nav-child-link",class:_vm.$route.matched[2] &&
              _vm.$route.matched[2].path === '/adherence-report' &&
              'nav-child-link-active',attrs:{"to":{ name: 'adherence-reports' }}},[(
                _vm.$route.matched[2] &&
                _vm.$route.matched[2].path === '/adherence-report'
              )?_c('span',{staticClass:"ml-1"}):_vm._e(),_c('AdherenceIcon',{staticClass:"adherence-icon mr-2"}),_vm._v(" "+_vm._s(_vm.$t('participant_adherence'))+" ")],1)]},proxy:true}],null,false,2582391208)}),_c('button',{staticClass:"sidebar-button",on:{"click":_vm.handleAddParticipant}},[_c('AddParticipantIcon'),_c('span',[_vm._v(_vm._s(_vm.$t('participant_add')))])],1),(_vm.hasReadLogsRights)?_c('sidebar-item',{attrs:{"link":{
          name: 'Audit Logs',
          icon: 'audit-logs-icon',
          path: '/audit-logs',
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('AuditLogsIcon',{staticClass:"mr-0"})]},proxy:true}],null,false,1713042774)}):_vm._e()]},proxy:true}:null],null,true)},[(_vm.navigatorPermissions)?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Navigation'),
          path: '/navigation',
          linkActive: _vm.$route.matched[1].path === '/navigation',
          iconType: 'fill',
        },"title":"Navigation"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('HomeIcon')]},proxy:true}],null,false,1967478151)})],1):_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('home'),
          path: '/dashboard',
          linkActive: _vm.$route.matched[1].path === '/dashboard',
          iconType: 'fill',
        },"title":"Home"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('HomeIcon')]},proxy:true}])}),(!_vm.isClientUser)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('clients'),
          icon: 'clients-icon',
          path: '/clients',
          linkActive: _vm.$route.matched[1].path === '/clients',
          iconType: 'fill',
        },"title":"Clients"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ClientIcon')]},proxy:true}],null,false,3857530225)}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('studies'),
          icon: 'book-icon',
          path: '/studies',
          linkActive: _vm.$route.matched[1].path === '/studies',
          iconType: 'fill',
        },"title":"Studies"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BookIcon')]},proxy:true}])}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('protocols'),
          icon: 'protocols-icon',
          path: '/protocols',
          linkActive: _vm.$route.matched[1].path === '/protocols',
          iconType: 'fill',
        },"title":"Protocols"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ProtocolsIcon')]},proxy:true}])}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sites'),
          icon: 'sites-icon',
          path: '/sites',
          linkActive: _vm.$route.matched[1].path === '/sites',
          iconType: 'fill',
        },"title":"Sites"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SitesIcon')]},proxy:true}])}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('participants'),
          path: '/participants',
          linkActive: _vm.$route.matched[1].path === '/participants',
          iconType: 'fill',
        },"title":"Participants"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ParticipantsIcon')]},proxy:true}])})],1)],2),_c('div',{staticClass:"main-content",class:{ wide: !_vm.sidebarVisibility },attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),_c('div',[(_vm.isIdle)?_c('modal-idle'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }