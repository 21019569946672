<template>
  <div class="adherence-report-container pl-4 pr-4 pt-4">
    <b-row>
      <b-col cols="9">
        <participant-adherence-report-filters-component
          @adherence-filter="handleFilters"
        ></participant-adherence-report-filters-component>
      </b-col>

      <b-col cols="3" class="d-flex justify-content-center align-items-center">
        <b-dropdown
          id="dropdown-1"
          :text="this.$t('export_data')"
          variant="primary"
          class="custom-dropdown"
        >
          <b-dropdown-item @click="handleDropdownItem('csv')"
            ><span class="selector-text">
              <csv-icon></csv-icon>
              <span class="pl-3">{{ $t('csv_file') }}</span>
            </span>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="handleDropdownItem('xls')"
            ><span class="selector-text">
              <xls-icon></xls-icon>
              <span class="pl-3">{{ $t('xls_file') }}</span>
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <participant-adherence-report-type-and-period-component
          :firstStudyWeekDate="firstStudyWeek"
          @selections="handleSelections"
        ></participant-adherence-report-type-and-period-component>
      </b-col>
    </b-row>

    <b-row class="pt-4 pb-4">
      <b-col v-if="hasParticipantStatusStatistics">
        <participant-chart-status-container
          :key="updateCharts"
          :statistics-data="participantStatusStatistics"
        ></participant-chart-status-container
      ></b-col>
      <b-col v-if="hasParticipantAdherenceStatistic"
        ><participant-adherence-average-container
          :key="updateCharts"
          :statistics-data="participantAverageAdherenceData"
        ></participant-adherence-average-container
      ></b-col>
      <b-col v-if="hasParticipantAdherenceStatistic">
        <participant-adherence-schedule-container
          :key="updateCharts"
          :statistics-data="participantAverageAdherenceData"
        ></participant-adherence-schedule-container>
      </b-col>
    </b-row>

    <b-row class="pt-4 pb-4">
      <b-col
        ><v-client-table
          :data="participantAverageAdherenceData"
          :columns="tableData.tableIsDetailed ? columnsDetailed : columns"
          :options="tableData.tableIsDetailed ? optionsDetailed : options"
          :class="{ 'loading-table': loading }"
          ><template slot="total_completed_percent" slot-scope="props">
            <report-adherence-levels
              :content="props.row.total_completed_percent"
            ></report-adherence-levels>
          </template>
          <template slot="baseline_date" slot-scope="props">
            <span>{{ transformBaselineDate(props.row.baseline_date) }}</span>
          </template>
        </v-client-table>
        <paginator-table-component
          @changePageEvent="
            (pageIndex) => this.handleChangePageEvent(pageIndex)
          "
          :initial-page="paginationData.currentPage"
          :total-rows="paginationData.totalCount"
          :per-page="paginationData.totalPages"
          :key="paginatorKey"
        />
      </b-col>
    </b-row>
    <!-- Modal Export Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      :title="this.$t('exporting_data')"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      centered
    >
      <p class="text-center">{{ $t('exporting_data_info') }}</p>
    </b-modal>
  </div>
</template>

<script>
import {
  columns,
  columnsDetailed,
} from '@/constants/adherenceReportTableColumns';
import { getAdherenceChartData, getReportData } from '@/api/resources';
import { getFirstStudyWeekDate } from '@/api/study';
import ParticipantAdherenceReportTypeAndPeriodComponent from '@/components/Reports/ParticipantAdherenceReportTypeAndPeriodComponent.vue';
import ParticipantChartStatusContainer from '@/components/Reports/ParticipantChartStatusContainer.vue';
import ParticipantAdherenceAverageContainer from '@/components/Reports/ParticipantAdherenceAverageContainer.vue';
import ParticipantAdherenceScheduleContainer from '@/components/Reports/ParticipantAdherenceScheduleContainer.vue';
import ParticipantAdherenceReportFiltersComponent from '@/components/Reports/ParticipantAdherenceReportFiltersComponent.vue';
import ReportAdherenceLevels from '@/components/Reports/ParticipantAdherenceReportAdherenceLevelsComponent.vue';
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import {
  getMondayAndSundayFromCurrentWeek,
  getMondayAndSundayFromLastWeek,
  getSundayOfMondayDateInYYYYMMDD,
  transformDate_WeekDay_MonthDay_Year_To_YYYY_MM_DD,
} from '@/utils/dateTransform';
import CsvIcon from '@/components/Icons/CsvIcon.vue';
import XlsIcon from '@/components/Icons/XlsIcon.vue';

export default {
  name: 'ParticipantAdherenceReportView',
  components: {
    ParticipantChartStatusContainer,
    ParticipantAdherenceAverageContainer,
    ParticipantAdherenceScheduleContainer,
    ParticipantAdherenceReportTypeAndPeriodComponent,
    ParticipantAdherenceReportFiltersComponent,
    ReportAdherenceLevels,
    PaginatorTableComponent,
    CsvIcon,
    XlsIcon,
  },

  data() {
    return {
      firstLoading: true,
      loading: true,
      participantAverageAdherenceData: [],
      participantStatusStatistics: {},
      updateCharts: 0,
      columns: columns,
      columnsDetailed: columnsDetailed,
      options: {
        headings: {
          participant_id: this.$t('participant_id'),
          subject_id: this.$t('participant_id_subject'),
          total_scheduled: this.$t('scheduled'),
          total_completed: this.$t('completed'),
          total_not_completed: this.$t('not_completed'),
          total_completed_percent: this.$t('adherence'),
        },
        sortable: [],
      },
      optionsDetailed: {
        headingsDetailed: {
          participant_id: this.$t('participant_id'),
          subject_id: this.$t('gender'),
          baseline_date: this.$t('baseline_date'),
          study_start_date: this.$t('study_start_date'),
          study_end_date: this.$t('study_end_date'),
          total_scheduled: this.$t('scheduled'),
          total_completed: this.$t('completed'),
          total_completed_percent: this.$t('adherence'),
          dreaMS_scheduled: this.$t('dreaMS_scheduled'),
          dreaMS_completed: this.$t('dreaMS_completed'),
          dreaMS_completed_percent: this.$t('dreaMS_completed_percent'),
          'Roche - Floodlight_scheduled': this.$t('floodlight_scheduled'),
          'Roche - Floodlight_completed': this.$t('floodlight_completed'),
          'Roche - Floodlight_completed_percent': this.$t(
            'floodlight_completed_percent'
          ),
          movement_scheduled: this.$t('movement_scheduled'),
          movement_completed: this.$t('movement_completed'),
          movement_completed_percent: this.$t('movement_completed_percent'),
          dexterity_scheduled: this.$t('dexterity_scheduled'),
          dexterity_completed: this.$t('dexterity_completed'),
          dexterity_completed_percent: this.$t('dexterity_completed_percent'),
          balance_scheduled: this.$t('balance_scheduled'),
          balance_completed: this.$t('balance_completed'),
          balance_completed_percent: this.$t('balance_completed_percent'),
          cognitive_scheduled: this.$t('cognitive_scheduled'),
          cognitive_completed: this.$t('cognitive_completed'),
          cognitive_completed_percent: this.$t('cognitive_completed_percent'),
          vision_scheduled: this.$t('vision_scheduled'),
          vision_completed: this.$t('vision_completed'),
          vision_completed_percent: this.$t('vision_completed_percent'),
          survey_scheduled: this.$t('survey_scheduled'),
          survey_completed: this.$t('survey_completed'),
          survey_completed_percent: this.$t('survey_completed_percent'),
          relapse_reported: this.$t('relapse_reported'),
        },
        sortable: [],
      },
      paginatorKey: 0,
      paginationData: {},
      tableData: {
        tableLimit: 10,
        tableOffset: 0,
        tableSort: 'total_scheduled',
        tableIsDetailed: false,
      },
      selectedWeek: {
        monday: null,
        sunday: null,
      },
      filtersData: {
        clientId: null,
        studyId: null,
        protocolId: null,
        siteId: null,
      },
      showConfirmationModal: false,
      firstStudyWeek: '',
    };
  },

  created() {
    this.getReport();
    this.getAdherenceStats();
    this.getFistStudyWeek();

    this.setBreadCrumbData({
      'adherence-reports': [
        {
          text: this.$t('participant_adherence'),
          active: true,
        },
      ],
    });
  },
  computed: {
    hasParticipantStatusStatistics() {
      return Object.keys(this.participantStatusStatistics).length > 0;
    },
    hasParticipantAdherenceStatistic() {
      return this.participantAverageAdherenceData.length > 0;
    },
  },
  methods: {
    async getAdherenceStats() {
      try {
        const { clientId, studyId, protocolId, siteId } = this.filtersData;
        const response = await getAdherenceChartData(
          clientId,
          studyId,
          protocolId,
          siteId
        );
        this.participantStatusStatistics = response.data;
        this.updateCharts += 1;
      } catch (error) {
        console.error(error);
      }
    },

    async getReport() {
      try {
        this.loading = true;
        const { tableLimit, tableOffset, tableSort, tableIsDetailed } =
          this.tableData;
        const { monday, sunday } = this.selectedWeek;
        const { clientId, studyId, protocolId, siteId } = this.filtersData;
        const response = await getReportData(
          tableLimit,
          tableOffset,
          tableSort,
          tableIsDetailed,
          monday,
          sunday,
          clientId,
          studyId,
          protocolId,
          siteId
        );
        this.participantAverageAdherenceData = response.data;
        this.paginationData = response.pagination;
        this.selectedWeek.monday = null;
        this.selectedWeek.sunday = null;
        this.paginatorKey += 1;
        this.updateCharts += 1;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async getFistStudyWeek() {
      try {
        const { clientId, studyId, protocolId, siteId } = this.filtersData;
        const response = await getFirstStudyWeekDate(
          clientId,
          studyId,
          protocolId,
          siteId
        );
        this.firstStudyWeek = response?.data?.dateStart;
      } catch (e) {
        console.error(e);
      }
    },

    handleSelections(selections) {
      if (selections.reportData === 'Summary') {
        this.tableData.tableOffset = 0;
        this.tableData.tableIsDetailed = false;
      }
      if (selections.reportData === 'Detailed') {
        this.tableData.tableOffset = 0;
        this.tableData.tableIsDetailed = true;
      }
      if (selections.periodData === 'First-to-last-week') {
        const lastWeek = getMondayAndSundayFromLastWeek();
        this.selectedWeek.monday = this.firstStudyWeek;
        this.selectedWeek.sunday = lastWeek.sunday;
      }
      if (selections.periodData === 'This week') {
        const currentWeek = getMondayAndSundayFromCurrentWeek();
        this.selectedWeek.monday = currentWeek.monday;
        this.selectedWeek.sunday = currentWeek.sunday;
      }
      if (selections.periodData.includes('Last Week')) {
        const { monday, sunday } = getMondayAndSundayFromLastWeek();
        this.selectedWeek.monday = monday;
        this.selectedWeek.sunday = sunday;
      }
      if (selections.periodData === 'Overall') {
        this.selectedWeek.monday = null;
        this.selectedWeek.sunday = null;
      }
      if (selections.periodData.includes('Weeks Ago')) {
        const monday = this.getDateFromString(selections.periodData);
        const sunday = getSundayOfMondayDateInYYYYMMDD(monday);
        this.selectedWeek.monday = monday;
        this.selectedWeek.sunday = sunday;
      }
      this.getReport();
    },

    getDateFromString(inputString) {
      const dateRegex = /(\d{2})\/(\d{2})\/(\d{4})/;
      const matchResult = inputString.match(dateRegex);

      if (matchResult) {
        const [, day, month, year] = matchResult;
        return `${year}-${month}-${day}`;
      } else {
        return '';
      }
    },

    handleChangePageEvent(pageIndex) {
      this.tableData.tableOffset = pageIndex - 1;
      this.getReport();
    },
    handleFilters(data) {
      this.filtersData = data;
      this.getReport();
      this.getAdherenceStats();
      this.getFistStudyWeek();
    },
    transformBaselineDate(dateString) {
      if (dateString) {
        return transformDate_WeekDay_MonthDay_Year_To_YYYY_MM_DD(dateString);
      }
    },
    async handleDropdownItem(fileType) {
      try {
        const { tableLimit, tableOffset, tableSort, tableIsDetailed } =
          this.tableData;
        const { monday, sunday } = this.selectedWeek;
        const { clientId, studyId, protocolId, siteId } = this.filtersData;
        const response = await getReportData(
          tableLimit,
          tableOffset,
          tableSort,
          tableIsDetailed,
          monday,
          sunday,
          clientId,
          studyId,
          protocolId,
          siteId,
          fileType
        );
        this.download_CSV_XLS(response, fileType);
        this.showConfirmationModal = true;
      } catch (error) {
        console.error(error);
      }
    },

    download_CSV_XLS(apiResponse, fileType) {
      const rows = apiResponse.trim().split('\n');

      const headers = rows[0].split(',').map((header) => header.trim());
      const dataRows = rows.slice(1).map((row) => row.split(','));

      const csvContent = [headers, ...dataRows]
        .map((row) => row.join(','))
        .join('\n');

      const blob = new Blob([csvContent], {
        type: `text/${fileType};charset=utf-8;`,
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `adherence-data.${fileType}`;
      link.click();
      setTimeout(() => {
        this.showConfirmationModal = false;
      }, 1500);
    },
  },
};
</script>

<style scoped lang="scss">
.loading-table {
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0% {
    opacity: 60%;
  }
  100% {
    opacity: 45%;
  }
}
.custom-dropdown ::v-deep .dropdown-menu.show {
  background: #076db3;
  color: #ffffff;
}

.custom-dropdown ::v-deep .selector-text {
  color: #ffffff;
  font-weight: bold;
}
.custom-dropdown ::v-deep .dropdown-item:hover,
.dropdown-item:focus {
  color: #96c2e7;
  text-decoration: none;
  background-color: #076db3;
  .selector-text {
    color: #96c2e7;
  }
}
</style>
