<template>
  <b-row>
    <b-col :cols="colsWidth" :style="cssDiv">
      <hr class="dividerStyles" />
    </b-col>
  </b-row>
</template>
    
<script>
export default {
  name: 'HorizontalDivider',
  status: 'ready',

  props: {
    colorLine: {
      type: String,
      default: '046db3',
    },
    lineWidth: {
      type: String,
      default: '1',
    },
    colsWidth: {
      type: String,
      default: '12',
    },
  },
  computed: {
    cssDiv() {
      return {
        '--line-color': `#${this.colorLine}`,
        '--line-width': `${this.lineWidth}px`,
      };
    },
  },
};
</script>
    
  <style lang="scss" scoped >
.dividerStyles {
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-top: var(--line-width) solid var(--line-color);
  }
}
</style>
    