<template>
  <b-spinner class="mt-4 mx-auto d-block" v-if="firstLoading" />
  <div class="mt-4" v-else>
    <StudiesTable
      :studiesListTableData="studies"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      :displayClientNameColumn="displayClientNameColumn"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
    <PaginatorTableComponent
      @changePageEvent="handleChangePageEvent"
      :current-page="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :page-size="pagination.pageSize"
    />
  </div>
</template>

<script>
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import listMixin from '@/mixins/listMixin';
import { paginationDefaults } from '@/constants/pagination-constants';
import { mapActions, mapState } from 'vuex';
import StudiesTable from '@/components/Tables/StudiesTable.vue';
import { studyListDataTableObjectMapper } from '@/object-mappers/study.object-mapper';

export default {
  name: 'StudyList',
  components: {
    StudiesTable,
    PaginatorTableComponent,
  },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
    displayClientNameColumn: Boolean,
  },
  computed: {
    ...mapState({
      studies: ({ studies }) => studyListDataTableObjectMapper(studies.studies),
      storePagination: ({ studies }) => studies.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('studies');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
    };
  },
  created() {
    this.getListData('studies');
  },
  methods: {
    ...mapActions({
      fetchListData: 'studies/fetchStudies',
      setStudyParams: 'studies/setStudyParams',
    }),
    goToDetail(study) {
      this.setStudyParams({
        clientId: study.clientId,
        studyId: study.id,
      });
      this.$emit('goToDetail');
    },
  },
};
</script>
