<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
    <modalChooseLanguage v-if="$store.state.auth.token" />
  </div>
</template>

<script>
import modalChooseLanguage from '@/components/ModalIdle/modalChooseLanguage.vue';
export default {
  components: {
    modalChooseLanguage,
  },
  created() {
    // eslint-disable-next-line no-undef
    console.log('App version:', APP_VERSION);
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/select.scss';

.avenir-bold {
  font-family: Avenir-Bold, Helvetica, Arial !important;
  &.col,
  > .col,
  .text_break {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.avenir {
  font-family: Avenir, Helvetica, Arial !important;
}
label:not(.custom-control-label),
label:not(.normal-label) {
  font-family: Avenir-Bold, Helvetica, Arial !important;
  margin-top: 1rem;
  font-size: 14px;
}
.normal-label {
  font-family: Avenir, Helvetica, Arial !important;
  margin-top: 1rem !important;
  font-size: 14px !important;
}
.vs__dropdown-toggle {
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  height: calc(1.5em + 1.25rem + 2px);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #8898aa;
}
/* steps component fixes */
.is-wait .el-step__icon-inner {
  visibility: hidden;
}

.el-steps .is-process .el-step__icon-inner {
  background-color: #046db3;
  content-visibility: hidden;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.el-steps .el-step__icon.is-text {
  border: 1px solid;
}
.el-steps .el-step__head.is-finish {
  color: #046db3;
  border-color: #046db3;
}
.el-step__head.is-finish .el-step__icon {
  background-color: #046db3;
}
.el-steps .el-step__title.is-finish {
  color: inherit;
}

.vs--disabled .vs__selected-options {
  background-color: #f8f8f8;
}
.el-steps .el-step__head.is-process {
  color: #046db3;
  border-color: #046db3;
}

.topbar-title {
  font-weight: 500;
  font-size: 22px;
  /* color: #ffffff; */
}
.topbar-title + .fa-times {
  /* color: white; */
  font-size: 18px;
  cursor: pointer;
}
.theme-pills {
  float: left;
  background: var(--light);
  border-radius: 50rem;
}
.theme-pills .nav-link:not(.active) {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cursor-pointer {
  cursor: pointer;
}

.modal-open {
  overflow: auto !important;
}

.action-button {
  margin-top: -2.7rem;
}
</style>
