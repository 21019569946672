<template>
  <b-row class="pt-5 pb-5 justify-content-center">
    <b-col>
      <ListFiltersComponent
        :filtersToDisplay="filtersToDisplay"
        @changeFilterEvent="handleChangeFilterEvent"
    /></b-col>
  </b-row>
</template>

<script>
import ListFiltersDataMixin from '@/mixins/ListFiltersDataMixin';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';

export default {
  name: 'ParticipantAdherenceReportFiltersComponent',
  components: { ListFiltersComponent },
  mixins: [ListFiltersDataMixin],

  data() {
    return {
      loading: false,
      filtersToDisplay: {
        client: !this.isClientUser,
        study: true,
        protocol: true,
        site: true,
      },
      filterData: {},
    };
  },

  watch: {
    filterData(newValue) {
      this.$emit('adherence-filter', newValue);
    },
  },

  methods: {
    handleChangeFilterEvent(newFilter) {
      this.filterData = newFilter;
    },
  },
};
</script>

<style lang="scss" scoped></style>
