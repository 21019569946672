<template>
  <div>
    <base-button type="primary" size="sm" rounded @click="showModal">{{
      button
    }}</base-button>
    <b-modal
      v-if="modalShow"
      v-model="modalShow"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      title-tag="h2"
      size="lg"
    >
      <template slot="modal-title"> {{ title }} </template>
      <b-tabs
        pills
        nav-class="theme-pills"
        active-nav-item-class="rounded-pill"
        :nav-wrapper-class="`${isCreateClient && 'd-none'} clearfix`"
        v-model="currentTab"
        @input="resetForm"
        class="pb-4"
      >
        <b-tab title="Current User" :disabled="isCreateClient">
          <b-card-text>
            <v-select
              class="mt-3"
              v-model="selectedCurrentUserExpertId"
              :options="currentUsers"
              :reduce="(user) => user.id"
              :custom-label="({ user }) => `${user.firstName} ${user.lastName}`"
              label="fullName"
              @input="changeCurrentUser"
            />
          </b-card-text>
          <template v-if="selectedCurrentUserExpertId">
            <h4 class="mt-5">{{ $t('roles') }}</h4>
            <b-form-checkbox-group
              stacked
              :options="roles"
              v-model="selectedUserRoles"
              text-field="name"
              value-field="id"
            />
          </template>
        </b-tab>
        <b-tab title="New user">
          <div v-if="canManageUsers" class="mb-2">
            <label>{{ $t('user_status') }}</label>
            <b-form-checkbox
              id="assign_user_status"
              name="assign_user_status"
              switch
              v-model="userIsActive"
            >
              {{ userIsActive ? $t('ACTIVE') : $t('INACTIVE') }}
            </b-form-checkbox>
          </div>

          <b-row>
            <b-col cols="6">
              <label>{{ $t('name_first') }}</label>
              <b-form-input
                v-model="newUser.firstName"
                :placeholder="$t('name_first')"
                maxLength="100"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <label>{{ $t('name_last') }}</label>
              <b-form-input
                v-model="newUser.lastName"
                :placeholder="$t('name_last')"
                maxLength="100"
              ></b-form-input>
            </b-col>
          </b-row>

          <label>{{ $t('title_work_job') }}</label>
          <b-form-input
            v-model="newUser.title"
            :placeholder="$t('title_work_job')"
            maxLength="100"
          ></b-form-input>

          <label>{{ $t('email') }}</label>
          <b-form-input
            v-model="newUser.email"
            id="email"
            :state="emailStatus"
            :placeholder="$t('email_tip')"
            maxLength="150"
            aria-labelledby="email-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">{{
            $t('error_email_not_valid')
          }}</b-form-invalid-feedback>

          <label>{{ $t('phone') }}</label>
          <phone-number-input-by-country v-model="newUser.phone" />

          <h4 class="mt-5">{{ $t('roles') }}</h4>
          <b-form-checkbox-group
            stacked
            :options="roles"
            v-model="selectedUserRoles"
            text-field="name"
            value-field="id"
          />
        </b-tab>
      </b-tabs>
      <div class="mt-5">
        <base-button
          type="button"
          class="ml-auto"
          @click="modalShow = false"
          :disabled="addingRoleUser"
          >{{ $t('cancel') }}
        </base-button>
        <base-button
          type="primary"
          @click="validateAndAdd"
          :disabled="addingRoleUser || !isValidRep"
          >{{ $t('add') }} <b-spinner v-if="addingRoleUser" small></b-spinner
        ></base-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PhoneNumberInputByCountry from '@/components/PhoneNumberInput';
import { fetchUsers } from '@/api/users';

export default {
  components: {
    PhoneNumberInputByCountry,
  },
  props: {
    title: String,
    button: String,
    roleUsers: Array,
    authLevel: String,
    code: String,
    resourceId: String,
    isEdit: Boolean,
    clientId: String,
  },
  data() {
    return {
      userIsActive: true,
      currentTab: 0,
      modalShow: false,
      addingRoleUser: false,
      selectedCurrentUserExpertId: '',
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
        status: { name: 'active' },
      },
      selectedUserRoles: [],
      options: [],
      isCreateClient: this.$route.name === 'create-client',
    };
  },
  watch: {
    userIsActive(newValue) {
      this.newUser.status = {
        ...this.newUser.status,
        name: newValue ? 'ACTIVE' : 'INACTIVE',
      };
    },
  },
  computed: {
    ...mapState({
      managers: (state) => state.experts.managers,
      noRoleUsers: (state) => state.experts.noRoleUsers,
      roles: (state) => state.resources.roles,
    }),
    ...mapGetters({
      canManageUsers: 'auth/canManageUsers',
      expertStatusFromId: 'experts/expertStatusFromId',
    }),
    currentUsers() {
      return this.noRoleUsers.filter((noRoleUser) => {
        return (
          this.roleUsers.findIndex((roleUser) => {
            const genericIdsComparison = roleUser.id === noRoleUser.id;
            const isEditIdsComparison =
              this.isEdit && roleUser.expertId === noRoleUser.id;
            return genericIdsComparison || isEditIdsComparison;
          }) === -1
        );
      });
    },

    checkInputsLength() {
      const { firstName, lastName, email, title } = this.newUser;
      return (
        firstName.length < 101 &&
        lastName.length < 101 &&
        title.length < 101 &&
        email.length < 151
      );
    },

    isValidRep() {
      const { firstName, lastName, phone, title } = this.newUser;
      switch (this.currentTab) {
        case 0:
          return (
            !!this.selectedCurrentUserExpertId &&
            this.selectedUserRoles.length > 0
          );

        case 1:
          return (
            !!firstName &&
            !!lastName &&
            !!phone &&
            !!this.emailStatus &&
            !!title &&
            this.checkInputsLength &&
            this.selectedUserRoles.length > 0
          );

        default:
          return false;
      }
    },
    validEmail() {
      return (
        !!this.newUser.email &&
        /^[a-zA-Z+0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          this.newUser.email
        )
      );
    },
    emailStatus() {
      return this.newUser.email === '' ? null : this.validEmail;
    },
  },
  created() {
    this.currentTab = this.isCreateClient ? 1 : 0;
  },
  methods: {
    ...mapActions({
      fetchRoles: 'resources/dispatchFetchRoles',
      fetchNoRoleExperts: 'experts/dispatchAuthLevelUsers',
    }),
    changeCurrentUser(id) {
      if (id) {
        const {
          user: { firstName, lastName, phone, email, title },
        } = this.noRoleUsers.filter((i) => i.id === id)[0];
        this.newUser = { firstName, lastName, phone, email, title, id };
      } else {
        this.selectedUserRoles = [];
      }
    },
    resetForm() {
      Object.keys(this.newUser).map((key) => (this.newUser[key] = ''));
      this.newUser.status = { name: this.userIsActive ? 'ACTIVE' : 'INACTIVE' };
      this.selectedUserRoles = [];
      this.selectedCurrentUserExpertId = '';
    },
    showModal() {
      this.fetchRoles({
        'filter[auth_level]': this.authLevel,
      });
      !this.isCreateClient &&
        this.fetchNoRoleExperts({
          'filter[type]': 'client',
          'filter[client_id]': this.clientId,
        });
      this.modalShow = true;
    },
    add(type) {
      if (type === 'existing') {
        const status = this.expertStatusFromId(
          this.selectedCurrentUserExpertId
        );
        this.newUser.status = { ...status };
        this.newUser.expertId = this.selectedCurrentUserExpertId;
      }
      this.$emit('add', {
        ...this.newUser,
        type,
        roles: [...this.selectedUserRoles],
      });
      this.modalShow = false;
      this.resetForm();
    },
    userNotAlreadyAdded() {
      return (
        this.roleUsers.findIndex(
          (roleUser) =>
            roleUser.email === this.newUser.email ||
            roleUser.phone === this.newUser.phone
        ) === -1
      );
    },
    async validateAndAdd() {
      if (this.userNotAlreadyAdded()) {
        if (this.currentTab === 0) {
          this.add('existing');
        }
        if (this.currentTab === 1) {
          this.addingRoleUser = true;
          try {
            const res = await fetchUsers({
              'filter[email|phone]': `${this.newUser.email}|${this.newUser.phone}`,
            });
            res === 'not found'
              ? this.add('new')
              : this.$toast.error(
                  !res?.data.length
                    ? this.$t('error_user_already_exists')
                    : this.$t('error_something_went_wrong')
                );
          } catch (error) {
            this.$toast.error(
              error.status === 500
                ? this.$t('error_user_already_exists')
                : this.$t(error.data.msg) ||
                    this.$t('error_something_went_wrong')
            );
          } finally {
            this.addingRoleUser = false;
          }
        }
      } else {
        this.$toast.error(this.$t('error_user_already_exists'));
      }
    },
  },
};
</script>

<style></style>
