import request from '@/utils/api.utils';

/**
 * POST: Request SignIn with credentials
 */
export function login(params) {
  const url = '/auth/web/signin';
  return request.post(url, params);
}

/**
 * POST: Reset password request
 */
export function passwordReset(params) {
  return request.post('/auth/restore-password', params);
}

/**
 * PUT: Update password
 */
export function passwordUpdate(params) {
  return request.put('/auth/restore-password', params);
}

/**
 * POST: Logout
 */
export function logout() {
  return request.post('/auth/signout');
}
