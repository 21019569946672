export default {
  path: 'user-management',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'roles',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '',
          name: 'list-roles',
          component: () =>
            import(
              /* webpackChunkName: "listRoles" */ '../views/UserManagement/Roles/Index.vue'
            ),
        },
        {
          path: 'add',
          name: 'create-role',
          component: () =>
            import(
              /* webpackChunkName: "createRole" */ '../views/UserManagement/Roles/Create.vue'
            ),
        },
        {
          path: ':id',
          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'view-role',

              component: () =>
                import(
                  /* webpackChunkName: "viewRole" */ '../views/UserManagement/Roles/View.vue'
                ),
            },

            {
              path: 'edit',
              name: 'edit-role',

              component: () =>
                import(
                  /* webpackChunkName: "editRole" */ '../views/UserManagement/Roles/Edit.vue'
                ),
            },
          ],
        },
      ],
    },
  ],
};
