<template>
  <v-client-table
    :data="teamMembersListTableData"
    :columns="columns"
    :options="options"
  >
    <template slot="name" slot-scope="props">
      {{ props.row.firstName + ' ' + props.row.lastName }}
    </template>
    <template v-if="isSiteView" slot="siteName">
      {{ site.basic.name }}
    </template>
    <template slot="status" slot-scope="props">
      <WebBadgeComponent :status="props.row.status" />
    </template>
    <template slot="actions" slot-scope="props">
      <div class="opacity-5 text-right">
        <update-user
          title="Update Team Member"
          authLevel="site"
          :member="props.row"
          :listOfMembers="teamMembersListTableData"
          @updateNewUser="updateMember"
        />
        <i
          @click="deleteTeamMember(props.row)"
          class="far fa-trash-alt ml-2 cursor-pointer"
        ></i>
      </div>
    </template>
  </v-client-table>
</template>

<script>
import UpdateUser from '@/components/UpdateUser.vue';
import { mapState } from 'vuex';
import WebBadgeComponent from '@/shared/components/WebBadgeComponent.vue';
import { updateExpert } from '@/api/experts';
import { updateTeamMember } from '@/api/members';

export default {
  name: 'TeamMembersTable',
  components: {
    WebBadgeComponent,
    UpdateUser,
  },
  props: {
    teamMembersListTableData: Array,
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    isSiteView: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      siteParams: ({ sites }) => sites.siteParams,
    }),
  },
  created() {
    if (this.isSiteView) this.addSiteNameColumn();
  },
  data() {
    return {
      actedMember: '',
      showConfirmation: false,
      loading: false,
      columns: ['name', 'email', 'phone', 'status', 'actions'],
      options: {
        headings: {
          email: this.$t('email'),
          siteName: this.$t('site'),
          actions: '',
        },
        sortable: ['siteName', 'name'],
        perPage: 9999,
      },
    };
  },
  methods: {
    async updateMember(user) {
      try {
        this.loading = true;

        if (user.fieldsChanged) {
          const { firstName, lastName, phone, email, title, status, expertId } =
            user;
          await updateExpert(expertId, {
            firstName,
            lastName,
            phone,
            email,
            title,
            status,
          });
        }

        if (user.userRolesChanged) {
          const { clientId, studyId, protocolId, siteId } = this.siteParams;
          const { id: memberId } = user;
          await updateTeamMember(
            {
              clientId,
              studyId,
              protocolId,
              siteId,
              memberId,
            },
            {
              roles: this.selectedUserRoles,
            }
          );
        }

        this.$emit('getTeamMembers');
      } catch (error) {
        this.displayError('Error update team member', error);
      } finally {
        this.loading = false;
      }
    },
    deleteTeamMember(row) {
      this.showConfirmation = true;
      this.$emit('deleteTeamMember', row);
    },
    addSiteNameColumn() {
      this.columns.splice(3, 0, 'siteName');
    },
  },
};
</script>
