<template>
  <li class="tabs-item">
    <router-link :to="link" class="tab-link text-sm">
      <slot></slot>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    link: Object,
  },
};
</script>

<style lang="scss">
.tabs-item {
  list-style: none;

  &:not(:last-child) {
    padding-right: 1rem;
  }
  .tab-link {
    text-transform: capitalize;
    padding: 0.75rem 1rem;
    border-radius: 50rem;
    background-color: #fff;
    color: #046db3;
    &.active {
      background-color: #046db3;
      color: white;
    }
  }
}
</style>
