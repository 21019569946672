<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="The Admin"
      title="The Admin"
    >
      <template slot="links" v-if="navigatorPermissions">
        <sidebar-item
          :link="{
            name: $t('Navigation'),
            path: '/navigation',
            linkActive: $route.matched[1].path === '/navigation',
            iconType: 'fill',
          }"
          title="Navigation"
        >
          <template v-slot:icon>
            <HomeIcon />
          </template>
        </sidebar-item>
      </template>

      <template slot="links" v-else>
        <sidebar-item
          :link="{
            name: $t('home'),
            path: '/dashboard',
            linkActive: $route.matched[1].path === '/dashboard',
            iconType: 'fill',
          }"
          title="Home"
        >
          <template v-slot:icon>
            <HomeIcon />
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="!isClientUser"
          :link="{
            name: $t('clients'),
            icon: 'clients-icon',
            path: '/clients',
            linkActive: $route.matched[1].path === '/clients',
            iconType: 'fill',
          }"
          title="Clients"
        >
          <template v-slot:icon>
            <ClientIcon />
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('studies'),
            icon: 'book-icon',
            path: '/studies',
            linkActive: $route.matched[1].path === '/studies',
            iconType: 'fill',
          }"
          title="Studies"
        >
          <template v-slot:icon>
            <BookIcon />
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('protocols'),
            icon: 'protocols-icon',
            path: '/protocols',
            linkActive: $route.matched[1].path === '/protocols',
            iconType: 'fill',
          }"
          title="Protocols"
        >
          <template v-slot:icon>
            <ProtocolsIcon />
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sites'),
            icon: 'sites-icon',
            path: '/sites',
            linkActive: $route.matched[1].path === '/sites',
            iconType: 'fill',
          }"
          title="Sites"
        >
          <template v-slot:icon>
            <SitesIcon />
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('participants'),
            path: '/participants',
            linkActive: $route.matched[1].path === '/participants',
            iconType: 'fill',
          }"
          title="Participants"
        >
          <template v-slot:icon>
            <ParticipantsIcon />
          </template>
        </sidebar-item>
      </template>

      <template v-if="navigatorPermissions != true" v-slot:links-after>
        <template v-if="!isClientUser">
          <h6 class="navbar-heading pl-4" v-show="sidebarVisibility">
            {{ $t('tools') }}
          </h6>
          <sidebar-item
            :link="{
              name: $t('user_management'),
              icon: 'user-icon',
              path: '/user-management/roles',
              linkActive: $route.matched[1].path === '/user-management',
              hasChild: true,
              iconType: 'fill',
              id: 'user-management',
            }"
            title="User Management"
          >
            <template v-slot:icon>
              <UserIcon class="mr-0" />
            </template>
            <template v-slot:child>
              <router-link
                :to="{ name: 'list-roles' }"
                class="nav-child-link"
                :class="
                  $route.matched[2] &&
                  $route.matched[2].path === '/user-management/roles' &&
                  'nav-child-link-active'
                "
              >
                <span
                  class="ml-1"
                  v-if="
                    $route.matched[2] &&
                    $route.matched[2].path === '/user-management/roles'
                  "
                >
                  &bull;
                </span>
                {{ $t('roles') }}
              </router-link>
            </template>
          </sidebar-item>
        </template>

        <sidebar-item
          class="special-class"
          :link="{
            name: $t('reports'),
            icon: 'report-icon',
            path: '/reports',
            linkActive: $route.matched[1].path === '/adherence-report',
            hasChild: true,
            iconType: 'fill',
            id: 'adherence-reports',
          }"
          title="Reports"
        >
          <template v-slot:icon>
            <ReportIcon class="mr-0 report-icon" />
          </template>
          <template v-slot:child>
            <router-link
              :to="{ name: 'adherence-reports' }"
              class="nav-child-link"
              :class="
                $route.matched[2] &&
                $route.matched[2].path === '/adherence-report' &&
                'nav-child-link-active'
              "
            >
              <span
                class="ml-1"
                v-if="
                  $route.matched[2] &&
                  $route.matched[2].path === '/adherence-report'
                "
              >
              </span>
              <AdherenceIcon class="adherence-icon mr-2" />
              {{ $t('participant_adherence') }}
            </router-link>
          </template>
        </sidebar-item>

        <button class="sidebar-button" @click="handleAddParticipant">
          <AddParticipantIcon />
          <span>{{ $t('participant_add') }}</span>
        </button>

        <sidebar-item
          v-if="hasReadLogsRights"
          :link="{
            name: 'Audit Logs',
            icon: 'audit-logs-icon',
            path: '/audit-logs',
          }"
        >
          <template v-slot:icon>
            <AuditLogsIcon class="mr-0" />
          </template>
        </sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Data Visualisation',
            icon: 'data-icon',
            path: '/sites',
          }"
        >
          <template v-slot:icon>
            <DataIcon />
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Survey Builder',
            icon: 'survey-icon',
            path: '/sites',
          }"
        >
          <template v-slot:icon>
            <SurveyIcon />
          </template>
        </sidebar-item> -->
      </template>
    </side-bar>
    <div
      class="main-content"
      :class="{ wide: !sidebarVisibility }"
      :data="sidebarBackground"
    >
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar" class="">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <div>
        <modal-idle v-if="isIdle" />
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters, mapState } from 'vuex';
import ClientIcon from '@/components/Icons/ClientIcon.vue';
import BookIcon from '@/components/Icons/BookIcon.vue';
// import DataIcon from "@/components/Icons/DataIcon.vue";
// import SurveyIcon from "@/components/Icons/SurveyIcon.vue";
import AuditLogsIcon from '@/components/Icons/AuditLogsIcon.vue';
import HomeIcon from '@/components/Icons/HomeIcon.vue';
import SitesIcon from '@/components/Icons/SitesIcon.vue';
import ParticipantsIcon from '@/components/Icons/ParticipantsIcon';
import ProtocolsIcon from '@/components/Icons/ProtocolsIcon.vue';
import ModalIdle from '@/components/ModalIdle';
import UserIcon from '@/components/Icons/UserIcon.vue';
import ReportIcon from '@/components/Icons/ReportIcon.vue';
import AddParticipantIcon from '@/components/Icons/AddParticipantIcon.vue';
import AdherenceIcon from '@/components/Icons/AdherenceIcon.vue';

export default {
  components: {
    DashboardNavbar,
    FadeTransition,
    ClientIcon,
    BookIcon,
    // DataIcon,
    // SurveyIcon,
    AuditLogsIcon,
    HomeIcon,
    SitesIcon,
    ParticipantsIcon,
    ProtocolsIcon,
    UserIcon,
    ReportIcon,
    AddParticipantIcon,
    ModalIdle,
    AdherenceIcon,
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    ...mapState({
      sidebarVisibility: (state) => state.sidebar.visible,
    }),
    ...mapGetters({
      isClientUser: 'auth/isClientUser',
      hasReadLogsRights: 'auth/hasReadLogsRights',
    }),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    handleAddParticipant() {
      this.$store.commit('participants/SET_TOGGLE_PARTICIPANT_MODAL');
    },
  },
};
</script>
