<template>
  <div class="p-5">
    <ListFiltersComponent
      v-if="showListFilters"
      :filtersToDisplay="filtersToDisplay"
      @changeFilterEvent="handleChangeFilterEvent"
    />
    <ProtocolList :filterData="filterProtocolsData" @goToDetail="goToDetail" />
  </div>
</template>

<script>
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import ProtocolList from '@/components/Protocol/List/ProtocolList.vue';
import { mapState } from 'vuex';
import isClientUserMixin from '@/mixins/isClientUserMixin';

export default {
  name: 'ProtocolListContainerView',
  components: {
    ListFiltersComponent,
    ProtocolList,
  },
  mixins: [isClientUserMixin],
  data() {
    return {
      filterProtocolsData: {},
      filtersToDisplay: {
        client: false,
        study: true,
        protocol: false,
        site: false,
      },
      showListFilters: false,
    };
  },
  created() {
    this.setBreadCrumbData({
      protocols: [
        {
          text: this.$t('protocols'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      userType: ({ auth }) => auth.userType,
    }),
  },
  methods: {
    goToDetail(protocolId) {
      this.$router.push({ name: 'view-protocol', params: { id: protocolId } });
    },
    handleChangeFilterEvent(newFilter) {
      this.filterProtocolsData = newFilter;
    },
  },
};
</script>
