import {
  fetchParticipantRelapses,
  getParticipantRelapse,
} from '@/api/relapses';

const initialState = {
  list: [],
  selectedRelapse: {},
  relapsePrams: {},
};
const state = { ...initialState };

const getters = {
  relapsesList: (state) => state.list,
};

const mutations = {
  SET_RELAPSES: (state, relapses) => {
    state.list = relapses;
  },
  SET_SELECTED_RELAPSE: (state, relapse) => {
    state.selectedRelapse = relapse;
  },
  SET_RELAPSE_PARAMS: (state, params) => {
    state.relapsePrams = params;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchParticipantRelapses({ commit }, payload) {
    try {
      const { data } = await fetchParticipantRelapses(payload);
      commit('SET_RELAPSES', data);
    } catch (error) {
      commit('SET_RELAPSES', []);
      return error;
    }
  },
  async dispatchFetchRelapse({ commit }, payload) {
    try {
      const { data } = await getParticipantRelapse(payload);
      commit('SET_SELECTED_RELAPSE', data);
    } catch (error) {
      commit('SET_SELECTED_RELAPSE', {});
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
