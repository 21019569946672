<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.32604 8.73436L1.43438 5.8427C1.27868 5.68665 1.06731 5.59896 0.846875 5.59896C0.626444 5.59896 0.415068 5.68665 0.259375 5.8427C-0.065625 6.1677 -0.065625 6.6927 0.259375 7.0177L3.74271 10.501C4.06771 10.826 4.59271 10.826 4.91771 10.501L13.7344 1.68436C14.0594 1.35936 14.0594 0.834363 13.7344 0.509363C13.5787 0.353319 13.3673 0.265625 13.1469 0.265625C12.9264 0.265625 12.7151 0.353319 12.5594 0.509363L4.32604 8.73436Z"
      fill="#046DB3"
    />
  </svg>
</template>
  
  <script>
export default {};
</script>
  
  <style></style>