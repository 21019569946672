import AuthLayout from '@/layout/AuthLayout';

export default {
  path: '/',
  redirect: 'login',
  component: AuthLayout,
  children: [
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    },
    {
      path: 'password',
      name: '',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: 'forgot',
          name: 'password-reset',
          component: () =>
            import(
              /* webpackChunkName: "reset password" */ '../views/PasswordReset.vue'
            ),
        },
      ],
    },
    {
      path: '/password-update',
      name: 'update-password',
      component: () =>
        import(/* webpackChunkName: "Login" */ '../views/PasswordUpdate.vue'),
    },
  ],
};
