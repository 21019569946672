import axios from 'axios';
import store from '@/store';
import { isNil } from 'lodash/lang';
import { apiVersion, apiVersionUrlSegment } from '@/constants/api-constants';

const apiBaseUrl = () => {
  const baseDomain = process.env.VUE_APP_BASE_DOMAIN;
  let baseUrl = process.env.VUE_APP_BASE_URL;

  if (window.location.hostname.indexOf(baseDomain) > 0) {
    baseUrl =
      window.location.protocol +
      '//' +
      window.location.hostname.replace('data', 'api');
  }

  return baseUrl;
};

const apiVersionRequestInterceptor = (config) => {
  const { apiVersion: apiVersionHeader, ...otherHeaders } = config.headers;
  const isVersionTwo =
    !isNil(apiVersionHeader) && apiVersionHeader === apiVersion.two;
  const apiVersionSegment = isVersionTwo
    ? apiVersionUrlSegment.versionTwo
    : apiVersionUrlSegment.versionOne;

  config.headers = otherHeaders;
  config.baseURL += apiVersionSegment;

  return config;
};

const authRequestInterceptor = (config) => {
  if (store.getters.token && config.url !== '/auth/signin') {
    config.headers['Authorization'] = 'Bearer ' + store.getters.token;
  }
  return config;
};

const errorRequestInterceptor = (error) => Promise.reject(error);

const dataResponseInterceptor = (response) => {
  return isNil(response.data) ? { data: [] } : response.data;
};

const errorResponseInterceptor = (error) => {
  if (isNil(error.response)) {
    return Promise.reject('HTTP error!');
  } else if (
    error.response.status === 401 ||
    error.response.data.code === 401
  ) {
    const isLogout = error.response.config.url.includes('/auth/signout');
    if (!isLogout && store.getters.token) {
      store.dispatch('auth/logout').then();
    }
  } else if (error.response.status === 404) {
    return Promise.resolve('not found');
  } else {
    return Promise.reject(error.response);
  }
};

const instance = axios.create({
  baseURL: apiBaseUrl(),
  timeout: process.env.VUE_APP_REQUESTS_TIMEOUT,
});

instance.interceptors.request.use(
  apiVersionRequestInterceptor,
  errorRequestInterceptor
);
instance.interceptors.request.use(
  authRequestInterceptor,
  errorRequestInterceptor
);

instance.interceptors.response.use(
  dataResponseInterceptor,
  errorResponseInterceptor
);
// response interceptor

export default instance;
