import request from '@/utils/api.utils';

/**
 * GET: fetch experts list
 */
export function fetchExperts(params) {
  const url = `/experts`;
  return request.get(url, { params });
}

/**
 * POST: Creates a client expert
 */
export function createExpert(params) {
  const url = `/experts`;
  return request.post(url, params);
}

/**
 * PUT: Update a expert
 */
export function updateExpert(expertId, params) {
  const url = `/experts/${expertId}`;
  return request.put(url, params);
}

/**
 * PUT: Update a language
 */
export function updateLanguage(language) {
  const url = `update-language/${language}`;
  return request.put(url);
}

/**
 * PUT: Change password
 */
export function changePassword(expertId, params) {
  const url = `/experts/${expertId}/change-password`;
  return request.put(url, params);
}
