export default {
  data() {
    return {
      tableClass: '',
      highlightTagText: '',
      timeout: null,
      showHighlightTag: this.$route.params.isNewlyCreated,
      highlightDuration: 10000,
    };
  },

  mounted() {
    if (this.showHighlightTag) {
      if (this.$route.params.isNewlyCreated) this.highlightTagText = 'NEW';

      this.tableClass = 'highlighted';
      this.timeout = setTimeout(() => {
        this.tableClass = '';
        this.highlightTagText = '';
      }, this.highlightDuration);
    }
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
};
