import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

export function fetchProtocolsV2(params) {
  const url = '/protocols';
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { params, headers });
}

/**
 * GET: get the list of protocols against a study
 */
export function fetchProtocols(params) {
  const url = `/protocols?sort=-created_at`;
  return request.get(url, { params });
}

/**
 * GET: fetch the list of all protocols
 */
export function fetchAllProtocols() {
  const url = `/protocols`;
  return request.get(url);
}

export function fetchProtocolsServerSide(params) {
  const url = `protocols`;
  return request.get(url, { params });
}
/**
 * GET: Retrieve a Protocol Approver
 */
export function getProtocolApprover({ clientId, studyId, protocolId }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/approver`;
  return request.get(url);
}

/**
 * POST: submit a new protocol against a client study
 */
export function createProtocol({ clientId, studyId, payload }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols`;
  return request.post(url, payload);
}

/**
 * POST: submit integrations for protocol
 */
export function createIntegrations({ clientId, studyId, protocolId }, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/integrations`;
  return request.post(url, payload);
}

/**
 * POST: Submit Test & Games / Surveys schedule
 */
export function createSchedule({ clientId, studyId, protocolId }, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/schedule`;
  return request.post(url, payload);
}

/**
 * GET: fetch the details of a protocol
 */
export function getProtocolDetail({ clientId, studyId, protocolId }) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}`;
  return request.get(url);
}

/**
 * POST: Create a protocol approver from an expertId
 */
export function createApprover(clientId, studyId, protocolId, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/approver`;
  return request.post(url, payload);
}

/**
 * POST: approve protocol
 */
export function approveProtocol(clientId, studyId, protocolId) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/approve`;
  return request.post(url);
}

/**
 * POST: Reject protocol
 */
export function rejectProtocol(clientId, studyId, protocolId, reason) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/reject`;
  return request.post(url, { reason });
}

/**
 * PUT: update a protocol
 */
export function updateProtocol({ clientId, studyId, protocolId }, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}`;
  return request.put(url, payload);
}

/**
 * PUT: update a protocol schedule
 */
export function updateProtocolSchedule(
  { clientId, studyId, protocolId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/schedule`;
  return request.put(url, payload);
}

/**
 * PUT: update a protocol schedule
 */
export function updateProtocolIntegrations(
  { clientId, studyId, protocolId },
  payload
) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/integrations`;
  return request.put(url, payload);
}

/**
 * PUT: update a protocol approver
 */
export function updateApprover({ clientId, studyId, protocolId }, payload) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/approver`;
  return request.put(url, payload);
}
