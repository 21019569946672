import request from '@/utils/api.utils';
import { apiVersion } from '@/constants/api-constants';

/**
 * GET: fetch participants list for a client
 */
export function fetchAllParticipants(params) {
  const url = `/participants`;
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { params, headers });
}

/**
 * GET: fetch participants list for a client
 */
export function fetchParticipants(params) {
  const url = `/participants?sort=-created_at`;
  return request.get(url, { params });
}

/**
 * POST: submit a new participant
 */
export function createParticipant({
  clientId,
  studyId,
  protocolId,
  siteId,
  body,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants`;
  const headers = { apiVersion: apiVersion.two };
  return request.post(url, body, { headers });
}

/**
 * GET: fetch participant by ID
 */
export function fetchParticipant({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}`;
  return request.get(url);
}

/**
 * GET: fetch participant statistics
 */
export function fetchParticipantStatistics({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  dateStart,
  dateEnd,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/statistics?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  return request.get(url);
}

/**
 * GET: fetch participant relapses list
 */
export function fetchParticipantRelapses(param) {
  const url = `/relapses?sort=-created_at`;
  return request.get(url, { param });
}

/**
 * GET: fetch participant Relapse schedule
 */
export function fetchParticipantRelapseSchedule({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/relapse-schedule`;
  return request.get(url);
}

/**
 * GET: Retrieve a Participant Study Schedule
 */
export function fetchParticipantStudySchedule({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/schedule`;
  return request.get(url);
}

export function fetchParticipantStudyScheduleFromWeek({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  onlyActiveTasks,
  weeksToDisplay,
  dateFrom,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/schedule?onlyActiveTasks=${onlyActiveTasks}&weeksToDisplay=${weeksToDisplay}&dateFrom=${dateFrom}`;
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { headers });
}

/**
 * PUT: update a participant
 */
export function updateParticipant({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
  payload,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}/basic-information`;
  return request.put(url, payload);
}

// update Participant Status
export function updateParticipantStatus({
  clientId,
  studyId,
  protocolId,
  siteId,
  id,
  payload,
}) {
  const { status = 'dropped-out', reason } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/status`;
  return request.put(url, { status, reason });
}

/**
 * Get: fetch participant change records
 */
export function participantsTasksEnabled({
  clientId,
  studyId,
  protocolId,
  siteId,
  participantId,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${participantId}`;
  return request.get(url);
}

/**
 * PUT: update a participant
 */
export function sendExcludedTasks({
  clientId,
  studyId,
  protocolId,
  siteId,
  id,
  payload,
}) {
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/tailor-schedule`;
  return request.put(url, payload);
}

/**
 * POST: submit a note
 */
export function createParticipantNote(payload) {
  const { clientId, studyId, protocolId, siteId, id, textNote } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/notes`;
  return request.post(url, {
    note: textNote,
  });
}

/**
 * GET: get all notes from a participant
 */
export function getAllParticipantNotes(payload) {
  const { clientId, studyId, protocolId, siteId, id } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/notes`;
  return request.get(url);
}

/**
 * PUT: update a participant visit schedule
 */
export function updateVisitSchedule(payload) {
  const { clientId, studyId, protocolId, siteId, id, newDate } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/visit`;
  return request.put(url, newDate);
}

/**
 * POST: discontinued reason
 */
export function sendParticipantDiscontinuedReason(payload) {
  const { clientId, studyId, protocolId, siteId, id, discontinuedReason } =
    payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/discontinue`;
  const headers = { apiVersion: apiVersion.two };
  return request.put(url, discontinuedReason, { headers });
}

/**
 * PUT: Screen out a participant
 */
export function screenOutParticipant(payload) {
  const { clientId, studyId, protocolId, siteId, id, reason } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/screen-out`;
  const headers = { apiVersion: apiVersion.two };
  return request.put(url, { reason }, { headers });
}

/**
 * PUT: Activate a participant
 */
export function activateParticipant(payload) {
  const { clientId, studyId, protocolId, siteId, id } = payload;
  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/${id}/activate`;
  const headers = { apiVersion: apiVersion.two };
  const under = '';
  return request.put(url, under, { headers });
}

/**
 * GET: Activate a participant
 */
export function checkAvailabilityForStudy(payload) {
  const { clientId, studyId, protocolId, siteId, subjectId } = payload;

  const url = `/clients/${clientId}/studies/${studyId}/protocols/${protocolId}/sites/${siteId}/participants/check-availability-for-study?subjectId=${subjectId}`;
  const headers = { apiVersion: apiVersion.two };
  return request.get(url, { headers });
}
