<template>
  <div>
    <cardStudyInfo
      class="mt-4"
      :cardTitle="titleStudyInfo"
      :selectedObject="selectedParticipant"
      :keysList="keyListStudyInfo"
      :keyNames="tagNames"
      :keyOrder="tagsOrder"
    ></cardStudyInfo>

    <cardParticipantInfo
      class="mt-4"
      :cardTitle="titleParticipantInfo"
      :selectedObject="selectedParticipant"
      :keysList="keyListParticipant"
      :keyNames="tagNamesParticipant"
      :keyOrder="tagsOrderParticipant"
      :partners="partners"
    ></cardParticipantInfo>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="primary"
          @click="handleNextClick"
          rounded
          data-testid="next-button"
        >
          {{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </div>
</template>
        
<script>
import { mapGetters, mapState } from 'vuex';
import cardStudyInfo from '@/components/InfoCards/cardStudyInfo';
import cardParticipantInfo from '@/components/InfoCards/cardParticipantInfo';
import cardStudyInfoMixin from '@/mixins/cardStudyInfoMixin';

export default {
  components: {
    cardStudyInfo,
    cardParticipantInfo,
  },
  mixins: [cardStudyInfoMixin],
  data() {
    return {
      testOne: false,
      tesTwo: false,
      titleStudyInfo: 'study_information',
      titleParticipantInfo: 'participant_information',
      keyListStudyInfo: [
        'studyName',
        'protocolName',
        'siteName',
        'dateStart',
        'dateEnd',
      ],
      tagNames: {
        studyName: 'study',
        protocolName: 'protocol',
        siteName: 'site',
        dateStart: 'study_start_date',
        dateEnd: 'study_end_date_planned',
      },
      tagsOrder: {
        studyName: 1,
        protocolName: 2,
        siteName: 3,
        dateStart: 4,
        dateEnd: 5,
      },
      keyListParticipant: [
        'phone',
        'subjectCode',
        'tags',
        'gender',
        'height',
        'weight',
      ],
      tagNamesParticipant: {
        phone: 'phone_number_mobile',
        subjectCode: 'participant_id_subject',
        tags: 'type',
        gender: 'gender',
        height: 'height',
        weight: 'weight',
      },
      tagsOrderParticipant: {
        phone: 1,
        subjectCode: 2,
        tags: 3,
        gender: 4,
        height: 5,
        weight: 6,
      },
      partners: [],
      participantInfoCardData: {},
    };
  },
  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({
      extractStudyCardInfo: 'participants/extractStudyCardInfo',
    }),
  },

  created() {
    this.testOne = true;
    this.partners = this.selectedParticipant.partners;
  },
  mounted() {
    this.tesTwo = true;
  },
  methods: {
    async handleNextClick() {
      this.loading = true;
      this.componentKey += 1;
      this.$emit('nextStep');
      this.loading = false;
    },
  },
};
</script>
        
<style></style>
        