<template>
  <div v-if="sections.length">
    <participant-adherence-schedule-chart
      :adherence-statistics="sections"
    ></participant-adherence-schedule-chart>
  </div>
</template>

<script>
import ParticipantAdherenceScheduleChart from '@/components/Reports/ParticipantAdherenceScheduleChart.vue';
export default {
  name: 'ParticipantAdherenceScheduleContainer',

  components: {
    ParticipantAdherenceScheduleChart,
  },

  props: {
    statisticsData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sections: [],
    };
  },

  created() {
    this.transformData();
  },

  methods: {
    transformData() {
      const completedRanges = [
        { range: '100% adh.', min: 100, max: 100, color: '#009648' },
        { range: '99% - 80% adh.', min: 80, max: 99, color: '#67EBA6' },
        { range: '79% - 60% adh', min: 60, max: 79, color: '#F5E344' },
        { range: '59% -40% adh', min: 40, max: 59, color: '#FF9C5A' },
        { range: '39% -20% adh', min: 20, max: 39, color: '#FF6C45' },
        { range: '19% - 0% adh', min: 0, max: 19, color: '#D15259' },
      ];

      const totalObjects = this.statisticsData.length;

      this.sections = completedRanges.map((range) => {
        const count = this.statisticsData.filter(
          (item) =>
            item.total_completed_percent >= range.min &&
            item.total_completed_percent <= range.max
        ).length;
        const percentage = (count / totalObjects) * 100;

        return {
          label: range.range,
          value: +percentage.toFixed(2),
          number: count,
          color: range.color,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
