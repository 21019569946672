<template>
  <div>
    <participants-status-chart
      :adherenceStatistics="transformedData"
      :totalParticipants="totalParticipantsNumber"
    ></participants-status-chart>
  </div>
</template>

<script>
import ParticipantsStatusChart from '@/components/Reports/ParticipantsStatusChart.vue';
export default {
  name: 'ParticipantChartStatusContainer',

  components: {
    ParticipantsStatusChart,
  },

  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataObject: [],
      transformedData: [],
      totalParticipantsNumber: 0,
    };
  },

  created() {
    this.transformData();
    this.getTotal();
  },

  methods: {
    getTotal() {
      this.totalParticipantsNumber = this.statisticsData.total;
    },

    getColor(key) {
      switch (key) {
        case 'attention':
          return '#f58b44';
        case 'complete':
          return '#529a0a';
        case 'discontinued':
          return '#badefc';
        case 'critical':
          return '#ff4b56';
        case 'relapse':
          return '#d15258';
        case 'ontrack':
          return '#4dc185';
        default:
          return 'black';
      }
    },
    transformData() {
      const transformedData = { ...this.statisticsData.tags };
      if ('on-track' in transformedData) {
        transformedData.ontrack = transformedData['on-track'];
        delete transformedData['on-track'];
      }

      this.transformedData = Object.entries(transformedData).map(
        ([key, value]) => ({
          label: key,
          value: value.percentage,
          number: value.count,
          color: this.getColor(key),
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
