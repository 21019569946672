export function reduceKeys(original, changed) {
  let obj = {};
  return Object.keys(original).reduce((obj, key) => {
    if (original[key] !== changed[key] && key in changed) {
      obj[key] = changed[key];
    }
    return obj;
  }, obj);
}

export function hasKeysLength(object) {
  return Object.keys(object).length > 0;
}

export const notificationsDiff = (updated, old) => {
  return (
    updated.filter(
      ({ id }) => !old.some((notification) => notification.id === id)
    ).length > 0
  );
};
/**
 * Convert a boolean day value to number
 */
export function convertDay(day) {
  return day ? 1 : 0;
}

export const calcPercentage = (partialValue, totalValue) => {
  return Number((100 * partialValue) / totalValue).toFixed(1);
};

export function randomString(length = 7) {
  const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let text = '';

  for (let i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );

  return text;
}

export function statusKeyClone(list) {
  return list.map((item) => ({
    ...item,
    statusName: item.status.name.toLowerCase(),
  }));
}
