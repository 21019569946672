<template>
  <div
    class="bg-white d-flex flex-row justify-content-between align-items-center px-5 py-3 border-bottom"
  >
    <h2 class="topbar-title flex-grow-1 mb-0">{{ title }}</h2>
    <i class="fas fa-times" @click="$emit('goBack')"></i>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style></style>
