import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      canCreateClient: 'auth/canCreateClient',
      canReadClient: 'auth/canReadClient',
      canReadStudy: 'auth/canReadStudy',
      canCreateStudy: 'auth/canCreateStudy',
      canReadProtocol: 'auth/canReadProtocol',
      canCreateProtocol: 'auth/canCreateProtocol',
      canReadSite: 'auth/canReadSite',
      canWriteSite: 'auth/canWriteSite',
      canCreateSite: 'auth/canCreateSite',
      canReadParticipant: 'auth/canReadParticipant',
      canWriteParticipant: 'auth/canWriteParticipant',
      canCreateParticipant: 'auth/canCreateParticipant',
      canApproveProtocol: 'auth/canApproveProtocol',
      navigatorPermissions: 'auth/navigatorPermissions',

      isClientUser: 'auth/isClientUser',
    }),
  },
};
