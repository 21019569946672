<template>
  <div>
    <b-spinner class="mx-auto d-block" v-if="loading"></b-spinner>
    <div v-else>
      <div class="clearfix" v-if="showUpdateProtocol">
        <base-button
          @click="goForEdit"
          type="primary"
          class="float-right action-button"
        >
          {{ $t('protocol_update') }}</base-button
        >
      </div>
      <overview :protocol="protocolDetails" class="mt-4" />
      <b-row class="my-5" v-if="ShowButtons">
        <b-col>
          <base-button
            :disabled="performingProtocolAction"
            type="primary"
            data-testid="accept-button"
            @click="handleApproveProtocol"
            >{{ $t('protocol_approve') }}
          </base-button>
          <base-button
            type="secondary"
            @click="showRejectModal = true"
            data-testid="reject-button"
            :disabled="performingProtocolAction"
            >{{ $t('protocol_reject') }}
          </base-button>
        </b-col>
      </b-row>
    </div>

    <!-- Reject Protocol Modal -->
    <b-modal
      v-model="showRejectModal"
      :static="true"
      id="protocol-modal"
      hide-header
      no-close-on-backdrop
    >
      <div class="border-bottom pb-3 text-center text-lg font-weight-600">
        {{ $t('protocol_study_reject') }}
      </div>
      <label class="text-sm font-weight-600" for=""
        >{{ $t('protocol_reject_enter_reason') }} *</label
      >
      <b-form-textarea
        id="textarea"
        v-model="reason"
        data-testid="reason-input"
        placeholder="Enter a reject reason"
        rows="3"
      ></b-form-textarea>
      <template slot="modal-footer">
        <base-button
          type="primary"
          :disabled="performingProtocolAction || !this.reason"
          @click="handleProtocolReject"
          data-testid="modal-reject-button"
          >{{ $t('reject') }}
          <b-spinner small v-if="performingProtocolAction"></b-spinner>
        </base-button>

        <base-button
          type="secondary"
          @click="showRejectModal = false"
          :disabled="performingProtocolAction"
          data-testid="modal-cancel-button"
          >{{ $t('cancel') }}
        </base-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Overview from '@/components/Protocol/Overview.vue';
import { approveProtocol, rejectProtocol } from '@/api/protocol';

export default {
  components: { Overview },
  data() {
    return {
      showRejectModal: false,
      performingProtocolAction: false,
      loading: false,
      protocolDetails: {},
      days: [
        'dayOne',
        'dayTwo',
        'dayThree',
        'dayFour',
        'dayFive',
        'daySix',
        'daySeven',
      ],
      reason: '',
      isClientView: this.$route.name === 'client-view-protocol',
    };
  },
  computed: {
    ...mapState({
      client: (state) => state.clients.selectedClient,
      study: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.selectedProtocol,
      protocolParams: (state) => state.protocols.protocolParams,
    }),
    isRejected() {
      return this.protocol.status.name === 'REJECTED';
    },
    ShowButtons() {
      return this.protocol.status.name === 'WAITING FOR APPROVAL';
    },
    showUpdateProtocol() {
      return (
        this.protocol?.study?.status?.name === 'ACTIVE' &&
        !['INACTIVE', 'ACTIVE', 'WAITING FOR APPROVAL'].includes(
          this.protocol.status.name
        ) &&
        this.canCreateProtocol
      );
    },
    showProtocolActions() {
      return (
        this.protocol.study?.status?.name === 'ACTIVE' &&
        this.protocol.status.name === 'WAITING FOR APPROVAL' &&
        this.canApproveProtocol(this.protocol.id)
      );
    },
  },
  watch: {
    '$route.params.id': function () {
      this.getProtocolDetails();
    },
  },
  created() {
    this.getProtocolDetails();
  },
  methods: {
    ...mapActions({
      fetchProtocolDetails: 'protocols/fetchProtocolDetails',
      fetchProtocolApprover: 'protocols/dispatchFetchProtocolApprover',
    }),
    async getProtocolDetails() {
      this.loading = true;
      try {
        const payload = {
          clientId: this.protocolParams.clientId,
          studyId: this.protocolParams.studyId,
          protocolId: this.protocolParams.protocolId,
        };
        await this.fetchProtocolDetails(payload);
        await this.setProtocolData();
      } catch (error) {
        this.displayError('Error get protocol details', error);
      } finally {
        this.loading = false;
        this.setBreadCrumbData({
          'client-view-protocol': [
            {
              text: this.$t('clients'),
              to: { name: 'clients' },
            },
            {
              text: this.client.basic?.legalName,
              to: { name: 'client-details' },
            },
            {
              text: this.study?.name,
              to: { name: 'client-study-details' },
            },
            {
              text: this.protocol.name,
              active: true,
            },
          ],
          'study-view-protocol': [
            {
              text: this.$t('studies'),
              to: { name: 'studies' },
            },
            {
              text: this.study?.name,
              to: { name: 'study-details' },
            },
            {
              text: this.protocol.name,
              active: true,
            },
          ],
          'view-protocol': [
            {
              text: this.$t('protocols'),
              to: { name: 'protocols' },
            },
            {
              text: this.protocol.name,
              active: true,
            },
          ],
        });
      }
    },
    async setProtocolData() {
      const payload = {
        clientId: this.protocolParams.clientId,
        studyId: this.protocolParams.studyId,
        protocolId: this.protocolParams.protocolId,
      };
      await this.fetchProtocolApprover(payload);

      const {
        name,
        description,
        studyDurationPerParticipant,
        plannedNumberOfParticipants,
        id,
        approver,
        protocolDataIntegrations,
      } = this.protocol;

      this.protocolDetails.basic = {
        name,
        description,
        studyDurationPerParticipant,
        plannedNumberOfParticipants,
        id,
      };

      if (typeof approver === 'object') {
        this.protocolDetails.approver = { ...approver };
      }

      protocolDataIntegrations.forEach((source) => {
        if (source.dataSource.name === 'Fitbit')
          this.protocolDetails.fitbit = true;
        if (source.dataSource.name === 'Apple Health')
          this.protocolDetails.appleHealth = true;
        if (source.dataSource.name === 'Google Fit')
          this.protocolDetails.googleFit = true;
      });

      this.loading = false;
    },
    generateTest(test) {
      const { testVersion, frequency } = test;
      return {
        name: `${testVersion.test.name} ${testVersion.version}`,
        testVersionId: testVersion.id,
        frequency,
        days: this.days.map((item) => this.convertDay(test[item])),
      };
    },
    async handleProtocolReject() {
      try {
        this.performingProtocolAction = true;
        const { data } = await rejectProtocol(
          this.protocolParams.clientId,
          this.protocolParams.studyId,
          this.protocolParams.protocolId,
          this.reason
        );
        data.id
          ? (this.$router.push({
              name:
                this.$route.name === 'view-protocol'
                  ? 'protocols'
                  : `${this.$route.meta.parent}-protocols`,
            }),
            this.$toast.info(this.$t('toast_protocol_rejected')))
          : this.$toast.error(this.$t('error_something_went_wrong'));
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.performingProtocolAction = false;
      }
    },
    async handleApproveProtocol() {
      try {
        this.performingProtocolAction = true;
        const { data } = await approveProtocol(
          this.protocolParams.clientId,
          this.protocolParams.studyId,
          this.protocolParams.protocolId
        );
        data.id
          ? (this.$router.push({
              name:
                this.$route.name === 'view-protocol'
                  ? 'protocols'
                  : `${this.$route.meta.parent}-protocols`,
            }),
            this.$toast.info(this.$t('toast_protocol_new_created')))
          : this.$toast.error(this.$t('error_something_went_wrong'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.performingProtocolAction = false;
      }
    },
    goForEdit() {
      this.$store.commit('protocols/SET_EDIT_PROTOCOL', this.protocolDetails);
      this.$router.push({
        name:
          this.$route.name === 'view-protocol'
            ? 'edit-protocol'
            : `${this.$route.meta.parent}-edit-protocol`,
      });
    },
    convertDay(day) {
      return day ? 1 : 0;
    },
  },
};
</script>
