export default {
  path: 'sites',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'sites',
      component: () =>
        import(
          /* webpackChunkName: "allSites" */ '../views/Sites/List/SiteListContainerView.vue'
        ),
    },
    {
      path: 'site',
      component: () =>
        import(/* webpackChunkName: "siteView" */ '../views/Sites/View.vue'),

      children: [
        {
          path: '',
          name: 'view-site',
          component: () =>
            import(
              /* webpackChunkName: "siteDetails" */ '../views/Sites/Details.vue'
            ),
        },
        {
          path: 'edit',
          name: 'edit-site',
          meta: {
            parent: 'site',
          },
          component: () =>
            import(
              /* webpackChunkName: "updateSite" */ '../views/Sites/Edit.vue'
            ),
        },
        {
          path: 'members',
          name: 'site-list-team',

          component: () =>
            import(
              /* webpackChunkName: "TeamMembersList" */ '../views/TeamMember/List/TeamMemberListContainer.vue'
            ),
        },

        {
          path: 'participants',
          meta: {
            parent: 'site',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'site-participants',
              meta: {
                parent: 'site',
              },
              component: () =>
                import(
                  /* webpackChunkName: "viewParticipant" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                ),
            },
            {
              path: 'participant',
              name: 'site-view-participant',
              meta: {
                parent: 'site',
              },
              component: () =>
                import(
                  /* webpackChunkName: "viewParticipant" */ '../views/Participants/View.vue'
                ),
            },
            {
              path: 'add',
              name: 'site-create-participant',
              meta: {
                parent: 'site',
              },
              component: () =>
                import(
                  /* webpackChunkName: "createParticipant" */ '../views/Participants/Create.vue'
                ),
            },

            {
              path: 'view-relapse',
              name: 'site-view-relapse',
              meta: {
                parent: 'site',
              },
              component: () =>
                import(
                  /* webpackChunkName: "viewRelapse" */ '../views/Relapses/RelapseDetailView.vue'
                ),
            },

            {
              path: 'create-relapse-schedule',
              name: 'site-create-relapse-schedule',
              meta: {
                parent: 'site',
              },
              component: () =>
                import(
                  /* webpackChunkName: "create-relapse-schedule" */ '../views/Relapses/CreateRelapseView.vue'
                ),
            },
          ],
        },
      ],
    },
  ],
};
