var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('wizard-header',{attrs:{"title":"Update Study Protocol"},on:{"goBack":function($event){_vm.showConfirmationModal = true}}}),_c('div',{staticClass:"bg-white border-bottom"},[_c('div',{staticClass:"p-4"},[_c('step-wizard',{attrs:{"currentStep":_vm.step,"wizardType":"protocol"}})],1)]),_c('div',{staticClass:"bg-white p-5"},[_c('basic-information',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}],attrs:{"basic":_vm.protocol.basic},on:{"nextStep":_vm.handleNextClick}}),_c('protocolApprover',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],attrs:{"delete":_vm.deletedProtocolApprover},on:{"nextStep":_vm.handleApproversNextClick,"back":function($event){_vm.step--}}}),_c('schedule',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 2),expression:"step === 2"}],on:{"nextStep":function($event){_vm.step++},"back":function($event){_vm.step--}}}),_c('external',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 3),expression:"step === 3"}],on:{"back":function($event){_vm.step--},"nextStep":_vm.handleNextClick}}),(_vm.step === 4)?_c('overview',{attrs:{"protocol":_vm.protocol,"submitting":_vm.submitting},on:{"back":function($event){_vm.step--},"updateProtocol":_vm.updateProtocol}}):_vm._e()],1),_c('b-modal',{attrs:{"title":"Update Study Protocol","title-tag":"h3","footer-class":"justify-content-center","header-class":"justify-content-center","hide-header-close":"","no-close-on-backdrop":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push({
            name:
              _vm.$route.name === 'edit-protocol'
                ? 'view-protocol'
                : ((_vm.$route.meta.parent) + "-view-protocol"),
          })}}},[_vm._v(" "+_vm._s(_vm.$t('yes_cancel'))+" ")]),_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"primary"},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('no_continue'))+" ")])]}}]),model:{value:(_vm.showConfirmationModal),callback:function ($$v) {_vm.showConfirmationModal=$$v},expression:"showConfirmationModal"}},[_c('p',{staticClass:"text-center px-5"},[_vm._v(" "+_vm._s(_vm.$t('exit_update_protocol'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }