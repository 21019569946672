import {
  fetchClientBasicInfo,
  fetchClientContacts,
  fetchClientRepresentatives,
  fetchClientsV2,
} from '@/api/client';

const initialState = {
  clients: [],
  pagination: {},
  selectedClient: {},
};
const state = { ...initialState };

const getters = {};

const mutations = {
  SET_LIST_CLIENTS: (state, clients) => {
    state.clients = clients;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  SELECTED_CLIENT: (state, client) => {
    state.selectedClient = client;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async fetchClients({ commit }, payload) {
    try {
      const { data, pagination } = await fetchClientsV2(payload);
      commit('SET_LIST_CLIENTS', data);
      commit('SET_PAGINATION', pagination);
    } catch (error) {
      commit('SET_LIST_CLIENTS', []);
      commit('SET_PAGINATION', {});
      console.error(error);
      return error;
    }
  },
  async dispatchFetchClient({ commit }, clientId) {
    try {
      const {
        data: {
          legalName,
          description,
          programManager,
          clientType: { id: clientTypeId },
          status,
        },
      } = await fetchClientBasicInfo(clientId);
      const { data: contacts } = await fetchClientContacts(clientId);
      const { data: representatives } = await fetchClientRepresentatives(
        clientId
      );
      const extractContact = contacts.filter(
        (contact) => contact.type === 'CONTACT'
      )[0].contact;
      const extractInvoice = contacts.filter(
        (contact) => contact.type === 'INVOICE'
      )[0].contact;
      commit('SELECTED_CLIENT', {
        basic: {
          id: clientId,
          legalName,
          description,
          programManagerId: programManager.id,
          clientTypeId,
          status: status.name,
        },
        contact: {
          website: extractContact.website,
          email: extractContact.email,
          phone: extractContact.phone,
          address: extractContact.address,
          city: extractContact.city,
          postalCode: extractContact.postalCode,
          countryId: extractContact.country.id,
          id: extractContact.id,
        },
        invoice: {
          address: extractInvoice?.address,
          city: extractInvoice?.city,
          countryId: extractInvoice?.country?.id,
          postalCode: extractInvoice?.postalCode,
          id: extractInvoice?.id,
        },
        representatives,
      });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
