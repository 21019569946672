<template>
  <div>
    <notifications group="app" position="top center" />

    <div class="container-fluid">
      <b-row class="justify-content-center min-vh-100">
        <b-col cols="7" class="align-self-center">
          <div class="mx-auto w-75">
            <slide-y-up-transition mode="out-in" origin="center top">
              <router-view></router-view>
            </slide-y-up-transition>
          </div>
        </b-col>
        <b-col
          cols="5"
          style="
            background-image: url('/img/theme/auth-background.jpeg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          "
        >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'auth-layout',
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>
<style>
.logo {
  width: 150px;
}

.row-logo {
  margin-bottom: 6rem;
}
</style>
