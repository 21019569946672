import axios from 'axios';

const request = axios.create({
  auth: {
    username: 'support+zendesk@healios.io/token',
    password: process.env.VUE_APP_ZENDESK_API_KEY,
  },
});

/**
 * POST: submit a new zendesk ticket
 */
export function postTicket(payload) {
  const url = `https://healios.zendesk.com/api/v2/tickets`;
  return request.post(url, payload);
}
