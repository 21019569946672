<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2223 4.44531H12.7778V5.55642H17.2223V17.2231H12.7778V18.3342H18.3334V5.55642C18.3334 5.26174 18.2163 4.97912 18.008 4.77075C17.7996 4.56238 17.517 4.44531 17.2223 4.44531Z"
      fill="#C5D8E4"
    />
    <path
      d="M11.0444 1.66797H3.39995C3.08758 1.66797 2.78801 1.79206 2.56713 2.01293C2.34625 2.23381 2.22217 2.53338 2.22217 2.84575V18.3346H12.2222V2.84575C12.2222 2.53338 12.0981 2.23381 11.8772 2.01293C11.6563 1.79206 11.3568 1.66797 11.0444 1.66797ZM11.1111 17.2235H9.44439V15.5569H4.99995V17.2235H3.33328V2.84575C3.33328 2.83699 3.335 2.82832 3.33835 2.82023C3.3417 2.81215 3.34661 2.8048 3.35281 2.79861C3.359 2.79242 3.36635 2.7875 3.37443 2.78415C3.38252 2.7808 3.39119 2.77908 3.39995 2.77908H11.0444C11.0531 2.77908 11.0618 2.7808 11.0699 2.78415C11.078 2.7875 11.0853 2.79242 11.0915 2.79861C11.0977 2.8048 11.1026 2.81215 11.106 2.82023C11.1093 2.82832 11.1111 2.83699 11.1111 2.84575V17.2235Z"
      fill="#C5D8E4"
    />
    <path
      d="M4.44434 4.44531H5.55545V5.55642H4.44434V4.44531Z"
      fill="#C5D8E4"
    />
    <path d="M6.6665 4.44531H7.77761V5.55642H6.6665V4.44531Z" fill="#C5D8E4" />
    <path
      d="M8.88867 4.44531H9.99978V5.55642H8.88867V4.44531Z"
      fill="#C5D8E4"
    />
    <path
      d="M4.44434 7.22266H5.55545V8.33377H4.44434V7.22266Z"
      fill="#C5D8E4"
    />
    <path d="M6.6665 7.22266H7.77761V8.33377H6.6665V7.22266Z" fill="#C5D8E4" />
    <path
      d="M8.88867 7.22266H9.99978V8.33377H8.88867V7.22266Z"
      fill="#C5D8E4"
    />
    <path d="M4.44434 10H5.55545V11.1111H4.44434V10Z" fill="#C5D8E4" />
    <path d="M6.6665 10H7.77761V11.1111H6.6665V10Z" fill="#C5D8E4" />
    <path d="M8.88867 10H9.99978V11.1111H8.88867V10Z" fill="#C5D8E4" />
    <path
      d="M4.44434 12.7773H5.55545V13.8885H4.44434V12.7773Z"
      fill="#C5D8E4"
    />
    <path d="M6.6665 12.7773H7.77761V13.8885H6.6665V12.7773Z" fill="#C5D8E4" />
    <path
      d="M8.88867 12.7773H9.99978V13.8885H8.88867V12.7773Z"
      fill="#C5D8E4"
    />
    <path
      d="M12.7778 7.22266H13.8889V8.33377H12.7778V7.22266Z"
      fill="#C5D8E4"
    />
    <path d="M15 7.22266H16.1111V8.33377H15V7.22266Z" fill="#C5D8E4" />
    <path d="M12.7778 10H13.8889V11.1111H12.7778V10Z" fill="#C5D8E4" />
    <path d="M15 10H16.1111V11.1111H15V10Z" fill="#C5D8E4" />
    <path
      d="M12.7778 12.7773H13.8889V13.8885H12.7778V12.7773Z"
      fill="#C5D8E4"
    />
    <path d="M15 12.7773H16.1111V13.8885H15V12.7773Z" fill="#C5D8E4" />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
