export function transformDurationParams(string, duration) {
  return string.replace('%Duration%', `${duration}`);
}

export function transformTimeParams(string, min, sec) {
  return string.replace('%A%', `${min}`).replace('%C%', `${sec}`);
}

export function transformWeeksParams(string, numOfWeeks) {
  return string.replace('%x', numOfWeeks);
}
