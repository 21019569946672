import { fetchExperts } from '@/api/experts';

const initialState = {
  managers: [],
  noRoleUsers: [],
  representatives: [],
};
const state = { ...initialState };

const getters = {
  expertStatusFromId({ noRoleUsers }) {
    return (id) => {
      const noRoleUser = noRoleUsers.find((manager) => manager.id === id);
      return noRoleUser?.user.status;
    };
  },
};

const mutations = {
  UPDATING_REPRESENTATIVE_DATA: (state, managers) => {
    state.representatives = managers;
  },
  SET_MANAGERS: (state, managers) => {
    state.managers = managers;
  },
  SET_CURRENT_USERS: (state, users) => {
    state.noRoleUsers = users;
  },
  RESET: (state) => {
    Object.keys(initialState).forEach((name) => {
      state[name] = initialState[name];
    });
  },
};

const actions = {
  async dispatchFetchManagers({ commit }) {
    try {
      const managers = await fetchExperts({
        'filter[profile]': 'program-manager',
      });
      const labeled = managers.data.map((manager) => ({
        id: manager.id,
        label: manager.user.firstName + ' ' + manager.user.lastName,
        userStatus: manager.user.status.name,
      }));
      commit('SET_MANAGERS', labeled);
    } catch (error) {
      console.error(error);
    }
  },
  async dispatchAuthLevelUsers({ commit }, payload) {
    try {
      const { data } = await fetchExperts(payload);
      const list = data.map((item) => ({
        ...item,
        fullName: item.user.firstName + ' ' + item.user.lastName,
      }));
      commit('SET_CURRENT_USERS', list);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
