export default {
  path: 'clients',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'clients',
      component: () =>
        import(
          /* webpackChunkName: "clients" */ '../views/Clients/List/ClientListContainerView.vue'
        ),
    },

    {
      path: 'create',
      name: 'create-client',

      component: () =>
        import(
          /* webpackChunkName: "createClient" */ '../views/Clients/Create.vue'
        ),
    },

    {
      path: '/clients/:id',
      component: () =>
        import(
          /* webpackChunkName: "viewClient" */ '../views/Clients/View.vue'
        ),
      children: [
        {
          path: '',
          name: 'client-details',

          component: () =>
            import(
              /* webpackChunkName: "clientDetails" */ '../views/Clients/Details.vue'
            ),
        },
        {
          path: 'edit',
          name: 'edit-client',

          component: () =>
            import(
              /* webpackChunkName: "editClient" */ '../views/Clients/Edit.vue'
            ),
        },
        {
          path: 'studies',
          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'client-studies',

              component: () =>
                import(
                  /* webpackChunkName: "studies" */ '../views/Studies/List/QuickStudyListContainerView.vue'
                ),
            },
            {
              path: 'add',
              name: 'create-study',

              component: () =>
                import(
                  /* webpackChunkName: "createStudy" */ '../views/Studies/Create.vue'
                ),
            },
          ],
        },
        {
          path: 'study',
          component: {
            render(c) {
              return c('router-view');
            },
          },

          children: [
            {
              path: '',
              name: 'client-study-details',

              component: () =>
                import(
                  /* webpackChunkName: "viewStudy" */ '../views/Studies/Details.vue'
                ),
            },

            {
              path: 'edit',
              name: 'client-edit-study',
              meta: { parent: 'client' },
              component: () =>
                import(
                  /* webpackChunkName: "editStudy" */ '../views/Studies/Edit.vue'
                ),
            },

            {
              path: 'protocols',
              meta: {
                parent: 'client',
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },

              children: [
                {
                  path: '',
                  name: 'client-protocols',
                  meta: {
                    parent: 'client',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "protocols" */ '../views/Protocol/List/QuickProtocolListContainerView.vue'
                    ),
                },
                {
                  path: 'add',
                  name: 'client-create-protocol',
                  meta: {
                    parent: 'client',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "createProtocol" */ '../views/Protocol/Create.vue'
                    ),
                },

                {
                  path: 'protocol',
                  meta: {
                    parent: 'client',
                  },
                  component: {
                    render(c) {
                      return c('router-view');
                    },
                  },
                  children: [
                    {
                      path: '',
                      name: 'client-view-protocol',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "ViewProtocol" */ '../views/Protocol/Details.vue'
                        ),
                    },

                    {
                      path: 'edit',
                      name: 'client-edit-protocol',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "ViewProtocol" */ '../views/Protocol/Edit.vue'
                        ),
                    },

                    {
                      path: 'sites',
                      name: 'client-protocol-sites',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "protocols" */ '../views/Sites/List/QuickSiteListContainerView.vue'
                        ),
                    },

                    {
                      path: 'participants',
                      name: 'client-protocol-participants',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "protocols" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                        ),
                    },
                  ],
                },
              ],
            },

            {
              path: 'sites',
              meta: {
                parent: 'client',
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },

              children: [
                {
                  path: '',
                  name: 'client-sites',
                  meta: {
                    parent: 'client',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "protocols" */ '../views/Sites/List/QuickSiteListContainerView.vue'
                    ),
                },
                {
                  path: 'add',
                  name: 'client-create-site',
                  meta: {
                    parent: 'client',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "createSite" */ '../views/Sites/Create.vue'
                    ),
                },
                {
                  path: 'site',

                  component: {
                    render(c) {
                      return c('router-view');
                    },
                  },

                  children: [
                    {
                      path: '',
                      name: 'client-view-site',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "viewSite" */ '../views/Sites/Details.vue'
                        ),
                    },

                    {
                      path: 'edit',
                      name: 'client-edit-site',
                      meta: {
                        parent: 'client',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "updateSite" */ '../views/Sites/Edit.vue'
                        ),
                    },

                    {
                      path: 'members',
                      name: 'client-site-list-team',
                      component: () =>
                        import(
                          /* webpackChunkName: "TeamMembersList" */ '../views/TeamMember/List/TeamMemberListContainer.vue'
                        ),
                    },
                    {
                      path: 'participants',
                      meta: {
                        parent: 'client',
                      },
                      component: {
                        render(c) {
                          return c('router-view');
                        },
                      },

                      children: [
                        {
                          path: '',
                          name: 'client-site-participants',
                          meta: {
                            parent: 'client',
                          },
                          component: () =>
                            import(
                              /* webpackChunkName: "viewParticipant" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                            ),
                        },
                        {
                          path: 'participant',
                          name: 'client-view-participant',
                          meta: {
                            parent: 'client',
                          },
                          component: () =>
                            import(
                              /* webpackChunkName: "viewParticipant" */ '../views/Participants/View.vue'
                            ),
                        },
                        {
                          path: 'add',
                          name: 'client-create-participant',
                          meta: {
                            parent: 'client',
                          },
                          component: () =>
                            import(
                              /* webpackChunkName: "createParticipant" */ '../views/Participants/Create.vue'
                            ),
                        },
                        {
                          path: 'view-relapse',
                          name: 'client-view-relapse',
                          meta: {
                            parent: 'client',
                          },
                          component: () =>
                            import(
                              /* webpackChunkName: "viewRelapse" */ '../views/Relapses/RelapseDetailView.vue'
                            ),
                        },

                        {
                          path: 'create-relapse-schedule',
                          name: 'client-create-relapse-schedule',
                          meta: {
                            parent: 'client',
                          },
                          component: () =>
                            import(
                              /* webpackChunkName: "create-relapse-schedule" */ '../views/Relapses/CreateRelapseView.vue'
                            ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              path: 'participants',
              name: 'client-participants',
              meta: {
                parent: 'client',
              },
              component: () =>
                import(
                  /* webpackChunkName: "protocols" */ '../views/Participants/List/QuickParticipantListContainerView.vue'
                ),
            },

            {
              path: 'team',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: 'client-list-team',
                  component: () =>
                    import(
                      /* webpackChunkName: "TeamMembersList" */ '../views/TeamMember/List/TeamMemberListContainer.vue'
                    ),
                },
                {
                  path: 'add',
                  name: 'add-member',

                  component: () =>
                    import(
                      /* webpackChunkName: "createTeamMember" */ '../views/TeamMember/Create.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
